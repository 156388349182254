.border-rising {
 padding: 0.088em;
 border-radius: 100%;
 border: 2.2122px solid #00bbf9;
}
.border-asked {
 padding: 0.088em;
 border-radius: 100%;
 border: 2.2122px solid #06d6a0;
}
.border-white {
 padding: 0.088em;
 border-radius: 100%;
 border: 2.2122px solid #fff;
}

.flex-marge-left-3 {
 margin-left: 0.1em;
}
.flex-row {
 display: flex;
 flex-direction: row;
 place-items: center;
}
