.title-count-total-info {
 display: flex;
}
.title-count-total-info h2 {
 display: flex;
 font-size: 0.9em;
 font-weight: 400;
 line-height: 1.3;
 letter-spacing: 0px;
 font-family: var(--font);
}
