.stock-unit-hm-cmd {
 display: flex;
 justify-content: start;
 align-items: start;
 width: 100%;
 margin-top: 2vh;
 color: var(--color-text);
}
.stock-unit-hm-cmd img {
 height: 2.5em;
 width: 2.5em;
 margin-left: 0.4em;
 object-fit: cover;
}
.stock-unit-hm-cmd p {
 display: flex;
 font-size: 2.5em;
 font-family: var(--font);
 font-weight: 300;
 line-height: 1;
 letter-spacing: 0px;
 color: #fff;
}

/* Small  */
.stock-unit-hm-cmd-sm {
 display: flex;
 justify-content: start;
 align-items: start;
 width: 100%;
 margin-top: 2vh;
 color: var(--color-text);
}
.stock-unit-hm-cmd-sm img {
 height: 2em;
 width: 2em;
 margin-left: 0.4em;
 object-fit: cover;
}
.stock-unit-hm-cmd-sm p {
 display: flex;
 font-size: 2em;
 font-family: var(--font);
 font-weight: 300;
 line-height: 1;
 letter-spacing: 0px;
 color: #fff;
}
