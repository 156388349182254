.flex-qt-operator-budget-credit {
 display: flex;
}
.flex-qt-operator-budget-credit p {
 display: flex;
 font-size: 1em;
 font-weight: 300;
 line-height: 1;
 letter-spacing: 0px;
 font-family: var(--font);
}
