.wrp-all-actif-user-info {
 display: grid;
 place-items: start;
}
.wrp-all-actif-user-info h4 {
 display: flex;
 font-size: 0.9em;
 font-weight: 400;
 line-height: 1;
 letter-spacing: 0px;
 font-family: var(--font);
 margin-bottom: 2.5vh;
 color: #9999a1;
}
.wrp-all-actif-user-info p {
 display: flex;
 font-size: 0.9em;
 font-weight: 400;
 line-height: 1;
 white-space: nowrap;
 letter-spacing: 0px;
 font-family: var(--font);

 margin-bottom: 1vh;
}
.actif-red {
 color: #f21b3f;
}

.wrp-all-actif-user-info-sm {
 display: grid;
 place-items: start;
}
.wrp-all-actif-user-info-sm h4 {
 display: flex;
 font-size: 0.9em;
 font-weight: 400;
 line-height: 1;
 letter-spacing: 0px;
 font-family: var(--font);
 margin-bottom: 2.5vh;
 color: #9999a1;
}
.wrp-all-actif-user-info-sm p {
 display: flex;
 font-size: 0.7em;
 font-weight: 400;
 line-height: 1;
 white-space: nowrap;
 letter-spacing: 0px;
 font-family: var(--font);

 margin-bottom: 1vh;
}
