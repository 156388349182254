.flex-form-enrol {
 display: grid;
 height: 100%;
 min-height: 100vh;
 grid-template-columns: minmax(150px, 55%) 1fr;
}

.flex-form-enrol-sm {
 display: grid;
 height: 100%;
 min-height: 100vh;
 grid-template-rows: auto 1fr auto;
}
