.title-head-acces-litige {
 display: flex;
}
.title-head-acces-litige h3 {
 display: flex;
 font-size: 1em;
 font-weight: 400;
 text-transform: uppercase;
 line-height: 1;
 letter-spacing: 0px;
 font-family: var(--font);
 color: #000;
}
