.wrp-body-throw-over-dash {
 display: flex;
 justify-content: space-between;
 align-items: center;
 width: 100%;
 border-radius: none;
}
.wrp-body-throw-content {
 display: grid;
 place-items: start;
}
.wrp-body-throw-content h3 {
 display: flex;
 font-size: 1em;
 font-family: var(--font);
 font-weight: 400;
 line-height: 1;
 letter-spacing: 0px;
 margin-bottom: 3vh;
 white-space: nowrap;
}
.wrp-body-throw-content p {
 display: flex;
 font-size: 0.94em;
 font-family: var(--font);
 font-weight: 100;
 line-height: 1;
 letter-spacing: 0px;
 margin: 1.2vh 0;
 white-space: nowrap;
 color: #7f7f7f;
}

.flex-stock-detail {
 display: grid;
 place-items: start;
 margin-top: 1vh;
}
.flex-stock-detail p {
 display: flex;
 font-size: 0.9em;
 font-weight: 400;
 font-family: var(--font);
 line-height: 0.8;
 letter-spacing: 0px;
}

.flex-pop-up-cmd-airtel {
 display: flex;
 flex-direction: row;
 justify-content: space-between;
 line-height: 1;
 width: 100%;
}
.flex-pop-up-cmd-airtel div {
 display: flex;
 flex-direction: row;
}
