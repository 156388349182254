:root {
 --colr-title-actif: #23001e;

 --color-grey-arrow: #acb1bb;
}

.wrp-title-hm-actifall {
 display: grid;
 position: absolute;
 left: 3%;
 bottom: 10%;
 color: var(--colr-title-actif);
}
.wrp-title-hm-actifall h1 {
 display: flex;
 font-size: 2em;
 font-weight: 100;
 width: 100%;
 max-width: 10em;
 line-height: 1.1;
 letter-spacing: 0px;
 font-family: var(--font);
}
.wrp-title-hm-actifall p {
 display: flex;
 font-size: 1.155em;
 font-weight: 200;
 width: 100%;
 max-width: 30em;
 letter-spacing: 0px;
 font-family: var(--font);
 margin-top: 3vh;
 color: var(--color-grey-arrow);
}

/* Small */
.wrp-title-hm-actifall-sm {
 display: grid;
 position: absolute;
 left: 3%;
 bottom: 20%;
 color: var(--colr-title-actif);
}
.wrp-title-hm-actifall-sm h1 {
 display: flex;
 font-size: 9vw;
 font-weight: 300;
 width: 100%;
 max-width: 10em;
 line-height: 1;
 letter-spacing: 0px;
 font-family: var(--font);
}
.wrp-title-hm-actifall-sm p {
 display: flex;
 font-size: 1.2em;
 font-weight: 100;
 width: 100%;
 max-width: 30em;
 line-height: 1.2;
 letter-spacing: 0px;
 font-family: var(--font);
 margin-top: 1vh;
 color: var(--color-grey-arrow);
}
