@import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@100;200;300;400;500&display=swap");

.wrp-logo-info {
 display: flex;
 flex-direction: row;
}
.wrp-logo-info h1 {
 display: flex;
 font-size: 0.85em;
 font-weight: 300;
 font-family: "Roboto Condensed", sans-serif;
 line-height: 1;
 letter-spacing: 0px;
}

.color-info-logo {
 color: #f26419;
}
