.home-box-unit-stock {
 width: 100%;
 background-color: var(--bg-color-yellow);
}
.home-unit-stock {
 display: grid;
 place-items: center;
 height: 90vh;
 width: 100%;
}
