.quote-transfert-send-budget {
 display: flex;
}
.quote-transfert-send-budget p {
 display: flex;
 font-size: 1em;
 font-weight: 300;
 line-height: 1.155;
 letter-spacing: 0px;
 text-align: center;
 font-family: var(--font);
}
