.flex-budget-pret-grid {
 display: flex;
}
.flex-budget-pret-grid-sm {
 display: grid;
 place-items: start;
}

.flex-balance-budget-pret {
 display: flex;
 color: #fdfdfd;
}
.flex-balance-budget-pret p {
 display: flex;
 font-size: 1em;
 font-weight: 300;
 line-height: 0.3;
 letter-spacing: 0px;
 white-space: nowrap;
 font-family: var(--font);
}

/* Small */
.flex-balance-budget-pret-sm {
 display: flex;
 color: #fdfdfd;
}
.flex-balance-budget-pret-sm p {
 display: flex;
 font-size: 0.9em;
 font-weight: 300;
 line-height: 0;
 letter-spacing: 0px;
 white-space: nowrap;
 font-family: var(--font);
}
