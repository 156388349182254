.wrp-avatar-transfer-view {
 display: flex;
 place-items: center;
}
.wrp-avatar-transfer-view p {
 display: flex;
 font-size: 0.9em;
 font-weight: 300;
 line-height: 1;
 letter-spacing: 0.3px;
 font-family: var(--font);
 margin-left: 1em;
 color: #000;
}

/* Small */
.wrp-avatar-transfer-view-sm {
 display: flex;
 place-items: center;
}
.wrp-avatar-transfer-view-sm p {
 display: flex;
 font-size: 0.7em;
 font-weight: 300;
 line-height: 1;
 letter-spacing: 0.3px;
 font-family: var(--font);
 margin-left: 1em;
 color: #000;
}

.pad-lf-rh-3 {
 padding: 2vh 1em;
}
