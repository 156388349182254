.cashbeck {
 display: flex;
 font-size: 1em;
 font-weight: 300;
 line-height: 1;
 letter-spacing: 0.3px;
 font-family: var(--font);
}
.cashbeck-text {
 display: flex;
 font-size: 0.9em;
 font-weight: 300;
 line-height: 1.3;
 letter-spacing: 0px;
 font-family: var(--font);
}
