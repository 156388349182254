.title-consumption-info {
 display: flex;
 flex-direction: column;
 margin-bottom: 3vh;
}
.title-consumption-info h3 {
 display: flex;
 font-size: 1.2em;
 font-weight: 300;
 line-height: 1;
 letter-spacing: 0px;
 font-family: var(--font);
}
