.grid-template-tontine .wrp-head-1fr {
 padding: 2rem;
 grid-column: 1 / 3;
 background-color: #edf5fd;
}
.grid-template-tontine-sm .wrp-head-1fr-sm {
 padding: 2rem;
 grid-column: 1 / 1;
 background-color: #edf5fd;
}

.pad-right-left-3 {
 display: flex;
}
.border-bottom-1 {
 display: flex;
 border-bottom: 0.3px solid #e9ecef;
}
.border-rigth-1 {
 display: flex;
 border-right: 0.3px solid #e9ecef;
}
.border-left-1 {
 display: flex;
 height: 100%;
 border-left: 0.3px solid #e9ecef;
}
