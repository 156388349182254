.flex-20vh-head-drawe {
 display: flex;
 height: 30vh;
 width: 100%;
 background-color: #005db3;
 color: #fff;
}
.flex-20vh-head-drawe-sm {
 display: flex;
 height: 20vh;
 width: 100%;
 background-color: #005db3;
 color: #fff;
}

.flex-grid-left {
 display: grid;
 place-items: start;
}
.pad-2em {
 padding: 1em;
}

.pad-1em {
 padding: 3%;
}
