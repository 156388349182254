.flex-prix-balance-litige-cancel {
 display: flex;
}
.flex-prix-balance-litige-cancel p {
 display: flex;
 font-size: 1.5em;
 font-weight: 100;
 line-height: 0.5;
 letter-spacing: 0px;
 font-family: var(--font);
 color: #679436;
}
.flex-prix-balance-litige-cancel span {
 display: flex;
 font-size: 0.8em;
 font-weight: 100;
 line-height: 0.5;
 letter-spacing: 0px;
 font-family: var(--font);
 margin-top: 1vh;
 color: #d90368;
}
