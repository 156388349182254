.wrp-month-throw {
 display: flex;

 justify-content: space-between;
 place-items: center;
 padding: 5vh 2em;

 width: 100%;
}
.flex-month-throw-content {
 display: flex;
 justify-content: space-between;
 align-items: center;
 width: 100%;
}
.throw-content-icon {
 display: flex;
 position: absolute;

 right: 7%;
 margin-bottom: 4vh;
}
