.flex-accordion-details {
 display: grid;
 place-items: start;
}
.flex-accordion-details h1 {
 display: flex;
 font-size: 1em;
 font-weight: 100;
 line-height: 1;
 letter-spacing: 0px;
 font-family: var(--font);
 margin: 4vh 0;
 color: #00b0ff;
}
.flex-accordion-details p {
 display: flex;
 font-size: 0.8em;
 font-weight: 100;
 line-height: 1;
 white-space: nowrap;

 letter-spacing: 0px;
 font-family: var(--font);
 color: #6c757d;
}
.flex-gap-3 {
 gap: 3em;
}
.left-margin-12vw {
 margin-left: 350px;
}

.left-margin-small-12vw {
 margin-left: 70px;
}

.margin-bottom-2vh {
 margin-bottom: 2vh;
}

.pop-up-small {
 display: flex;
 font-size: 0.5em;
 font-weight: 300;
 line-height: 1;
 letter-spacing: 0px;
 font-family: var(--font);
}

/* Small */
.flex-accordion-details-sm {
 display: grid;
 place-items: start;
}
.flex-accordion-details-sm h1 {
 display: flex;
 font-size: 1em;
 font-weight: 100;
 line-height: 1;
 letter-spacing: 0px;
 font-family: var(--font);
 margin: 4vh 0;
 color: #00b0ff;
}
.flex-accordion-details-sm p {
 display: flex;
 font-size: 0.7em;
 font-weight: 400;
 line-height: 1;
 white-space: nowrap;

 letter-spacing: 0px;
 font-family: var(--font);
 color: #6c757d;
}

.top-8vh {
 margin-top: 2vh;
}
button {
 border: none;
}
.mr-bt-5vh {
 margin-bottom: 5vh;
}
