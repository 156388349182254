.title-head-transfer-self {
 display: flex;
 margin: 6vh 0;
}
.title-head-transfer-self h3 {
 display: flex;
 font-size: 1em;
 font-weight: 400;
 line-height: 0;
 letter-spacing: 0px;
 font-family: var(--font);
}

.title-head-transfer-self-sm {
 display: flex;
 margin: 6vh 0;
}
.title-head-transfer-self-sm h3 {
 display: flex;
 font-size: 1.2em;
 font-weight: 300;
 white-space: nowrap;
 line-height: 1;
 letter-spacing: 0px;
 font-family: var(--font);
}
