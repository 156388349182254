.flex-width-100 {
 display: flex;
 width: 100%;
}
.flex-center {
 display: grid;
 height: 100%;
 width: 100%;
 place-items: center;
}

.wrp-grid-center {
 display: grid;
 place-items: center;
}
.wrp-grid-center p {
 display: flex;
 font-size: 1.2em;
 font-weight: 300;
 line-height: 1;
 letter-spacing: 0px;
 font-family: var(--font);
 margin-top: 2vh;
}

/* Small */
.wrp-grid-center-sm {
 display: grid;
 place-items: center;
}
.wrp-grid-center-sm p {
 display: flex;
 font-size: 0.9em;
 font-weight: 300;
 line-height: 1;
 letter-spacing: 0px;
 font-family: var(--font);
 margin-top: 2vh;
}
