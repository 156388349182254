.wrp-avatar-transfer-identity-dash {
 display: flex;
 place-items: center;
 margin: 5vh 0;
}
.wrp-avatar-transfer-identity-dash p {
 display: flex;
 font-size: 1em;
 font-weight: 300;
 line-height: 0;
 letter-spacing: 0.3px;
 font-family: var(--font);
 margin-left: 0.5em;
 padding-top: 3ch;
}

/* Small */
.wrp-avatar-transfer-identity-dash-sm {
 display: grid;
 place-items: center;
}
.wrp-avatar-transfer-identity-dash-sm p {
 display: flex;
 font-size: 0.8em;
 font-weight: 100;
 line-height: 0;
 text-align: end;
 letter-spacing: 0.3px;
 font-family: var(--font);
 color: #fff;
}
