.border-bottom-1 {
 border-bottom: 1px solid #dadbdb;
}
.flex-budget-creditaire {
 display: flex;
 margin-top: 2vh;
 padding: 0 3%;
}
.bg-blue {
 height: 100%;
 background-color: #deded6;
}
