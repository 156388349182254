.stock-throw-btn {
 display: flex;
 cursor: pointer;
 padding: 0.7em;

 /* From https://css.glass */
 /* background: rgba(241, 187, 95, 0.72); */
 background: #eba01d;

 border-radius: 50%;
 box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);

 backdrop-filter: blur(5px);
 -webkit-backdrop-filter: blur(5px);
 border: 1px solid rgba(241, 187, 95, 0.41);
}
