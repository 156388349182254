.grid-head-dash-pret {
 grid-column: 1 / 4;
}
.grid-head-dash-pret-sm {
 grid-column: 1 / 2;
}

.flex-header-6ch {
 display: flex;
 width: 100%;
 place-items: center;
 height: 8ch;
 padding: 0 3%;
}

.bg-platinum {
 display: flex;
 background-color: #005db3;
}
