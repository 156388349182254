.stock-autority-pin {
 display: flex;
 flex-direction: column;
 justify-content: space-between;
 align-items: center;
 width: 100%;
}
.stock-autority-pin form input {
 display: flex;
 background-color: transparent;
 border: none;
}
.stock-autority-pin input {
 display: none;
 caret-color: transparent;
 color: transparent;
}
