.wrp-input-ref-signin-user {
 display: flex;
 flex-direction: column;
}
.wrp-input-ref-signin-user form {
 display: grid;
}
.wrp-input-ref-signin-user form input {
 display: flex;
 font-size: 1.1em;
 font-weight: 300;
 line-height: 1;
 margin-top: 1vh;
 letter-spacing: 0px;
 font-family: var(--font);
 color: #343a40;
}
.wrp-input-ref-signin-user form button {
 margin-top: 3vh;
}
