.flex-title-head-share {
 display: flex;
 color: #fff;
}
.flex-title-head-share h1 {
 display: flex;
 font-size: 1em;
 font-weight: 200;
 line-height: 1;
 letter-spacing: 0px;
 font-family: var(--font);
}
