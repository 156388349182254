.title-head-refs-creditaire {
 display: grid;
 padding: 2vh 2%;
}
.title-head-refs-creditaire h1 {
 display: flex;
 font-size: 1em;
 font-weight: 300;
 line-height: 1;
 letter-spacing: 0px;
 font-family: var(--font);
 text-transform: uppercase;
 margin-bottom: 5vh;
 margin-top: 1.9ch;

 color: #99a87d;
}
