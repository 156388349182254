.flex-list-share {
 display: grid;
}

.flex-list-share p {
 display: flex;
 font-size: 0.8em;
 line-height: 1;
 letter-spacing: 0px;
 font-family: var(--font);
}
