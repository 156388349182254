.wrp-title-actif-all {
 display: grid;
 justify-content: center;
 place-items: center;
 width: 100%;
 margin-top: 10vh;
 margin-bottom: 5vh;
}
.wrp-title-actif-all h1 {
 display: flex;
 font-size: 2em;
 font-weight: 300;
 font-family: var(--font);
 line-height: 1;
 letter-spacing: 0.5px;
 color: var(--color-blue);
}

/* Small */
.wrp-title-actif-all-sm {
 display: grid;
 justify-content: start;
 place-items: start;
 width: 100%;
 margin: 7vh 0;
 padding: 0 1em;
}
.wrp-title-actif-all-sm h1 {
 display: flex;
 font-size: 2em;
 font-weight: 300;
 font-family: var(--font);
 line-height: 1;
 letter-spacing: 0.5px;
 color: var(--color-blue);
}
