.wrp-body-transfert-valid {
 display: flex;
 flex-direction: column;
 margin-top: 15vh;
}

.flex-transfert-buy-balance {
 display: grid;
 grid-template-rows: auto 1fr auto;
 height: 100vh;
}
