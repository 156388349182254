.flex-profil-left-litige {
 display: grid;
 place-items: center;
}
.flex-profil-left-litige h4 {
 display: flex;
 font-size: 1.055em;
 font-weight: 300;
 margin-top: 1.5vh;
 line-height: 1;
 letter-spacing: 0px;
 font-family: var(--font);
 text-transform: lowercase;
}

.border-succes {
 display: flex;
 border-radius: 100%;
 padding: 0.1em;
 border: 0.144em solid #493;
}
.border-error {
 display: flex;
 border-radius: 100%;
 padding: 0.1em;
 border: 0.144em solid crimson;
}
