.flex-title-form-enroll {
 display: flex;
 padding: 1em;
}
.flex-title-form-enroll h5 {
 display: flex;
 font-size: 1em;
 font-weight: 400;
 line-height: 1;
 letter-spacing: 0.5px;
 font-family: var(--font);
 text-transform: uppercase;
}
