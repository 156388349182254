.flex-img-side-info {
 display: grid;
 place-items: center;
}
.flex-img-side-info img {
 height: 19vw;
 width: 22vw;
 object-fit: cover;
 margin-bottom: 2vh;
}
