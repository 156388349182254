.title-contri-body-user {
 display: flex;
 padding: 0 2.3em;
}
.title-contri-body-user h4 {
 display: flex;
 font-weight: 300;
 font-size: 0.9em;
 line-height: 4;
 white-space: nowrap;
 letter-spacing: 0px;
 font-family: var(--font);
 color: #6c757d;
}

/* Small  */
.title-contri-body-user-sm {
 display: flex;
 width: 100%;

 justify-content: space-between;
 place-items: center;
}
.title-contri-body-user-sm h4 {
 display: flex;
 font-weight: 300;
 font-size: 0.9em;
 line-height: 1;
 white-space: nowrap;
 letter-spacing: 0px;
 font-family: var(--font);
 color: #6c757d;
}
