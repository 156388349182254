.flex-head-profil-info-tontine {
 display: grid;
 place-items: center;
}
.flex-head-profil-info-tontine p {
 display: flex;
 font-size: 1em;
 font-weight: 200;
 line-height: 1;
 letter-spacing: 0px;
 font-family: var(--font);
 margin-top: 2vh;
}
