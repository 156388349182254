.input-sign-hm {
 display: flex;
 flex-direction: column;
 padding: 0.5em;
 width: 100%;
}
.input-sign-hm form {
 display: flex;
 flex-direction: column;

 justify-content: center;
 align-items: center;
 width: 100%;
}
.input-sign-hm input {
 display: flex;
 min-width: 100%;
 text-align: center;
 line-height: 1;
 font-family: var(--font);
 padding: 2.4vh 0;
 border: none;
 background: transparent;
 color: #11151c;
}

.letterspacesign-email {
 display: flex;
 font-size: 1em;
 font-weight: 400;
 letter-spacing: 0px;
}
.letterspacesign-pwd {
 display: flex;
 font-size: 1.4em;
 font-weight: 400;
 letter-spacing: 10px;
}

/* Small */
.input-sign-hm-sm {
 display: flex;
 flex-direction: column;
 padding: 2em;
}
.input-sign-hm-sm form {
 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: center;
}
.input-sign-hm-sm input {
 display: flex;

 text-align: center;
 line-height: 2;
 font-family: var(--font);
 border: none;
 background: transparent;
 color: #11151c;
}
.input-sign-hm-sm button {
 margin-top: 4vh;
}
.input-sign-hm-sm .letterspacesign-email {
 display: flex;
 font-size: 1.21em;
 font-weight: 300;
 letter-spacing: 0px;
 margin-top: 6vh;
}
.input-sign-hm-sm .letterspacesign-pwd {
 display: flex;
 font-size: 1.6em;
 font-weight: 400;
 letter-spacing: 10px;
}
.pop-up {
 display: flex;
 font-size: 0.9em;
 font-family: var(--font);
 font-weight: 300;
 line-height: 1.2;
 letter-spacing: 0px;
}
