.title-price-balance-actif {
 display: flex;
}
.title-price-balance-actif h3 {
 display: flex;
 font-size: 1.3em;
 font-weight: 400;
 font-family: var(--font);
 line-height: 1;
 letter-spacing: 0px;
}

.title-price-balance-actif-sm {
 display: flex;
}
.title-price-balance-actif-sm h3 {
 display: flex;
 font-size: 1.3em;
 font-weight: 400;
 font-family: var(--font);
 line-height: 1;
 letter-spacing: 0px;
}
