.wrp-list-menu-dash {
 overflow: scroll;
 height: 100vh;
 padding: 1em;
 background-color: #fbfcff;
}

.wrp-stretching {
 display: grid;
 grid-template-columns: repeat(2, 1fr);
}
.wrp-stretching ul {
 margin: 0;
 padding: 0;
 list-style: none;
}
.wrp-stretching ul li {
 flex: 1 1 0px;
}
