.title-operator-budget {
 display: flex;
 padding: 3vh 3%;
}

.title-operator-budget h1 {
 display: flex;
 font-size: 1.2em;
 font-weight: 100;
 line-height: 1;
 letter-spacing: 0px;
 font-family: var(--font);
}
