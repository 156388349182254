.wrp-form-input-pin-nows {
 display: flex;
 justify-content: center;
 align-items: center;
 width: 100%;
 max-width: 25em;
}
.wrp-form-input-pin-nows form {
 display: flex;
 flex-direction: column;
 justify-content: center;

 place-items: center;
 padding: 1em;

 width: 100%;
 max-width: 100vw;
}
.wrp-form-input-pin-nows input {
 display: flex;
 font-size: 1.5em;
 font-weight: 500;
 font-family: var(--font);
 line-height: 1;

 letter-spacing: 8px;
 text-align: center;
 padding-left: 1.9em;
 border: none;
}
.wrp-form-input-pin-nows form button {
 display: flex;
 margin-top: 2vh;
 max-width: 100vw;
 font-size: 1em;
 border-radius: 0.6em;
 padding: 0.3em;
 font-family: var(--font);
}
.wrp-form-input-pin-nows form p {
 display: flex;
 font-size: 1.1em;
 font-weight: 300;
 line-height: 1.2;
 text-align: center;
 letter-spacing: 0px;
 font-family: var(--font);
 margin: 3vh 0;
}
.title-pop-pret {
 display: flex;
 font-size: 1.155em;
 font-weight: 300;
 font-family: var(--font);
 line-height: 1;
 letter-spacing: 0.3px;
 color: #2dc653;
}
.paragraph-pop-pret {
 display: flex;
 font-size: 0.9em;
 font-weight: 300;
 font-family: var(--font);
 line-height: 1;
 letter-spacing: 0.3px;
 color: #595959;

 margin: 4vh 0;
}
.flex-active-ask-pret {
 display: grid;
 place-items: start;
 width: 100%;
 gap: 1em;
}
.flex-input-pret-modulo {
 display: grid;
 place-items: start;
}
.flex-input-pret-modulo label {
 display: flex;
 font-size: 1.1em;
 font-weight: 300;
 line-height: 1.2;
 text-align: center;
 letter-spacing: 0px;
 font-family: var(--font);
 margin: 1vh 0;
 margin-top: 3vh;
 color: #2b303a;
}
.flex-input-pret-modulo input {
 display: flex;
 font-size: 1.3em;
 font-weight: 100;
 font-family: var(--font);
 line-height: 1;
 letter-spacing: 0px;
 border: none;
 color: #2b303a;
}

/* Small Pin */
.wrp-form-input-pin-nows-sm {
 display: flex;
 justify-content: center;
 align-items: center;
 width: 100%;
}

.wrp-form-input-pin-nows-sm input {
 display: flex;
 font-size: 1.5em;
 font-weight: 500;
 font-family: var(--font);
 line-height: 1;

 letter-spacing: 8px;
 text-align: center;
 padding-left: 1.9em;
 border: none;
}
.wrp-form-input-pin-nows-sm form button {
 display: flex;
 margin-top: 2vh;
 max-width: 100vw;
 font-size: 1.115em;
 border-radius: 0.6em;
 padding: 0.5em;
 font-family: var(--font);
}
.wrp-form-input-pin-nows-sm form p {
 display: flex;
 font-size: 1.1em;
 font-weight: 300;
 line-height: 1.2;
 text-align: center;
 letter-spacing: 0px;
 font-family: var(--font);
 margin: 3vh 0;
}
.title-pop-pret {
 display: flex;
 font-size: 1.155em;
 font-weight: 300;
 font-family: var(--font);
 line-height: 1;
 letter-spacing: 0.3px;
 color: #2dc653;
}
.paragraph-pop-pret {
 display: flex;
 font-size: 0.9em;
 font-weight: 300;
 font-family: var(--font);
 line-height: 1;
 letter-spacing: 0.3px;
 color: #595959;

 margin: 4vh 0;
}
.flex-active-ask-pret {
 display: grid;
 place-items: start;
 width: 100%;
 gap: 1em;
}
.flex-input-pret-modulo {
 display: grid;
 place-items: start;
}
.flex-input-pret-modulo label {
 display: flex;
 font-size: 1.1em;
 font-weight: 300;
 line-height: 1.2;
 text-align: center;
 letter-spacing: 0px;
 font-family: var(--font);
 margin: 1vh 0;
 margin-top: 3vh;
 color: #2b303a;
}
.flex-input-pret-modulo input {
 display: flex;
 font-size: 1.3em;
 font-weight: 100;
 font-family: var(--font);
 line-height: 1;
 letter-spacing: 0px;
 border: none;
 color: #2b303a;
}
