.flex-count-total-info {
 display: flex;
 padding: 1ch 0;
}
.count-agent-total-info {
 display: flex;
 flex-direction: column;
 place-items: start;
}
.count-agent-total-info p {
 display: flex;
 font-size: 0.9em;
 font-weight: 400;
 line-height: 0.5;
 letter-spacing: 0.3px;
 font-family: var(--font);

 color: #ff3f00;
}
