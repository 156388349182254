.flex-admin-quote-addbudget {
 display: flex;
}
.flex-admin-quote-addbudget p {
 display: flex;
 font-size: 0.9em;
 font-weight: 300;
 line-height: 1.155;
 letter-spacing: 0px;
 font-family: var(--font);
 text-align: center;
 color: #7f7f7f;
}
