.flex-view-send-share {
 display: grid;
 height: 100vh;
 min-height: 100%;
 grid-template-rows: auto 1fr auto;
}
.block-flex-center-wifi {
 display: grid;
 place-items: center;
}
