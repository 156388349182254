.list-bd-group-tontine {
 display: flex;
 padding: 2vh 0;
}
.list-bd-group-tontine h2 {
 display: flex;
 font-size: 0.855em;
 font-weight: 300;
 line-height: 1;
 letter-spacing: 0px;
 white-space: nowrap;

 font-family: var(--font);
 text-transform: uppercase;
}
.list-bd-group-tontine h3 {
 display: flex;
 font-size: 0.855em;
 font-weight: 300;
 white-space: nowrap;
 line-height: 1;
 letter-spacing: 0px;
 font-family: var(--font);
}
