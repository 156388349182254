.wrp-list-buy-transfert-cancel {
 display: grid;
 margin-top: 5vh;
}

.wrp-list-buy-transfert-cancel ul {
 padding: 0;
 margin: 0;
 list-style: none;
}

.wrp-list-buy-transfert-cancel ul li {
 display: flex;
 justify-content: space-between;
 place-items: center;
 width: 100%;
}

.wrp-list-buy-transfert-cancel ul li p {
 display: flex;
 font-size: 0.9em;
 font-weight: 300;
 line-height: 1;
 font-family: var(--font);
 letter-spacing: 0px;
}
