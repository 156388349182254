.title-head-transfer {
 display: flex;
}
.title-head-transfer h3 {
 display: flex;
 font-size: 1em;
 font-weight: 400;
 line-height: 2;
 letter-spacing: 0px;
 font-family: var(--font);
}
.pad-top-bottom {
 padding: 2vh 0;
}
