.stock-authority-dat {
 display: flex;
 justify-content: space-between;
 align-items: center;
 width: 100%;
 margin-top: 5vh;
}
.stock-authority-dat p {
 display: flex;
 padding: 0.31em;
 border-radius: 100%;
 border: 0.111em solid white;
}
