.flex-user-quote-transfert-send-money {
 display: flex;
}
.flex-user-quote-transfert-send-money p {
 display: flex;
 font-size: 0.9em;
 font-weight: 300;
 line-height: 1.155;
 letter-spacing: 0px;
 text-align: center;
 font-family: var(--font);
 color: #c1121f;
}
