.wrp-price-actif-all {
 display: flex;
 flex-direction: row;

 justify-content: end;
 align-items: end;
}
.wrp-price-actif-all p {
 display: flex;
 font-size: 2em;
 font-weight: 200;
 font-family: var(--font);
 line-height: 2;
 letter-spacing: -1.5px;
}
.wrp-price-actif-all span {
 display: flex;
 font-size: 1em;
 font-weight: 100;
 font-family: var(--font);
 line-height: 1;
 letter-spacing: 0px;
}
