.flex-asked-drawe {
 display: flex;
}
.flex-asked-drawe p {
 display: flex;
 font-size: 1.1em;
 font-weight: 300;
 line-height: 1;
 letter-spacing: 0px;
 white-space: nowrap;
 font-family: var(--font);
}
