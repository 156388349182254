/* CSS ID */

.flex-id-phone {
 display: flex;
 margin-left: 1em;
}
.flex-id-phone h3 {
 display: flex;
 font-size: 1.155em;
 font-weight: 500;
 line-height: 1;
 letter-spacing: 0px;
 font-family: var(--font);
}
.pad-top-bottom-2 {
 margin: 1.5vh 0;
}
