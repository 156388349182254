.stock-unit-hm-cmd {
 display: flex;
}
.cmd-hm-stock-unit {
 display: grid;
 grid-template-columns: repeat(2, 80%);
 grid-auto-rows: 60px;
 gap: 5px;

 width: 100%;
 max-width: 20em;
 padding: 1em;
 height: 25ch;

 /* From https://css.glass */
 /* background: rgba(255, 255, 255, 0.2); */

 border-radius: 16px;
 /* box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1); */

 backdrop-filter: blur(5px);
 -webkit-backdrop-filter: blur(5px);
 border: 1px solid rgba(255, 255, 255, 0);
}
.stock-network-img {
 display: flex;
 justify-content: space-between;
 align-items: center;
 width: 100%;
}
.stock-network-img img {
 height: 2.5em;
 width: 2.5em;
 object-fit: cover;
}
.stock-img-africell {
 height: 4em;
 width: 4em;
 object-fit: contain;
}

/* Small Cmd */
.cmd-hm-stock-unit-sm {
 display: grid;
 grid-template-columns: repeat(2, 65%);
 grid-auto-rows: 50px;
 gap: 5px;

 width: 100%;
 max-width: 65vw;
 padding: 1em;
 height: 20ch;

 /* From https://css.glass */
 /* background: rgba(255, 255, 255, 0.2); */

 border-radius: 16px;

 backdrop-filter: blur(5px);
 -webkit-backdrop-filter: blur(5px);
 border: 1px solid rgba(255, 255, 255, 0);
}
.stock-network-img-sm {
 display: flex;
 justify-content: space-between;
 align-items: center;
 width: 100%;
}
.stock-network-img-sm img {
 height: 40px;
 width: 40px;
 object-fit: cover;
}
.stock-img-africell-sm {
 height: 65px;
 width: 65px;
 object-fit: contain;
}
