.flex-global-pret-progres {
 display: grid;
 margin-top: 3vh;
}
.flex-global-pret-progres h3 {
 display: flex;
 font-size: 1.11em;
 font-weight: 300;
 line-height: 1;
 letter-spacing: 0px;
 font-family: var(--font);
 color: #005db3;
}
