.input-signin-sharing {
 display: flex;
 flex-direction: column;
 width: 100%;
 justify-content: center;
 align-items: center;
}
.input-signin-sharing form {
 display: flex;
 width: 100%;
 max-width: 25em;
 flex-direction: column;
}
.input-signin-sharing form input {
 display: flex;
 flex-direction: column;
 font-size: 1.1em;
 font-weight: 500;
 font-family: "Quicksand", sans-serif;
 line-height: 2;
 padding: 1.5vh 0;
 letter-spacing: 0.5px;
 border: none;
 border-bottom: 1px solid #b0bacf;

 color: #000;

 background: transparent;
}
.input-signin-sharing form button {
 margin-top: 3vh;
}

/* Small screen */
.input-signin-sharing-sm {
 display: flex;
 flex-direction: column;
 width: 100%;
 justify-content: center;
 align-items: center;
 margin-top: 3vh;
}
.input-signin-sharing-sm form {
 display: flex;
 width: 100%;
 padding: 1em;
 flex-direction: column;
}
.input-signin-sharing-sm form input {
 display: flex;
 flex-direction: column;
 font-size: 1.1em;
 font-weight: 500;
 font-family: "Quicksand", sans-serif;
 line-height: 2;
 padding: 1.5vh 0;
 letter-spacing: 0.5px;
 border: none;
 border-bottom: 1px solid #b0bacf;

 color: #000;

 background: transparent;
}
.input-signin-sharing-sm form button {
 margin-top: 3vh;
}
