.border-bottom-1 {
 border-bottom: 1px solid #dadbdb;
}
.flex-budget-creditaire {
 display: flex;
 margin-top: 2vh;
 padding: 0 3%;
}

.flex-dash-solde-budget-creditaire {
 display: flex;
 font-size: 1.2em;
 font-weight: 300;
 line-height: 1;
 letter-spacing: 0px;
 font-family: var(--font);
 padding: 0.7em;
}
