.wrp-budget-head-dash {
 display: flex;
 margin: 7vh 0;
}
.budget-dash-screen-large {
 display: flex;
}
.budget-dash-screen-large p {
 display: flex;
 font-size: 1.7em;
 font-weight: 300;
 line-height: 1;
 letter-spacing: 0px;

 font-family: "Quicksand", sans-serif;
 font-optical-sizing: auto;
 font-style: normal;
}

/* small large */
.budget-dash-screen-large-sm {
 display: flex;
}
.budget-dash-screen-large-sm p {
 display: flex;
 font-size: 4.5vw;
 font-weight: 300;
 line-height: 1;
 letter-spacing: 0px;
 white-space: nowrap;

 font-family: "Quicksand", sans-serif;
 font-optical-sizing: auto;
 font-style: normal;
}
