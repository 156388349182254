.grid-center-box-drawer {
 display: grid;
 place-items: center;
}

.grid-flex-center {
 display: flex;
 place-items: center;
}

.flex-flag-litge-box {
 display: flex;
}

.flex-flag-litge-box p {
 display: flex;
 margin-top: 2vh;
 font-size: 1em;
 font-weight: 300;
 line-height: 0;
 letter-spacing: 0px;
 font-family: var(--font);
}
