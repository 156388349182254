.wrp-live {
 display: flex;
 flex-direction: column;

 justify-content: center;
 align-items: center;

 height: 100vh;
 width: 100%;
}
.wrp-live p {
 display: flex;
 font-size: 1.233em;
 font-weight: 400;
 font-family: var(--font);
 line-height: 1;
 letter-spacing: 0px;
 cursor: pointer;
 color: blue;
}
