.wrp-balance-actif-all {
 display: flex;
 flex-direction: column;
 width: 100%;
 justify-content: center;
 place-items: center;

 margin-top: 5vh;
}
.price-color-word {
 display: flex;
 font-size: 1em;
 font-weight: 300;
 font-family: var(--font);
 line-height: 1;
 letter-spacing: 0px;
 color: var(--color-blue);
}
