.flex-prix-balance-litige {
 display: flex;
}
.flex-prix-balance-litige p {
 display: flex;
 font-size: 1em;
 font-weight: 300;
 line-height: 0.5;
 letter-spacing: 0px;
 font-family: var(--font);
 color: #3e3e3e;
}
