.title-pin-hm-main-info {
 display: grid;
 place-items: center;
 width: 100%;
 max-width: 30vw;
}
.title-pin-hm-main-info h2 {
 display: flex;
 font-size: 1.4em;
 font-weight: 300;
 line-height: 1;
 font-family: var(--font);
 letter-spacing: 0px;
 margin-bottom: 1ch;
 color: #495057;
}
.title-pin-hm-main-info p {
 display: flex;
 font-size: 0.855em;
 font-weight: 300;
 line-height: 1.4;
 font-family: var(--font);
 letter-spacing: 0px;
 text-align: center;
 color: #aaaaaa;
}

/* Small */
.title-pin-hm-main-info-sm {
 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: center;
 width: 100%;
}
.title-pin-hm-main-info-sm h2 {
 display: flex;
 font-size: 1.3em;
 font-weight: 100;
 line-height: 1;
 font-family: var(--font);
 letter-spacing: 0px;
 margin-bottom: 2ch;
 color: #495057;
}
.title-pin-hm-main-info-sm p {
 display: flex;
 font-size: 0.8em;
 font-weight: 100;
 line-height: 1.2;
 font-family: var(--font);
 letter-spacing: 0px;
 text-align: center;
 color: #aaaaaa;
}
