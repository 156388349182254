.wrp-withus-user-info {
 display: grid;
}
.wrp-withus-user-info p {
 display: flex;
 font-size: 0.9em;
 font-weight: 400;
 line-height: 0.3;
 white-space: nowrap;
 letter-spacing: 0px;
 font-family: var(--font);
 color: #444;
}
