.wrp-hm-info {
 display: grid;
 grid-template-rows: auto 1fr auto;
 width: 100%;
 height: 100%;
 min-height: 100vh;
}

.flex-between {
 display: flex;
 justify-content: space-between;
 place-items: center;
 width: 100%;
}
