.flex-height-25-vh {
 display: flex;
 width: 100%;
 height: 25vh;
 background-color: #e6e6e6;
}
.flex-pad-left-right-3 {
 display: flex;
 padding: 0 3%;
}
