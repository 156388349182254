.hm-title-unit-stock {
 display: grid;
 place-items: start;
 color: var(--color-text);
}
.hm-title-unit-stock h1 {
 display: flex;
 font-size: 2em;
 font-family: var(--font);
 font-weight: 100;
 line-height: 1;
 letter-spacing: 0px;
}
.hm-title-unit-stock-sm {
 display: grid;
 place-items: start;
 color: #fff;
}
.hm-title-unit-stock-sm h1 {
 display: flex;
 font-size: 7vw;
 font-family: var(--font);
 font-weight: 100;
 line-height: 1;
 letter-spacing: 0px;
}
