.stock-autority-quote {
 display: grid;
 place-items: center;
 color: white;
}
.stock-autority-quote p {
 display: flex;
 font-size: 1.3em;
 font-weight: 100;
 font-family: var(--font);
 line-height: 1;
 letter-spacing: 1px;
}
