.wrp-hm-dash-list-user-pret {
 display: flex;
 justify-content: space-between;
 place-items: center;
 width: 100%;
 cursor: pointer;
 max-width: 100vw;
 border-bottom: 1px solid #eeeeee;
}
.wrp-hm-dash-list-user-pret p {
 display: flex;
 font-size: 0.8em;
 font-weight: 300;
 text-align: start;
 white-space: nowrap;
 line-height: 0.3;
 letter-spacing: 0px;
 font-family: var(--font);
 margin-top: 3vh;
 color: #5b5b5b;
}
.flex-money-user-list {
 display: flex;
 flex-direction: column;
}
.flex-money-user-list span {
 display: flex;
 font-size: 0.7em;
 width: 10em;

 font-weight: 400;
 text-align: center;
 white-space: nowrap;
 letter-spacing: 0px;
 font-family: var(--font);
 color: #5b5b5b;
}

.flex-pret-title-user {
 display: grid;
 padding: 0 3%;
 margin-top: 5vh;
}
.flex-pret-title-user h4 {
 display: flex;
 font-size: 1.2em;
 font-weight: 400;
 line-height: 1;
 letter-spacing: 0px;
 font-family: var(--font);
 color: #004e98;
}

.mr-top-bottom-3 {
 margin: 3vh 0;
}
