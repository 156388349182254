.flex-creidit-center {
 display: flex;
 place-items: center;
}
.flex-creidit-center p {
 display: flex;
 font-size: 0.8em;
 font-weight: 300;
 line-height: 1;
 letter-spacing: 0px;
 margin-top: 1vh;
 padding: 0.4em;
 font-family: var(--font);
}

.box-credit-center {
 display: flex;
}
.box-credit-center p {
 display: flex;
 font-size: 0.7em;
 font-weight: 400;
 line-height: 1;
 letter-spacing: 0px;
 margin-top: 2vh;
 padding: 0.4em;
 font-family: var(--font);
}

.flex-block-center {
 display: flex;
 flex-direction: column;
}
.flex-block-center h1 {
 display: flex;
 font-size: 1em;
 font-weight: 400;
 line-height: 4;
 letter-spacing: 0px;
 font-family: var(--font);
}
.flex-block-center p {
 display: flex;
 font-size: 0.8em;
 font-weight: 300;
 line-height: 0.6;
 letter-spacing: 0px;
 font-family: var(--font);
}
