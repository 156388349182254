.flex-between-edit {
 display: flex;
 width: 100%;
 justify-content: space-between;
 place-items: center;
 padding: 0.5em;
}
.title-pop-up {
 display: flex;
 font-size: 1em;
 font-weight: 300;
 line-height: 1;
 margin-bottom: -1vh;

 letter-spacing: 0px;
 font-family: var(--font);
}
.flex-between-edit .flex-block {
 display: grid;
 place-items: start;
}
.flex-between-edit .flex-block span {
 display: flex;
 font-size: 0.8em;
 font-weight: 300;
 line-height: 2;
 /* padding: 2vh 0; */
 color: #00b4d8;

 letter-spacing: 0.5px;
 font-family: var(--font);
}
.flex-between-edit p {
 display: flex;
 font-size: 0.9em;
 font-weight: 300;
 line-height: 1;
 padding: 1.5vh 0;

 letter-spacing: 0px;
 font-family: var(--font);
}

/* Small */
.flex-between-edit-sm {
 display: flex;
 width: 100%;
 justify-content: space-between;
 place-items: center;
 padding: 0.3em;
}
.flex-between-edit-sm form {
 display: flex;
 flex-direction: row;
 width: 100%;
 justify-content: space-between;
 place-items: center;
}

.title-pop-up-sm {
 display: flex;
 font-size: 1.11em;
 font-weight: 300;
 line-height: 1;
 margin-bottom: 3vh;

 letter-spacing: 0px;
 font-family: var(--font);
}
.flex-between-edit-sm .flex-block-sm {
 display: grid;
 place-items: start;
}
.flex-between-edit-sm .flex-block-sm span {
 display: flex;
 font-size: 3vw;
 font-weight: 300;
 line-height: 1;
 padding: 0.5vh 0;
 color: #00b4d8;

 letter-spacing: 0.5px;
 font-family: var(--font);
}
.flex-between-edit-sm p {
 display: flex;
 font-size: 0.8em;
 font-weight: 300;
 line-height: 1;
 padding: 0.5vh 0;

 letter-spacing: 0px;
 font-family: var(--font);
}

.pop-up-low {
 display: flex;
 font-size: 0.9em;
 font-weight: 100;
 line-height: 1.3;
 padding: 0.4vh 0;
 letter-spacing: 0px;
 font-family: var(--font);
}
