.allow-avatar {
 display: flex;
}
.allow-avatar img {
 height: 7em;
 width: 7em;
 object-fit: cover;
}

.allow-avatar-sm {
 display: flex;
}
.allow-avatar-sm img {
 height: 6em;
 width: 6em;
 object-fit: cover;
}
