.crimson {
 /* color: crimson; */
 color: #005db3;
}

.grey-595959 {
 color: #595959;
}

.fat-400 {
 font-weight: 500;
}
.fat-100 {
 font-weight: 100;
}

.wrp-main-prix-pret {
 display: flex;
}

.wrp-main-prix-pret p {
 display: flex;
 font-size: 1.8em;
 font-weight: 100;
 line-height: 1;
 letter-spacing: 0px;
 font-family: var(--font);
}

.wrp-main-prix-pret-sm {
 display: flex;
 font-size: 1.8em;
 font-weight: 100;
 line-height: 1;
 letter-spacing: 0px;
 font-family: var(--font);
}
