.flex-avatar-profil-info-pret {
 display: grid;
 place-items: center;
}

.flex-avatar-profil-info-pret p {
 display: flex;
 font-size: 1.1em;
 margin-top: 2vh;
 font-weight: 300;
 line-height: 1;
 letter-spacing: 0.5px;
 font-family: var(--font);
 color: #7f7f7f;
}
