.flex-vh-100 {
 display: grid;
 grid-template-columns: minmax(150px, 45%) 1fr;

 height: 100%;
 min-height: 100vh;
}

.flex-vh-100-sm {
 display: grid;
 height: 100%;
 min-height: 100vh;
}
