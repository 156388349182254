.flex {
 display: flex;
 place-items: center;
 gap: 1em;
}

.flex-thrift {
 display: grid;
}

.flex-thrift p {
 display: flex;
 font-size: 0.9em;
 font-weight: 300;
 line-height: 1;
 letter-spacing: 0px;
 font-family: var(--font);
}
