.hm-IMA {
 display: flex;
 height: 100vh;
 width: 100%;
}
.hm-IMA img {
 display: flex;
 object-fit: cover;
 max-width: 60vw;
 object-position: 40%;
}

.hm-IMA-sm {
 display: flex;
 margin: 0;
 padding: 0;
 width: 100%;
}
.hm-IMA-sm img {
 display: flex;
 height: 30vh;
 width: 100vw;
 object-fit: cover;
 object-position: 30%;
}
