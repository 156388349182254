.grid-head-dash-litige {
 grid-column: 1 / 3;
}
.grid-head-dash-litige-sm {
 grid-column: 1 / 2;
}
.flex-header-6ch {
 display: flex;
 width: 100%;
 place-items: center;
 height: 6ch;
 padding: 0 3%;
}

.bg-color-hicolblue {
 background-color: #0b2545;
}
