.wrp-title-head-drawe-asked {
 display: flex;
}

.wrp-title-head-drawe-asked h2 {
 display: flex;
 font-size: 1.2em;
 font-weight: 300;
 line-height: 1;
 letter-spacing: 0px;
 font-family: var(--font);
}
