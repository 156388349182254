.wrp-count-head-tontine {
 display: flex;
 margin-top: 3vh;
}
.wrp-count-head-tontine h3 {
 display: flex;
 font-size: 0.9em;
 font-weight: 300;
 letter-spacing: 0px;
 font-family: var(--font);
 color: #0077b6;
}
.wrp-count-head-tontine p {
 display: flex;
 font-size: 0.9em;
 font-weight: 300;
 line-height: 3;
 letter-spacing: 0px;
 font-family: var(--font);
 color: #0077b6;
}
