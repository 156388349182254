@import url(https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@100;200;300;400;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@100;200;300;400;500&display=swap);
.hm-page {
 display: flex;
 flex-direction: row;
 width: 100%;
}
.hm-page-sm {
 display: flex;
 flex-direction: column;
 width: 100%;
}

.hm-IMA {
 display: flex;
 height: 100vh;
 width: 100%;
}
.hm-IMA img {
 display: flex;
 object-fit: cover;
 max-width: 60vw;
 object-position: 40%;
}

.hm-IMA-sm {
 display: flex;
 margin: 0;
 padding: 0;
 width: 100%;
}
.hm-IMA-sm img {
 display: flex;
 height: 30vh;
 width: 100vw;
 object-fit: cover;
 object-position: 30%;
}

.flex-sign-hm {
 display: flex;
 flex-direction: column;
 width: 100%;
 justify-content: center;
 align-items: center;
 grid-gap: 2em;
 gap: 2em;
 color: var(--color-text-gold);
 background-color: var(--color-bg);
}
.flex-sign-hm-sm {
 display: flex;
 flex-direction: column;

 width: 100%;
 height: 75vh;
 justify-content: center;
 align-items: center;
 color: var(--color-text-gold);
 background-color: var(--color-bg);
}

.wrp-title-sign-hm {
 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: center;
 width: 90%;
 padding: 1.2em;

 margin: -8vh 0;

 color: var(--color-text-gold);
}
.wrp-title-sign-hm h1 {
 display: flex;
 font-size: 1.8em;
 font-family: var(--font);
 font-weight: 300;
 line-height: 0.5;
 letter-spacing: -0.5px;
}
.wrp-title-sign-hm p {
 display: flex;
 font-size: 0.9em;
 font-family: var(--font);
 font-weight: 300;
 line-height: 1.1;
 padding: 1em;
 text-align: center;
 letter-spacing: 0px;
 margin-top: 1vh;
}

.input-sign-hm {
 display: flex;
 flex-direction: column;
 padding: 0.5em;
 width: 100%;
}
.input-sign-hm form {
 display: flex;
 flex-direction: column;

 justify-content: center;
 align-items: center;
 width: 100%;
}
.input-sign-hm input {
 display: flex;
 min-width: 100%;
 text-align: center;
 line-height: 1;
 font-family: var(--font);
 padding: 2.4vh 0;
 border: none;
 background: transparent;
 color: #11151c;
}

.letterspacesign-email {
 display: flex;
 font-size: 1em;
 font-weight: 400;
 letter-spacing: 0px;
}
.letterspacesign-pwd {
 display: flex;
 font-size: 1.4em;
 font-weight: 400;
 letter-spacing: 10px;
}

/* Small */
.input-sign-hm-sm {
 display: flex;
 flex-direction: column;
 padding: 2em;
}
.input-sign-hm-sm form {
 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: center;
}
.input-sign-hm-sm input {
 display: flex;

 text-align: center;
 line-height: 2;
 font-family: var(--font);
 border: none;
 background: transparent;
 color: #11151c;
}
.input-sign-hm-sm button {
 margin-top: 4vh;
}
.input-sign-hm-sm .letterspacesign-email {
 display: flex;
 font-size: 1.21em;
 font-weight: 300;
 letter-spacing: 0px;
 margin-top: 6vh;
}
.input-sign-hm-sm .letterspacesign-pwd {
 display: flex;
 font-size: 1.6em;
 font-weight: 400;
 letter-spacing: 10px;
}
.pop-up {
 display: flex;
 font-size: 0.9em;
 font-family: var(--font);
 font-weight: 300;
 line-height: 1.2;
 letter-spacing: 0px;
}

.input-signin-user {
 display: flex;
 flex-direction: column;
 width: 100%;
 justify-content: center;
 align-items: center;
}
.input-signin-user form {
 display: flex;
 width: 100%;
 max-width: 25em;
 flex-direction: column;
}
.input-signin-user form input {
 display: flex;
 flex-direction: column;
 font-size: 1.1em;
 font-weight: 500;
 font-family: "Quicksand", sans-serif;
 line-height: 2;
 padding: 1.5vh 0;
 letter-spacing: 0.5px;
 border: none;
 border-bottom: 1px solid #b0bacf;

 color: #000;

 background: transparent;
}
.input-signin-user form button {
 margin-top: 3vh;
}

/* Small screen */
.input-signin-user-sm {
 display: flex;
 flex-direction: column;
 width: 100%;
 justify-content: center;
 align-items: center;
 margin-top: 3vh;
}
.input-signin-user-sm form {
 display: flex;
 width: 100%;
 padding: 1em;
 flex-direction: column;
}
.input-signin-user-sm form input {
 display: flex;
 flex-direction: column;
 font-size: 1.1em;
 font-weight: 500;
 font-family: "Quicksand", sans-serif;
 line-height: 2;
 padding: 1.5vh 0;
 letter-spacing: 0.5px;
 border: none;
 border-bottom: 1px solid #b0bacf;

 color: #000;

 background: transparent;
}
.input-signin-user-sm form button {
 margin-top: 3vh;
}

.flex-title-form-enroll {
 display: flex;
 padding: 1em;
}
.flex-title-form-enroll h5 {
 display: flex;
 font-size: 1em;
 font-weight: 400;
 line-height: 1;
 letter-spacing: 0.5px;
 font-family: var(--font);
 text-transform: uppercase;
}

.bg-color-green-type {
 height: 100vh;
 background-color: #468faf;
}

.bg-color-green-type-sm {
 height: 10vh;
 background-color: #468faf;
}

.flex-form-enrol {
 display: grid;
 height: 100%;
 min-height: 100vh;
 grid-template-columns: minmax(150px, 55%) 1fr;
}

.flex-form-enrol-sm {
 display: grid;
 height: 100%;
 min-height: 100vh;
 grid-template-rows: auto 1fr auto;
}

.input-signin-sharing {
 display: flex;
 flex-direction: column;
 width: 100%;
 justify-content: center;
 align-items: center;
}
.input-signin-sharing form {
 display: flex;
 width: 100%;
 max-width: 25em;
 flex-direction: column;
}
.input-signin-sharing form input {
 display: flex;
 flex-direction: column;
 font-size: 1.1em;
 font-weight: 500;
 font-family: "Quicksand", sans-serif;
 line-height: 2;
 padding: 1.5vh 0;
 letter-spacing: 0.5px;
 border: none;
 border-bottom: 1px solid #b0bacf;

 color: #000;

 background: transparent;
}
.input-signin-sharing form button {
 margin-top: 3vh;
}

/* Small screen */
.input-signin-sharing-sm {
 display: flex;
 flex-direction: column;
 width: 100%;
 justify-content: center;
 align-items: center;
 margin-top: 3vh;
}
.input-signin-sharing-sm form {
 display: flex;
 width: 100%;
 padding: 1em;
 flex-direction: column;
}
.input-signin-sharing-sm form input {
 display: flex;
 flex-direction: column;
 font-size: 1.1em;
 font-weight: 500;
 font-family: "Quicksand", sans-serif;
 line-height: 2;
 padding: 1.5vh 0;
 letter-spacing: 0.5px;
 border: none;
 border-bottom: 1px solid #b0bacf;

 color: #000;

 background: transparent;
}
.input-signin-sharing-sm form button {
 margin-top: 3vh;
}

/*  */
.flex-center {
 display: flex;
 place-items: center;
}
.pad-top-2 {
 padding-top: 2vh;
}

.flex-usd-devise-sharing {
 display: flex;
}
.flex-usd-devise-sharing p {
 display: flex;
 font-size: 1.1em;
 font-weight: 300;
 line-height: 1;
 letter-spacing: 0px;
 font-family: var(--text-family);
}

.flex-title-form-enroll {
 display: flex;
 padding: 1em;
}
.flex-title-form-enroll h5 {
 display: flex;
 font-size: 1em;
 font-weight: 400;
 line-height: 1;
 letter-spacing: 0.5px;
 font-family: var(--font);
 text-transform: uppercase;
}

.bg-color-green-type {
 height: 100vh;
 background-color: #468faf;
}

.bg-color-green-type-sm {
 height: 10vh;
 background-color: #468faf;
}

.flex-form-enrol {
 display: grid;
 height: 100%;
 min-height: 100vh;
 grid-template-columns: minmax(150px, 55%) 1fr;
}

.flex-form-enrol-sm {
 display: grid;
 height: 100%;
 min-height: 100vh;
 grid-template-rows: auto 1fr auto;
}

:root {
 --color-text: white;

 --bg-color-yellow: #f1bb5f;
}

.home-box-unit-stock {
 width: 100%;
 background-color: var(--bg-color-yellow);
}
.home-unit-stock {
 display: grid;
 place-items: center;
 height: 90vh;
 width: 100%;
}

.hm-title-unit-stock {
 display: grid;
 place-items: start;
 color: var(--color-text);
}
.hm-title-unit-stock h1 {
 display: flex;
 font-size: 2em;
 font-family: var(--font);
 font-weight: 100;
 line-height: 1;
 letter-spacing: 0px;
}
.hm-title-unit-stock-sm {
 display: grid;
 place-items: start;
 color: #fff;
}
.hm-title-unit-stock-sm h1 {
 display: flex;
 font-size: 7vw;
 font-family: var(--font);
 font-weight: 100;
 line-height: 1;
 letter-spacing: 0px;
}

.flex-btn-stock {
 display: flex;
 width: 100%;
 max-width: 20em;
}
.flex-btn-stock button {
 display: flex;

 font-size: 1.155em;
 letter-spacing: 0.3px;
 font-family: var(--font);
 white-space: nowrap;
 outline-style: none;
 font-weight: 400;

 justify-content: center;
 align-items: center;

 padding: 0.5em 0;

 cursor: pointer;
 width: 100%;
 color: #55606a;

 border-radius: 0.6em;
 border: 0.1px solid var(--color-text);
 background-color: var(--color-text);
}

/* Small screen */
.flex-btn-stock-sm {
 display: flex;
 width: 100%;
 max-width: 95vw;
}
.flex-btn-stock-sm button {
 display: flex;

 font-size: 1.155em;
 letter-spacing: 0px;
 font-family: var(--font);
 white-space: nowrap;
 outline-style: none;
 font-weight: 400;

 justify-content: center;
 align-items: center;

 padding: 0.5em 0;

 cursor: pointer;
 width: 100%;
 color: #55606a;

 border-radius: 0.6em;
 border: 0.1px solid var(--color-text);
 background-color: var(--color-text);
}

.stock-unit-hm-cmd {
 display: flex;
}
.cmd-hm-stock-unit {
 display: grid;
 grid-template-columns: repeat(2, 80%);
 grid-auto-rows: 60px;
 grid-gap: 5px;
 gap: 5px;

 width: 100%;
 max-width: 20em;
 padding: 1em;
 height: 25ch;

 /* From https://css.glass */
 /* background: rgba(255, 255, 255, 0.2); */

 border-radius: 16px;
 /* box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1); */

 backdrop-filter: blur(5px);
 -webkit-backdrop-filter: blur(5px);
 border: 1px solid rgba(255, 255, 255, 0);
}
.stock-network-img {
 display: flex;
 justify-content: space-between;
 align-items: center;
 width: 100%;
}
.stock-network-img img {
 height: 2.5em;
 width: 2.5em;
 object-fit: cover;
}
.stock-img-africell {
 height: 4em;
 width: 4em;
 object-fit: contain;
}

/* Small Cmd */
.cmd-hm-stock-unit-sm {
 display: grid;
 grid-template-columns: repeat(2, 65%);
 grid-auto-rows: 50px;
 grid-gap: 5px;
 gap: 5px;

 width: 100%;
 max-width: 65vw;
 padding: 1em;
 height: 20ch;

 /* From https://css.glass */
 /* background: rgba(255, 255, 255, 0.2); */

 border-radius: 16px;

 backdrop-filter: blur(5px);
 -webkit-backdrop-filter: blur(5px);
 border: 1px solid rgba(255, 255, 255, 0);
}
.stock-network-img-sm {
 display: flex;
 justify-content: space-between;
 align-items: center;
 width: 100%;
}
.stock-network-img-sm img {
 height: 40px;
 width: 40px;
 object-fit: cover;
}
.stock-img-africell-sm {
 height: 65px;
 width: 65px;
 object-fit: contain;
}

.stock-unit-hm-cmd {
 display: flex;
 justify-content: start;
 align-items: start;
 width: 100%;
 margin-top: 2vh;
 color: var(--color-text);
}
.stock-unit-hm-cmd img {
 height: 2.5em;
 width: 2.5em;
 margin-left: 0.4em;
 object-fit: cover;
}
.stock-unit-hm-cmd p {
 display: flex;
 font-size: 2.5em;
 font-family: var(--font);
 font-weight: 300;
 line-height: 1;
 letter-spacing: 0px;
 color: #fff;
}

/* Small  */
.stock-unit-hm-cmd-sm {
 display: flex;
 justify-content: start;
 align-items: start;
 width: 100%;
 margin-top: 2vh;
 color: var(--color-text);
}
.stock-unit-hm-cmd-sm img {
 height: 2em;
 width: 2em;
 margin-left: 0.4em;
 object-fit: cover;
}
.stock-unit-hm-cmd-sm p {
 display: flex;
 font-size: 2em;
 font-family: var(--font);
 font-weight: 300;
 line-height: 1;
 letter-spacing: 0px;
 color: #fff;
}

.stock-admin-nav {
 display: flex;
 padding: 0 2%;
 height: 8ch;
 justify-content: space-between;
 align-items: center;
 width: 100%;
}

.allow-stock-unit {
 background-color: var(--bg-color-yellow);
}
.allow-stock-sub-unit {
 display: grid;
 height: 90vh;
 width: 100%;
 place-items: center;
}

.stock-admin-nav {
 display: flex;
 padding: 0 2%;
 height: 8ch;
 justify-content: space-between;
 align-items: center;
 width: 100%;
}

.stock-autority-quote {
 display: grid;
 place-items: center;
 color: white;
}
.stock-autority-quote p {
 display: flex;
 font-size: 1.3em;
 font-weight: 100;
 font-family: var(--font);
 line-height: 1;
 letter-spacing: 1px;
}

.allow-avatar {
 display: flex;
}
.allow-avatar img {
 height: 7em;
 width: 7em;
 object-fit: cover;
}

.allow-avatar-sm {
 display: flex;
}
.allow-avatar-sm img {
 height: 6em;
 width: 6em;
 object-fit: cover;
}

.stock-autority-pin {
 display: flex;
 flex-direction: column;
 justify-content: space-between;
 align-items: center;
 width: 100%;
}
.stock-autority-pin form input {
 display: flex;
 background-color: transparent;
 border: none;
}
.stock-autority-pin input {
 display: none;
 caret-color: transparent;
 color: transparent;
}

.stock-authority-dat {
 display: flex;
 justify-content: space-between;
 align-items: center;
 width: 100%;
 margin-top: 5vh;
}
.stock-authority-dat p {
 display: flex;
 padding: 0.31em;
 border-radius: 100%;
 border: 0.111em solid white;
}

.unit-stock-throw {
 display: flex;
 flex-direction: column;
 justify-content: center;
 place-items: center;
 height: 100vh;
 width: 100%;
 background-color: var(--bg-color-yellow);
}

.stock-throw-btn {
 display: flex;
 cursor: pointer;
 padding: 0.7em;

 /* From https://css.glass */
 /* background: rgba(241, 187, 95, 0.72); */
 background: #eba01d;

 border-radius: 50%;
 box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);

 backdrop-filter: blur(5px);
 -webkit-backdrop-filter: blur(5px);
 border: 1px solid rgba(241, 187, 95, 0.41);
}

.stock-admin-nav {
 display: flex;
 padding: 0 2%;
 height: 7ch;
 justify-content: space-between;
 align-items: center;
 width: 100%;
}

.wrp-throw-stock {
 display: grid;
 grid-template-rows: auto 1fr auto;
 height : 100%;
 min-height : 100vh;

}

.wrp-head-throw {
 display: grid;
}

.stock-admin-nav {
 display: flex;
 padding: 0 1em;
 justify-content: space-between;
 align-items: center;
 width: 100%;
 background-color: var(--bg-color-yellow);
}

/* .stock-nav-operator {
 display: flex;
 place-items: center;
}
.stock-nav-operator img {
 height: 3em;
 width: 3em;
 object-fit: cover;
 cursor: pointer;
 margin-right: 1em;
}

.stock-nav-operator-sm {
 display: flex;
 place-items: center;
}
.stock-nav-operator-sm img {
 height: 2.5em;
 width: 2.5em;
 object-fit: cover;
 cursor: pointer;
 margin-right: 1em;
} */


.box-unit-list-month {
 display: flex;
 flex-direction: column;
 padding: 2ch 0;
 border-bottom: 2px solid #ced4da;
}
.box-unit-list-month h3 {
 display: flex;
 font-size: 1.2em;
 font-weight: 400;
 font-family: var(--font);
 line-height: 1;
 letter-spacing: 0px;
}
.wrp-unit-list-month {
 display: grid;
 width: 100%;
 place-items: start;
 margin-top: 3vh;
}
.wrp-unit-list-month p {
 display: flex;
 font-size: 1.155em;
 font-family: var(--font);
 font-weight: 400;
 line-height: 0.5;
 letter-spacing: 0px;
}

.box-unit-list-month h3 {
 display: flex;
 font-size: 1.155em;
 font-family: var(--font);
 font-weight: 400;
 line-height: 0.5;
 letter-spacing: 0px;
 color: #8a5a44;
}

.wrp-unit-list-month h2 {
 display: flex;
 font-size: 2.5em;
 font-family: var(--font);
 font-weight: 200;
 line-height: 1;
 letter-spacing: 0px;
 color: #8a5a44;
}

.wrp-body-throw-stock {
 display: flex;
 flex-direction: column;
 padding: 2ch 1.2em;
}

.wrp-body-throw-over {
 display: flex;
 justify-content: space-between;
 align-items: center;
 width: 100%;
 border-radius: none;
}
.wrp-body-throw-content {
 display: grid;
 place-items: start;
}
.wrp-body-throw-content h3 {
 display: flex;
 font-size: 0.9em;
 font-family: var(--font);
 font-weight: 400;
 line-height: 1;
 letter-spacing: 0px;
 margin-bottom: 3vh;
 white-space: nowrap;
}
.wrp-body-throw-content p {
 display: flex;
 font-size: 0.951em;
 font-family: var(--font);
 font-weight: 100;
 line-height: 0;
 letter-spacing: 0px;
 white-space: nowrap;
 color: #7f7f7f;
}
.wrp-body-throw-content span {
 display: flex;
 font-size: 0.97em;
 font-family: var(--font);
 font-weight: 100;
 line-height: 0;
 letter-spacing: 0px;
 margin: 1.18vh 0;
 white-space: nowrap;
 color: #7f7f7f;
}

.flex-stock-detail {
 display: grid;
 place-items: start;
 margin-top: 1vh;
}
.flex-stock-detail p {
 display: flex;
 font-size: 0.9em;
 font-weight: 500;
 font-family: var(--font);
 line-height: 0.5;
 letter-spacing: 0px;
}

.flex-pop-up-cmd-airtel {
 display: flex;
 flex-direction: row;
 justify-content: space-between;
 line-height: 1;
 width: 100%;
}
.flex-pop-up-cmd-airtel div {
 display: flex;
 flex-direction: row;
}

.stock-admin-nav {
 display: flex;
 padding: 0 1em;
 justify-content: space-between;
 align-items: center;
 width: 100%;
 background-color: var(--bg-color-yellow);
}

/* .stock-nav-operator {
 display: flex;
 place-items: center;
}
.stock-nav-operator img {
 height: 3em;
 width: 3em;
 object-fit: cover;
 cursor: pointer;
 margin-right: 1em;
}

.stock-nav-operator-sm {
 display: flex;
 place-items: center;
}
.stock-nav-operator-sm img {
 height: 2.5em;
 width: 2.5em;
 object-fit: cover;
 cursor: pointer;
 margin-right: 1em;
} */

.stock-unit-hm-cmd-operator {
 display: flex;
 justify-content: start;
 align-items: start;
 margin-top: 2vh;
 color: var(--color-text);
}
.stock-unit-hm-cmd-operator img {
 height: 2em;
 width: 2em;
 margin-left: 0.4em;
 object-fit: cover;
}
.stock-unit-hm-cmd-operator p {
 display: flex;
 font-size: 2em;
 font-family: var(--font);
 font-weight: 300;
 line-height: 1;
 letter-spacing: 0px;
 color: #fff;
}

/* Small  */
.stock-unit-hm-cmd-operator-sm {
 display: flex;
 margin-top: 2vh;
 color: var(--color-text);
}
.stock-unit-hm-cmd-operator-sm img {
 height: 2em;
 width: 2em;
 margin-left: 0.4em;
 object-fit: cover;
}
.stock-unit-hm-cmd-operator-sm p {
 display: flex;
 font-size: 2em;
 font-family: var(--font);
 font-weight: 300;
 line-height: 1;
 letter-spacing: 0px;
 color: #fff;
}

.flex-price-increase {
 display: grid;
 place-items: start;
 margin: 3vh 0;
}

.wrp-head-pack-title h2 {
 display: flex;
 font-size: 1.6em;
 font-weight: 300;
 line-height: 0.5;
 letter-spacing: -1px;
 font-family: "Noto Sans Cham", sans-serif;
 padding: 0 0.5em;
 color: #004b23;
}

.flex-price-increase p {
 display: flex;
 font-size: 1.3em;
 font-weight: 300;
 line-height: 0.5;
 letter-spacing: -1px;
 font-family: "Noto Sans Cham", sans-serif;
 color: #004b23;
}

.flex-price-increase span {
 display: flex;
 font-size: 0.7em;
 font-weight: 300;
 line-height: 0;
 padding-left: 3em;
 letter-spacing: 0px;
 font-family: "Noto Sans Cham", sans-serif;
 color: crimson;
}

.flex-padding-1em {
 padding: 1em;
}

.wrp-month-throw {
 display: flex;

 justify-content: space-between;
 place-items: center;
 padding: 5vh 2em;

 width: 100%;
}
.flex-month-throw-content {
 display: flex;
 justify-content: space-between;
 align-items: center;
 width: 100%;
}
.throw-content-icon {
 display: flex;
 position: absolute;

 right: 7%;
 margin-bottom: 4vh;
}

/* .box-unit-list-month {
 display: flex;
 flex-direction: column;
 padding: 2ch 0;
 border-bottom: 2px solid #ced4da;
}
.box-unit-list-month h3 {
 display: flex;
 font-size: 1.2em;
 font-weight: 400;
 font-family: var(--font);
 line-height: 1;
 letter-spacing: 0px;
}
.wrp-unit-list-month {
 display: flex;
 width: 100%;
 justify-content: space-between;
 place-items: center;
}
.wrp-unit-list-month p {
 display: flex;
 font-size: 1.155em;
 font-family: var(--font);
 font-weight: 400;
 line-height: 1;
 letter-spacing: 0px;
} */

/* .wrp-month-throw {
 display: flex;

 justify-content: space-between;
 place-items: center;
 padding: 5vh 2em;

 width: 100%;
}
.flex-month-throw-content {
 display: flex;
 justify-content: space-between;
 align-items: center;
 width: 100%;
}
.throw-content-icon {
 display: flex;
 position: absolute;

 right: 7%;
 margin-bottom: 4vh;
} */

/* .box-unit-list-month {
 display: flex;
 flex-direction: column;
 padding: 2ch 0;
 border-bottom: 2px solid #ced4da;
}
.box-unit-list-month h3 {
 display: flex;
 font-size: 1.2em;
 font-weight: 400;
 font-family: var(--font);
 line-height: 1;
 letter-spacing: 0px;
}
.wrp-unit-list-month {
 display: flex;
 width: 100%;
 justify-content: space-between;
 place-items: center;
}
.wrp-unit-list-month p {
 display: flex;
 font-size: 1.155em;
 font-family: var(--font);
 font-weight: 400;
 line-height: 1;
 letter-spacing: 0px;
} */

/* .stock-nav-operator-africell {
 display: flex;
 place-items: center;
}
.stock-nav-operator-africell img {
 height: 4em;
 width: 4em;
 object-fit: contain;
}
.stock-nav-operator-africell-sm {
 display: flex;
 place-items: center;
}
.stock-nav-operator-africell-sm img {
 height: 3em;
 width: 3em;
 object-fit: contain;
} */

/* .wrp-month-throw {
 display: flex;

 justify-content: space-between;
 place-items: center;
 padding: 5vh 2em;

 width: 100%;
}
.flex-month-throw-content {
 display: flex;
 justify-content: space-between;
 align-items: center;
 width: 100%;
}
.throw-content-icon {
 display: flex;
 position: absolute;

 right: 7%;
 margin-bottom: 4vh;
} */

/* .box-unit-list-month {
 display: flex;
 flex-direction: column;
 padding: 2ch 0;
 border-bottom: 2px solid #ced4da;
}
.box-unit-list-month h3 {
 display: flex;
 font-size: 1.2em;
 font-weight: 400;
 font-family: var(--font);
 line-height: 1;
 letter-spacing: 0px;
}
.wrp-unit-list-month {
 display: flex;
 width: 100%;
 justify-content: space-between;
 place-items: center;
}
.wrp-unit-list-month p {
 display: flex;
 font-size: 1.155em;
 font-family: var(--font);
 font-weight: 400;
 line-height: 1;
 letter-spacing: 0px;
} */

.wrp-live {
 display: flex;
 flex-direction: column;

 justify-content: center;
 align-items: center;

 height: 100vh;
 width: 100%;
}
.wrp-live p {
 display: flex;
 font-size: 1.233em;
 font-weight: 400;
 font-family: var(--font);
 line-height: 1;
 letter-spacing: 0px;
 cursor: pointer;
 color: blue;
}

:root {
 --color-blue: #00bbf9;
 --bg-actif: rgba(235, 235, 235, 0.61);
 border-radius: 16px;
 box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
 backdrop-filter: blur(12.5px);
 -webkit-backdrop-filter: blur(12.5px);
 border: 1px solid rgba(235, 235, 235, 0.69);
}

.wrp-hm-actif-all {
 display: grid;
 position: relative;
 height: 100vh;
 width: 100%;
 background-color: var(--bg-actif);
}

.admin-nav {
 display: flex;
 padding: 0 0.4em;
 height: 8vh;
 justify-content: space-between;
 align-items: center;
 width: 100%;
}

:root {
 --colr-title-actif: #23001e;

 --color-grey-arrow: #acb1bb;
}

.wrp-title-hm-actifall {
 display: grid;
 position: absolute;
 left: 3%;
 bottom: 10%;
 color: #23001e;
 color: var(--colr-title-actif);
}
.wrp-title-hm-actifall h1 {
 display: flex;
 font-size: 2em;
 font-weight: 100;
 width: 100%;
 max-width: 10em;
 line-height: 1.1;
 letter-spacing: 0px;
 font-family: var(--font);
}
.wrp-title-hm-actifall p {
 display: flex;
 font-size: 1.155em;
 font-weight: 200;
 width: 100%;
 max-width: 30em;
 letter-spacing: 0px;
 font-family: var(--font);
 margin-top: 3vh;
 color: #acb1bb;
 color: var(--color-grey-arrow);
}

/* Small */
.wrp-title-hm-actifall-sm {
 display: grid;
 position: absolute;
 left: 3%;
 bottom: 20%;
 color: #23001e;
 color: var(--colr-title-actif);
}
.wrp-title-hm-actifall-sm h1 {
 display: flex;
 font-size: 9vw;
 font-weight: 300;
 width: 100%;
 max-width: 10em;
 line-height: 1;
 letter-spacing: 0px;
 font-family: var(--font);
}
.wrp-title-hm-actifall-sm p {
 display: flex;
 font-size: 1.2em;
 font-weight: 100;
 width: 100%;
 max-width: 30em;
 line-height: 1.2;
 letter-spacing: 0px;
 font-family: var(--font);
 margin-top: 1vh;
 color: #acb1bb;
 color: var(--color-grey-arrow);
}

.btn-large-hm-actif-all {
 display: grid;
 position: absolute;
 right: 3%;
 bottom: 10%;
}
.btn-large-hm-actif-all span {
 display: flex;
 cursor: pointer;
 padding: 0.6em;
 border-radius: 50%;
 background-color: #d4d700;
}

/* Small btn */
.btn-large-hm-actif-all-sm {
 display: grid;
 position: absolute;
 left: 5%;
 bottom: 10%;
}
.btn-large-hm-actif-all-sm span {
 display: flex;
 cursor: pointer;
 padding: 0.6em;
 border-radius: 50%;
 background-color: #d4d700;
}

.wrp-actif-body-all {
 display: flex;
 flex-direction: column;
 height: 100vh;
 width: 100%;
 background-color: var(--bg-actif);
}

.wrp-currency-acitf-all {
 display: flex;
}
.wrp-currency-acitf-all img {
 height: 2.4em;
 width: 2.4em;
 object-fit: cover;
 cursor: pointer;
}

.wrp-title-actif-all {
 display: grid;
 justify-content: center;
 place-items: center;
 width: 100%;
 margin-top: 10vh;
 margin-bottom: 5vh;
}
.wrp-title-actif-all h1 {
 display: flex;
 font-size: 2em;
 font-weight: 300;
 font-family: var(--font);
 line-height: 1;
 letter-spacing: 0.5px;
 color: var(--color-blue);
}

/* Small */
.wrp-title-actif-all-sm {
 display: grid;
 justify-content: start;
 place-items: start;
 width: 100%;
 margin: 7vh 0;
 padding: 0 1em;
}
.wrp-title-actif-all-sm h1 {
 display: flex;
 font-size: 2em;
 font-weight: 300;
 font-family: var(--font);
 line-height: 1;
 letter-spacing: 0.5px;
 color: var(--color-blue);
}

.wrp-box-balance {
 display: flex;
 flex-direction: row;
}

.wrp-box-balance-sm {
 display: flex;
 flex-direction: column;
}

/* .wrp-balance-actif-all {
 display: flex;
 flex-direction: column;
 width: 100%;
 justify-content: center;
 place-items: center;

 margin-top: 5vh;
}
.price-color-word {
 display: flex;
 font-size: 1em;
 font-weight: 300;
 font-family: var(--font);
 line-height: 1;
 letter-spacing: 0px;
 color: #1e88e5;
} */

/* .wrp-price-actif-all {
 display: flex;
 flex-direction: row;

 justify-content: end;
 align-items: end;
}
.wrp-price-actif-all p {
 display: flex;
 font-size: 3em;
 font-weight: 400;
 font-family: var(--font);
 line-height: 1;
 letter-spacing: -1.5px;
}
.wrp-price-actif-all span {
 display: flex;
 font-size: 1em;
 font-weight: 400;
 font-family: var(--font);
 line-height: 1;
 letter-spacing: 0px;
} */

/* .title-price-balance-actif {
 display: flex;
}
.title-price-balance-actif h3 {
 display: flex;
 font-size: 1.3em;
 font-weight: 400;
 font-family: var(--font);
 line-height: 1;
 letter-spacing: 0px;
} */

.wrp-balance-actif-all {
 display: flex;
 flex-direction: column;
 width: 100%;
 justify-content: center;
 place-items: center;

 margin-top: 5vh;
}
.price-color-word {
 display: flex;
 font-size: 1em;
 font-weight: 300;
 font-family: var(--font);
 line-height: 1;
 letter-spacing: 0px;
 color: var(--color-blue);
}

.wrp-price-actif-all {
 display: flex;
 flex-direction: row;

 justify-content: end;
 align-items: end;
}
.wrp-price-actif-all p {
 display: flex;
 font-size: 2em;
 font-weight: 200;
 font-family: var(--font);
 line-height: 2;
 letter-spacing: -1.5px;
}
.wrp-price-actif-all span {
 display: flex;
 font-size: 1em;
 font-weight: 100;
 font-family: var(--font);
 line-height: 1;
 letter-spacing: 0px;
}

.title-price-balance-actif {
 display: flex;
}
.title-price-balance-actif h3 {
 display: flex;
 font-size: 1.3em;
 font-weight: 400;
 font-family: var(--font);
 line-height: 1;
 letter-spacing: 0px;
}

.title-price-balance-actif-sm {
 display: flex;
}
.title-price-balance-actif-sm h3 {
 display: flex;
 font-size: 1.3em;
 font-weight: 400;
 font-family: var(--font);
 line-height: 1;
 letter-spacing: 0px;
}

.wrp-actif-body-all {
 display: flex;
 flex-direction: column;
 height: 100vh;
 width: 100%;
 background-color: var(--bg-actif);
}

.wrp-currency-acitf-all {
 display: flex;
}
.wrp-currency-acitf-all img {
 height: 2.4em;
 width: 2.4em;
 object-fit: cover;
 cursor: pointer;
}

.wrp-title-actif-all {
 display: grid;
 justify-content: center;
 place-items: center;
 width: 100%;
 margin-top: 10vh;
 margin-bottom: 5vh;
}
.wrp-title-actif-all h1 {
 display: flex;
 font-size: 2em;
 font-weight: 300;
 font-family: var(--font);
 line-height: 1;
 letter-spacing: 0.5px;
 color: var(--color-blue);
}

/* Small */
.wrp-title-actif-all-sm {
 display: grid;
 justify-content: start;
 place-items: start;
 width: 100%;
 margin: 7vh 0;
 padding: 0 1em;
}
.wrp-title-actif-all-sm h1 {
 display: flex;
 font-size: 2em;
 font-weight: 300;
 font-family: var(--font);
 line-height: 1;
 letter-spacing: 0.5px;
 color: var(--color-blue);
}

.wrp-box-balance {
 display: flex;
 flex-direction: row;
}

.wrp-box-balance-sm {
 display: flex;
 flex-direction: column;
}

/* .wrp-balance-actif-all {
 display: flex;
 flex-direction: column;
 width: 100%;
 justify-content: center;
 place-items: center;

 margin-top: 5vh;
}
.price-color-word {
 display: flex;
 font-size: 1em;
 font-weight: 300;
 font-family: var(--font);
 line-height: 1;
 letter-spacing: 0px;
 color: #1e88e5;
} */

/* .wrp-price-actif-all {
 display: flex;
 flex-direction: row;

 justify-content: end;
 align-items: end;
}
.wrp-price-actif-all p {
 display: flex;
 font-size: 3em;
 font-weight: 400;
 font-family: var(--font);
 line-height: 1;
 letter-spacing: -1.5px;
}
.wrp-price-actif-all span {
 display: flex;
 font-size: 1em;
 font-weight: 400;
 font-family: var(--font);
 line-height: 1;
 letter-spacing: 0px;
} */

/* .title-price-balance-actif {
 display: flex;
}
.title-price-balance-actif h3 {
 display: flex;
 font-size: 1.3em;
 font-weight: 400;
 font-family: var(--font);
 line-height: 1;
 letter-spacing: 0px;
} */

.wrp-balance-actif-all {
 display: flex;
 flex-direction: column;
 width: 100%;
 justify-content: center;
 place-items: center;

 margin-top: 5vh;
}
.price-color-word {
 display: flex;
 font-size: 1em;
 font-weight: 300;
 font-family: var(--font);
 line-height: 1;
 letter-spacing: 0px;
 color: var(--color-blue);
}

/* .wrp-price-actif-all {
 display: flex;
 flex-direction: row;

 justify-content: end;
 align-items: end;
}
.wrp-price-actif-all p {
 display: flex;
 font-size: 3em;
 font-weight: 400;
 font-family: var(--font);
 line-height: 1;
 letter-spacing: -1.5px;
}
.wrp-price-actif-all span {
 display: flex;
 font-size: 1.4em;
 font-weight: 400;
 font-family: var(--font);
 line-height: 1;
 letter-spacing: 0px;
} */

.title-price-balance-actif {
 display: flex;
}
.title-price-balance-actif h3 {
 display: flex;
 font-size: 1.3em;
 font-weight: 400;
 font-family: var(--font);
 line-height: 1;
 letter-spacing: 0px;
}

.title-price-balance-actif-sm {
 display: flex;
}
.title-price-balance-actif-sm h3 {
 display: flex;
 font-size: 1.3em;
 font-weight: 400;
 font-family: var(--font);
 line-height: 1;
 letter-spacing: 0px;
}

.wrp-hm-info {
 display: grid;
 grid-template-rows: auto 1fr auto;
 width: 100%;
 height: 100%;
 min-height: 100vh;
}

.flex-between {
 display: flex;
 justify-content: space-between;
 place-items: center;
 width: 100%;
}

.wrp-head-hm-info {
 padding: 0.7rem;
 grid-column: 1 / 3;
}
.wrp-head-hm-dash-info {
 padding: 0.7rem;
 grid-column: 1 / 4;
}
.wrp-head-hm-dash-info-two {
 grid-column: 1 / 3;
}
.wrp-head-hm-dash-info-one {
 grid-column: 1 / 2;
}

.wrp-head-hm-info-small {
 padding: 0.7rem;
 grid-column: 1 / 2;
}
.wrp-head-hm-dash-info-small {
 padding: 0.7rem;
 grid-column: 1 / 2;
}

.wrp-head-hm-info-client {
 padding: 0.7rem;
 grid-column: 1 / 4;
}
.flex-border-bottom {
 border-bottom: 1px solid #d3d3d3;
}
.flex-border-left {
 border-left: 1px solid #d3d3d3;
}
.flex-border-rigth {
 border-right: 1px solid #d3d3d3;
}

.wrp-update-edit-detail-user {
 display: grid;
 grid-template-rows: auto 1fr auto;
 height: 100%;
 min-height: 100vh;
}

.wrp-nav-detail-user {
 display: flex;
 justify-content: space-between;
 place-items: center;
 width: 100%;
 height: 6ch;
 padding: 0 3%;
 background-color: #1b819b;
}

.wrp-save-edit-info {
 display: flex;
}
.wrp-save-edit-info h2 {
 display: flex;
 font-size: 0.9em;
 font-weight: 300;
 line-height: 1;
 letter-spacing: 0.3px;
 font-family: var(--font);
 text-transform: uppercase;
 cursor: pointer;
 color: #fff;
}

/* .wrp-list-body-update {
 display: flex;
 flex-direction: column;
 margin: 5vh 3%;
}
.letter-space {
 display: flex;
 font-size: 1em;
 font-weight: 300;
 line-height: 1;
 letter-spacing: 20px;
 line-height: 1;
} */

.flex-between-edit {
 display: flex;
 width: 100%;
 justify-content: space-between;
 place-items: center;
 padding: 0.5em;
}
.title-pop-up {
 display: flex;
 font-size: 1em;
 font-weight: 300;
 line-height: 1;
 margin-bottom: -1vh;

 letter-spacing: 0px;
 font-family: var(--font);
}
.flex-between-edit .flex-block {
 display: grid;
 place-items: start;
}
.flex-between-edit .flex-block span {
 display: flex;
 font-size: 0.8em;
 font-weight: 300;
 line-height: 2;
 /* padding: 2vh 0; */
 color: #00b4d8;

 letter-spacing: 0.5px;
 font-family: var(--font);
}
.flex-between-edit p {
 display: flex;
 font-size: 0.9em;
 font-weight: 300;
 line-height: 1;
 padding: 1.5vh 0;

 letter-spacing: 0px;
 font-family: var(--font);
}

/* Small */
.flex-between-edit-sm {
 display: flex;
 width: 100%;
 justify-content: space-between;
 place-items: center;
 padding: 0.3em;
}
.flex-between-edit-sm form {
 display: flex;
 flex-direction: row;
 width: 100%;
 justify-content: space-between;
 place-items: center;
}

.title-pop-up-sm {
 display: flex;
 font-size: 1.11em;
 font-weight: 300;
 line-height: 1;
 margin-bottom: 3vh;

 letter-spacing: 0px;
 font-family: var(--font);
}
.flex-between-edit-sm .flex-block-sm {
 display: grid;
 place-items: start;
}
.flex-between-edit-sm .flex-block-sm span {
 display: flex;
 font-size: 3vw;
 font-weight: 300;
 line-height: 1;
 padding: 0.5vh 0;
 color: #00b4d8;

 letter-spacing: 0.5px;
 font-family: var(--font);
}
.flex-between-edit-sm p {
 display: flex;
 font-size: 0.8em;
 font-weight: 300;
 line-height: 1;
 padding: 0.5vh 0;

 letter-spacing: 0px;
 font-family: var(--font);
}

.pop-up-low {
 display: flex;
 font-size: 0.9em;
 font-weight: 100;
 line-height: 1.3;
 padding: 0.4vh 0;
 letter-spacing: 0px;
 font-family: var(--font);
}

/* css flex active */
.flex-grid-active {
 display: grid;
 height: 100%;
 place-items: center;
}

.flex-width-100 {
 display: flex;
 width: 100%;
}
.flex-center {
 display: grid;
 height: 100%;
 width: 100%;
 place-items: center;
}

.wrp-grid-center {
 display: grid;
 place-items: center;
}
.wrp-grid-center p {
 display: flex;
 font-size: 1.2em;
 font-weight: 300;
 line-height: 1;
 letter-spacing: 0px;
 font-family: var(--font);
 margin-top: 2vh;
}

/* Small */
.wrp-grid-center-sm {
 display: grid;
 place-items: center;
}
.wrp-grid-center-sm p {
 display: flex;
 font-size: 0.9em;
 font-weight: 300;
 line-height: 1;
 letter-spacing: 0px;
 font-family: var(--font);
 margin-top: 2vh;
}

/* .flex-width-100 {
 display: flex;
 width: 100%;
}
.flex-center {
 display: grid;
 height: 100%;
 width: 100%;
 place-items: center;
}

.wrp-grid-center {
 display: grid;
 place-items: center;
}
.wrp-grid-center p {
 display: flex;
 font-size: 1.2em;
 font-weight: 300;
 line-height: 1;
 letter-spacing: 0px;
 font-family: var(--font);
 margin-top: 2vh;
} */

.wrp-update-edit-detail-user {
 display: grid;
 grid-template-rows: auto 1fr auto;
 height: 100%;
 min-height: 100vh;
}

.wrp-nav-detail-user {
 display: flex;
 justify-content: space-between;
 place-items: center;
 width: 100%;
 height: 8ch;
 padding: 0 3%;
 background-color: #1b819b;
}

/* .wrp-avatar-transfer-identity {
 display: flex;
 place-items: center;
}

.wrp-avatar-transfer-identity p {
 display: flex;
 font-size: 0.9em;
 font-weight: 300;
 line-height: 1;
 letter-spacing: 0.3px;
 font-family: var(--font);
 margin-left: 1em;
 color: #fff;
} */

.wrp-body-exchange-buy-detail {
 display: flex;
 padding: 3%;
}

.list-exchange-buy-detail {
 display: flex;
 flex-direction: column;
 width: 100%;
}
.list-top-exchange-buy-detail {
 display: flex;
 flex-direction: column;
 border-bottom: 1px solid #e7ecef;

 padding-top: 7vh;
}
.list-sub-exchange-buy-detail {
 display: flex;
 justify-content: space-between;
 place-items: center;
 width: 100%;
}
.list-sub-exchange-buy-detail p {
 display: flex;
 font-size: 0.7em;
 font-weight: 400;
 line-height: 0.5;
 white-space: nowrap;
 letter-spacing: 0px;
 font-family: var(--font);
}

.list-top-exchange-info-detail {
 display: flex;
 flex-direction: column;
 border-bottom: 1px solid #e7ecef;

 padding-top: 7vh;
}
.list-sub-exchange-info-detail {
 display: flex;
 justify-content: space-between;
 place-items: center;
 width: 100%;
}
.list-sub-exchange-info-detail p {
 display: flex;
 font-size: 0.7em;
 font-weight: 400;

 padding-top: 2vh;

 line-height: 2;
 white-space: nowrap;
 letter-spacing: 0px;
 font-family: var(--font);
}

.list-exchange-buy-detail-view {
 display: flex;
 flex-direction: column;
 width: 100%;
}
.list-top-exchange-buy-detail-view {
 display: flex;
 flex-direction: column;
 border-radius: 1em;
 padding: 0.7em;
 margin: 1vh 0;
}
.list-sub-exchange-buy-detail-view {
 display: flex;
 justify-content: space-between;
 place-items: center;
 width: 100%;
}
.list-sub-exchange-buy-detail-view p {
 display: flex;
 font-size: 0.7em;
 font-weight: 400;
 line-height: 0.5;
 white-space: nowrap;
 letter-spacing: 0px;
 font-family: var(--font);
}

.title-head-transfer-self {
 display: flex;
 margin: 6vh 0;
}
.title-head-transfer-self h3 {
 display: flex;
 font-size: 1em;
 font-weight: 400;
 line-height: 0;
 letter-spacing: 0px;
 font-family: var(--font);
}

.title-head-transfer-self-sm {
 display: flex;
 margin: 6vh 0;
}
.title-head-transfer-self-sm h3 {
 display: flex;
 font-size: 1.2em;
 font-weight: 300;
 white-space: nowrap;
 line-height: 1;
 letter-spacing: 0px;
 font-family: var(--font);
}


.flex-grid-center-creditaire {
 display: flex;
 justify-content: space-between;
 place-items: center;
}

.wrp-exchange-buy-detail {
 display: flex;
 flex-direction: column;
 height: 100%;
 min-height: 100vh;
}

.wrp-update-edit-detail-user {
 display: grid;
 grid-template-rows: auto 1fr auto;
 height: 100%;
 min-height: 100vh;
}

.wrp-nav-detail-user {
 display: flex;
 justify-content: space-between;
 place-items: center;
 width: 100%;
 height: 6ch;
 padding: 0 3%;
 background-color: #1b819b;
}

.wrp-avatar-transfer-identity {
 display: flex;
 place-items: center;
}
.wrp-avatar-transfer-identity p {
 display: flex;
 font-size: 0.8em;
 font-weight: 300;
 line-height: 0;
 letter-spacing: 0.3px;
 font-family: var(--font);
 margin-left: 0.5em;
 padding-top: 3ch;
 color: #fff;
}

/* Small */
.wrp-avatar-transfer-identity-sm {
 display: grid;
 place-items: center;
}
.wrp-avatar-transfer-identity-sm p {
 display: flex;
 font-size: 0.8em;
 font-weight: 100;
 line-height: 0;
 text-align: end;
 letter-spacing: 0.3px;
 font-family: var(--font);
 color: #fff;
}

.wrp-small-screen-withus-user-date {
 display: grid;
}
.wrp-small-screen-withus-user-date p {
 display: flex;
 font-size: 0.9em;
 font-weight: 300;
 line-height: 0;
 white-space: nowrap;
 letter-spacing: 0px;
 font-family: var(--font);
 color: #fff;
}

/* Small */
.wrp-small-screen-withus-user-date-sm {
 display: grid;
 margin-top: 2ch;
}
.wrp-small-screen-withus-user-date-sm p {
 display: flex;
 font-size: 0.9em;
 font-weight: 300;
 line-height: 0;
 white-space: nowrap;
 letter-spacing: 0px;
 font-family: var(--font);
 color: #fff;
}

.color-purple-info {
 padding: 1vh 2%;
 color: #5e548e;
 background-color: #d5cfe8;
}
.color-purple-info-low {
 color: #494949;
 padding: 1vh 2%;
}

.between-no-width {
 display: flex;
 justify-content: space-between;
 place-items: center;
 grid-gap: 1em;
 gap: 1em;
}

.wrp-logo-info {
 display: flex;
 flex-direction: row;
}
.wrp-logo-info h1 {
 display: flex;
 font-size: 0.85em;
 font-weight: 300;
 font-family: "Roboto Condensed", sans-serif;
 line-height: 1;
 letter-spacing: 0px;
}

.color-info-logo {
 color: #f26419;
}

/* CSS ID */

.flex-id-phone {
 display: flex;
 margin-left: 1em;
}
.flex-id-phone h3 {
 display: flex;
 font-size: 1.155em;
 font-weight: 500;
 line-height: 1;
 letter-spacing: 0px;
 font-family: var(--font);
}
.pad-top-bottom-2 {
 margin: 1.5vh 0;
}

.flex-no-width {
 display: flex;
 justify-content: space-around;
 place-items: center;
}

.wrp-avatar-transfer-view {
 display: flex;
 place-items: center;
}
.wrp-avatar-transfer-view p {
 display: flex;
 font-size: 0.9em;
 font-weight: 300;
 line-height: 1;
 letter-spacing: 0.3px;
 font-family: var(--font);
 margin-left: 1em;
 color: #000;
}

/* Small */
.wrp-avatar-transfer-view-sm {
 display: flex;
 place-items: center;
}
.wrp-avatar-transfer-view-sm p {
 display: flex;
 font-size: 0.7em;
 font-weight: 300;
 line-height: 1;
 letter-spacing: 0.3px;
 font-family: var(--font);
 margin-left: 1em;
 color: #000;
}

.pad-lf-rh-3 {
 padding: 2vh 1em;
}

.pad-total-info {
 padding: 1vh 0;
 border-right: 1px solid #d3d3d3;
}

.title-count-total-info {
 display: flex;
}
.title-count-total-info h2 {
 display: flex;
 font-size: 0.9em;
 font-weight: 400;
 line-height: 1.3;
 letter-spacing: 0px;
 font-family: var(--font);
}

.flex-count-total-info {
 display: flex;
 padding: 1ch 0;
}
.count-agent-total-info {
 display: flex;
 flex-direction: column;
 place-items: start;
}
.count-agent-total-info p {
 display: flex;
 font-size: 0.9em;
 font-weight: 400;
 line-height: 0.5;
 letter-spacing: 0.3px;
 font-family: var(--font);

 color: #ff3f00;
}

.wrp-column-hm-dash-list-user {
 display: flex;
 flex-direction: column;
 justify-content: start;
 margin-top: 5vh;
}
.wrp-column-hm-dash-list-user form {
 display: flex;
 flex-direction: row;
 padding-left: center;
 justify-content: space-between;
 width: 100%;
 padding: 1.2vh 1em;
 border-radius: 0.5em;
 background-color: #e6e6ea;
}
.wrp-column-hm-dash-list-user form input {
 display: flex;
 border: none;
 background: transparent;

 width: 100%;

 font-size: 1em;
 font-weight: 300;
 line-height: 1;
 letter-spacing: 0px;
 font-family: var(--font);
}

.wrp-hm-dash-list-user {
 display: flex;
 justify-content: space-between;
 place-items: center;
 width: 100%;
 cursor: pointer;
 margin-top: 5vh;
 max-width: 100vw;
 padding-bottom: 1vh;
 border-bottom: 1px solid #eeeeee;
}
.flex-column-list-user-all {
 display: flex;
 justify-content: start;
 place-items: center;
}

.wrp-hm-dash-list-user h3 {
 display: flex;
 font-size: 0.9em;
 font-weight: 500;
 line-height: 1;
 letter-spacing: 0px;
 font-family: var(--font);
}
.wrp-hm-dash-list-user span {
 display: grid;
 place-items: start;
 justify-content: start;
}
.wrp-hm-dash-list-user p {
 display: flex;
 font-size: 0.7em;
 font-weight: 400;
 text-align: start;
 white-space: nowrap;
 line-height: 0;
 letter-spacing: 0px;
 font-family: var(--font);
 width: 7em;
 margin-top: 2vh;
 color: #5b5b5b;
}

.flex-money-user-list {
 display: flex;
 flex-direction: column;
}

.flex-money-user-list span {
 display: flex;
 font-size: 0.7em;
 width: 10em;

 font-weight: 400;
 text-align: center;
 white-space: nowrap;
 letter-spacing: 0px;
 font-family: var(--font);
 color: #5b5b5b;
}

.scroll-user-list-total {
 overflow: scroll;
 height: 100vh;
}
.scroll-user-list-tontine {
 overflow: scroll;
 height: 80vh;
}

.title-actif-info {
 display: flex;
 margin: 2vh 0;
}
.title-actif-info h1 {
 display: flex;
 font-size: 0.9em;
 font-weight: 400;
 line-height: 1;
 letter-spacing: 0px;
 font-family: var(--font);
}

.wrp-all-actif-user-info {
 display: grid;
 place-items: start;
}
.wrp-all-actif-user-info h4 {
 display: flex;
 font-size: 0.9em;
 font-weight: 400;
 line-height: 1;
 letter-spacing: 0px;
 font-family: var(--font);
 margin-bottom: 2.5vh;
 color: #9999a1;
}
.wrp-all-actif-user-info p {
 display: flex;
 font-size: 0.9em;
 font-weight: 400;
 line-height: 1;
 white-space: nowrap;
 letter-spacing: 0px;
 font-family: var(--font);

 margin-bottom: 1vh;
}
.actif-red {
 color: #f21b3f;
}

.wrp-all-actif-user-info-sm {
 display: grid;
 place-items: start;
}
.wrp-all-actif-user-info-sm h4 {
 display: flex;
 font-size: 0.9em;
 font-weight: 400;
 line-height: 1;
 letter-spacing: 0px;
 font-family: var(--font);
 margin-bottom: 2.5vh;
 color: #9999a1;
}
.wrp-all-actif-user-info-sm p {
 display: flex;
 font-size: 0.7em;
 font-weight: 400;
 line-height: 1;
 white-space: nowrap;
 letter-spacing: 0px;
 font-family: var(--font);

 margin-bottom: 1vh;
}

.pad-left-right-3 {
 padding: 0 3%;
}

.wrp-info-info-detail {
 display: grid;
}

.pad-left-right-3 {
 padding: 0.2ch 3%;
}

.wrp-withus-user-info {
 display: grid;
}
.wrp-withus-user-info p {
 display: flex;
 font-size: 0.9em;
 font-weight: 400;
 line-height: 0.3;
 white-space: nowrap;
 letter-spacing: 0px;
 font-family: var(--font);
 color: #444;
}

.color-purple-info {
 padding: 0 2%;
 color: #5e548e;
 background-color: #d5cfe8;
}
.color-purple-info-low {
 padding: 0 2%;
 color: #494949;
}

.flex-vh-100 {
 display: grid;
 grid-template-columns: minmax(150px, 45%) 1fr;

 height: 100%;
 min-height: 100vh;
}

.flex-vh-100-sm {
 display: grid;
 height: 100%;
 min-height: 100vh;
}

.wrp-side-info {
 display: grid;
 place-items: center;
 height: 100%;
 background-color: #f5f3f4;
}

/* .flex-img-side-info {
 display: grid;
 place-items: center;
}
.flex-img-side-info img {
 height: 17vw;
 width: 17vw;
 object-fit: cover;
 margin-bottom: 2vh;
} */

.grid-center {
 display: grid;
 place-items: center;
 height: 100%;
}

/* .title-pin-hm-main-info {
 display: grid;
 place-items: center;
 width: 100%;
 max-width: 35vw;
}
.title-pin-hm-main-info h2 {
 display: flex;
 font-size: 1.2em;
 font-weight: 400;
 line-height: 1;
 font-family: var(--font);
 letter-spacing: 0px;
 margin-bottom: 1ch;
}
.title-pin-hm-main-info p {
 display: flex;
 font-size: 1em;
 font-weight: 400;
 line-height: 1.2;
 font-family: var(--font);
 letter-spacing: 0px;
 text-align: center;
 color: #aaaaaa;
}

Small
.title-pin-hm-main-info-sm {
 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: center;
 width: 100%;
 max-width: 85vw;
}
.title-pin-hm-main-info-sm h2 {
 display: flex;
 font-size: 1.2em;
 font-weight: 400;
 line-height: 1;
 font-family: var(--font);
 letter-spacing: 0px;
 margin-bottom: 1ch;
}
.title-pin-hm-main-info-sm p {
 display: flex;
 font-size: 1em;
 font-weight: 400;
 line-height: 1.2;
 font-family: var(--font);
 letter-spacing: 0px;
 text-align: center;
 color: #aaaaaa;
} */

.wrp-form-input-pin-nows {
 display: flex;
 justify-content: center;
 align-items: center;
 width: 100%;
 max-width: 25em;
}
.wrp-form-input-pin-nows form {
 display: flex;
 flex-direction: column;
 justify-content: center;

 place-items: center;
 padding: 1em;

 width: 100%;
 max-width: 100vw;
}
.wrp-form-input-pin-nows input {
 display: flex;
 font-size: 1.5em;
 font-weight: 500;
 font-family: var(--font);
 line-height: 1;

 letter-spacing: 8px;
 text-align: center;
 padding-left: 1.9em;
 border: none;
}
.wrp-form-input-pin-nows form button {
 display: flex;
 margin-top: 2vh;
 max-width: 100vw;
 font-size: 1em;
 border-radius: 0.6em;
 padding: 0.3em;
 font-family: var(--font);
}
.wrp-form-input-pin-nows form p {
 display: flex;
 font-size: 1.1em;
 font-weight: 300;
 line-height: 1.2;
 text-align: center;
 letter-spacing: 0px;
 font-family: var(--font);
 margin: 3vh 0;
}
.title-pop-pret {
 display: flex;
 font-size: 1.155em;
 font-weight: 300;
 font-family: var(--font);
 line-height: 1;
 letter-spacing: 0.3px;
 color: #2dc653;
}
.paragraph-pop-pret {
 display: flex;
 font-size: 0.9em;
 font-weight: 300;
 font-family: var(--font);
 line-height: 1;
 letter-spacing: 0.3px;
 color: #595959;

 margin: 4vh 0;
}
.flex-active-ask-pret {
 display: grid;
 place-items: start;
 width: 100%;
 grid-gap: 1em;
 gap: 1em;
}
.flex-input-pret-modulo {
 display: grid;
 place-items: start;
}
.flex-input-pret-modulo label {
 display: flex;
 font-size: 1.1em;
 font-weight: 300;
 line-height: 1.2;
 text-align: center;
 letter-spacing: 0px;
 font-family: var(--font);
 margin: 1vh 0;
 margin-top: 3vh;
 color: #2b303a;
}
.flex-input-pret-modulo input {
 display: flex;
 font-size: 1.3em;
 font-weight: 100;
 font-family: var(--font);
 line-height: 1;
 letter-spacing: 0px;
 border: none;
 color: #2b303a;
}

/* Small Pin */
.wrp-form-input-pin-nows-sm {
 display: flex;
 justify-content: center;
 align-items: center;
 width: 100%;
}

.wrp-form-input-pin-nows-sm input {
 display: flex;
 font-size: 1.5em;
 font-weight: 500;
 font-family: var(--font);
 line-height: 1;

 letter-spacing: 8px;
 text-align: center;
 padding-left: 1.9em;
 border: none;
}
.wrp-form-input-pin-nows-sm form button {
 display: flex;
 margin-top: 2vh;
 max-width: 100vw;
 font-size: 1.115em;
 border-radius: 0.6em;
 padding: 0.5em;
 font-family: var(--font);
}
.wrp-form-input-pin-nows-sm form p {
 display: flex;
 font-size: 1.1em;
 font-weight: 300;
 line-height: 1.2;
 text-align: center;
 letter-spacing: 0px;
 font-family: var(--font);
 margin: 3vh 0;
}
.title-pop-pret {
 display: flex;
 font-size: 1.155em;
 font-weight: 300;
 font-family: var(--font);
 line-height: 1;
 letter-spacing: 0.3px;
 color: #2dc653;
}
.paragraph-pop-pret {
 display: flex;
 font-size: 0.9em;
 font-weight: 300;
 font-family: var(--font);
 line-height: 1;
 letter-spacing: 0.3px;
 color: #595959;

 margin: 4vh 0;
}
.flex-active-ask-pret {
 display: grid;
 place-items: start;
 width: 100%;
 grid-gap: 1em;
 gap: 1em;
}
.flex-input-pret-modulo {
 display: grid;
 place-items: start;
}
.flex-input-pret-modulo label {
 display: flex;
 font-size: 1.1em;
 font-weight: 300;
 line-height: 1.2;
 text-align: center;
 letter-spacing: 0px;
 font-family: var(--font);
 margin: 1vh 0;
 margin-top: 3vh;
 color: #2b303a;
}
.flex-input-pret-modulo input {
 display: flex;
 font-size: 1.3em;
 font-weight: 100;
 font-family: var(--font);
 line-height: 1;
 letter-spacing: 0px;
 border: none;
 color: #2b303a;
}

.flex-vh-100 {
 display: grid;
 grid-template-columns: minmax(150px, 45%) 1fr;

 height: 100%;
 min-height: 100vh;
}

.flex-vh-100-sm {
 display: grid;
 height: 100%;
 min-height: 100vh;
}

.wrp-side-info {
 display: grid;
 place-items: center;
 height: 100%;
 background-color: #f5f3f4;
}

.flex-img-side-tontine {
 display: grid;
 place-items: center;
}
.flex-img-side-tontine img {
 height: 25vh;
 width: 100%;
 object-fit: contain;
}

.grid-center {
 display: grid;
 place-items: center;
 height: 100%;
}

/* .title-pin-hm-main-info {
 display: grid;
 place-items: center;
 width: 100%;
 max-width: 35vw;
}
.title-pin-hm-main-info h2 {
 display: flex;
 font-size: 1.2em;
 font-weight: 400;
 line-height: 1;
 font-family: var(--font);
 letter-spacing: 0px;
 margin-bottom: 1ch;
}
.title-pin-hm-main-info p {
 display: flex;
 font-size: 1em;
 font-weight: 400;
 line-height: 1.2;
 font-family: var(--font);
 letter-spacing: 0px;
 text-align: center;
 color: #aaaaaa;
}

Small
.title-pin-hm-main-info-sm {
 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: center;
 width: 100%;
 max-width: 85vw;
}
.title-pin-hm-main-info-sm h2 {
 display: flex;
 font-size: 1.2em;
 font-weight: 400;
 line-height: 1;
 font-family: var(--font);
 letter-spacing: 0px;
 margin-bottom: 1ch;
}
.title-pin-hm-main-info-sm p {
 display: flex;
 font-size: 1em;
 font-weight: 400;
 line-height: 1.2;
 font-family: var(--font);
 letter-spacing: 0px;
 text-align: center;
 color: #aaaaaa;
} */

/* .wrp-form-input-pin-nows {
 display: flex;
 justify-content: center;
 align-items: center;
 width: 100%;
 max-width: 25em;
}
.wrp-form-input-pin-nows form {
 display: flex;
 flex-direction: column;
 justify-content: center;

 place-items: center;
 padding: 1em;

 width: 100%;
 max-width: 100vw;
}
.wrp-form-input-pin-nows input {
 display: flex;
 font-size: 1.5em;
 font-weight: 500;
 font-family: var(--font);
 line-height: 1;

 letter-spacing: 8px;
 text-align: center;
 padding-left: 1.9em;
 border: none;
}
.wrp-form-input-pin-nows form button {
 display: flex;
 margin-top: 2vh;
 max-width: 100vw;
 font-size: 1.115em;
 border-radius: 0.6em;
 padding: 0.5em;
 font-family: var(--font);
}
.wrp-form-input-pin-nows form p {
 display: flex;
 font-size: 1.1em;
 font-weight: 300;
 line-height: 1.2;
 text-align: center;
 letter-spacing: 0px;
 font-family: var(--font);
 margin: 3vh 0;
}
.title-pop-pret {
 display: flex;
 font-size: 1.155em;
 font-weight: 300;
 font-family: var(--font);
 line-height: 1;
 letter-spacing: 0.3px;
 color: #2dc653;
}
.paragraph-pop-pret {
 display: flex;
 font-size: 0.9em;
 font-weight: 300;
 font-family: var(--font);
 line-height: 1;
 letter-spacing: 0.3px;
 color: #595959;

 margin: 4vh 0;
}
.flex-active-ask-pret {
 display: grid;
 place-items: start;
 width: 100%;
 gap: 1em;
}
.flex-input-pret-modulo {
 display: grid;
 place-items: start;
}
.flex-input-pret-modulo label {
 display: flex;
 font-size: 1.1em;
 font-weight: 300;
 line-height: 1.2;
 text-align: center;
 letter-spacing: 0px;
 font-family: var(--font);
 margin: 1vh 0;
 margin-top: 3vh;
 color: #2b303a;
}
.flex-input-pret-modulo input {
 display: flex;
 font-size: 1.3em;
 font-weight: 100;
 font-family: var(--font);
 line-height: 1;
 letter-spacing: 0px;
 border: none;
 color: #2b303a;
}

Small Pin
.wrp-form-input-pin-nows-sm {
 display: flex;
 justify-content: center;
 align-items: center;
 width: 100%;
}

.wrp-form-input-pin-nows-sm input {
 display: flex;
 font-size: 1.5em;
 font-weight: 500;
 font-family: var(--font);
 line-height: 1;

 letter-spacing: 8px;
 text-align: center;
 padding-left: 1.9em;
 border: none;
}
.wrp-form-input-pin-nows-sm form button {
 display: flex;
 margin-top: 2vh;
 max-width: 100vw;
 font-size: 1.115em;
 border-radius: 0.6em;
 padding: 0.5em;
 font-family: var(--font);
}
.wrp-form-input-pin-nows-sm form p {
 display: flex;
 font-size: 1.1em;
 font-weight: 300;
 line-height: 1.2;
 text-align: center;
 letter-spacing: 0px;
 font-family: var(--font);
 margin: 3vh 0;
}
.title-pop-pret {
 display: flex;
 font-size: 1.155em;
 font-weight: 300;
 font-family: var(--font);
 line-height: 1;
 letter-spacing: 0.3px;
 color: #2dc653;
}
.paragraph-pop-pret {
 display: flex;
 font-size: 0.9em;
 font-weight: 300;
 font-family: var(--font);
 line-height: 1;
 letter-spacing: 0.3px;
 color: #595959;

 margin: 4vh 0;
}
.flex-active-ask-pret {
 display: grid;
 place-items: start;
 width: 100%;
 gap: 1em;
}
.flex-input-pret-modulo {
 display: grid;
 place-items: start;
}
.flex-input-pret-modulo label {
 display: flex;
 font-size: 1.1em;
 font-weight: 300;
 line-height: 1.2;
 text-align: center;
 letter-spacing: 0px;
 font-family: var(--font);
 margin: 1vh 0;
 margin-top: 3vh;
 color: #2b303a;
}
.flex-input-pret-modulo input {
 display: flex;
 font-size: 1.3em;
 font-weight: 100;
 font-family: var(--font);
 line-height: 1;
 letter-spacing: 0px;
 border: none;
 color: #2b303a;
} */


.grid-template-tontine {
 display: grid;
 grid-template-rows: auto 1fr auto;
 width: 100%;
 height: 100%;
 min-height: 100vh;
}
.grid-template-tontine-sm {
 display: grid;
 grid-template-rows: auto 1fr auto;
 width: 100%;
 height: 100%;
 min-height: 100vh;
}

.wrp-logo {
 display: flex;
 cursor: pointer;
}
.wrp-logo img {
 height: 3em;
 width: 3em;
 object-fit: cover;
}

.grid-template-tontine .wrp-head-1fr {
 padding: 2rem;
 grid-column: 1 / 3;
 background-color: #edf5fd;
}
.grid-template-tontine-sm .wrp-head-1fr-sm {
 padding: 2rem;
 grid-column: 1 / 1;
 background-color: #edf5fd;
}

.pad-right-left-3 {
 display: flex;
}
.border-bottom-1 {
 display: flex;
 border-bottom: 0.3px solid #e9ecef;
}
.border-rigth-1 {
 display: flex;
 border-right: 0.3px solid #e9ecef;
}
.border-left-1 {
 display: flex;
 height: 100%;
 border-left: 0.3px solid #e9ecef;
}

.class-group-css-tontine {
 display: flex;
}
.class-group-css-tontine h2 {
 display: flex;
 font-size: 0.9em;
 font-weight: 100;
 line-height: 1;
 letter-spacing: 0px;
 font-family: var(--font);
 text-transform: uppercase;
}


.wrp-count-head-tontine {
 display: flex;
 margin-top: 1vh;
}
.wrp-count-head-tontine h3 {
 display: flex;
 font-size: 0.9em;
 font-weight: 300;
 letter-spacing: 0px;
 font-family: var(--font);
 line-height: 0;
 padding-top: 2vh;
 color: #0077b6;
}
.wrp-count-head-tontine p {
 display: flex;
 font-size: 0.9em;
 font-weight: 300;
 line-height: 0;
 letter-spacing: 0px;
 font-family: var(--font);
 color: #0077b6;
}

/* .flex-head-group-tontine {
 display: flex;
 padding: 3vh 5%;
} */

.list-bd-group-tontine {
 display: flex;
 padding: 2vh 0;
}
.list-bd-group-tontine h2 {
 display: flex;
 font-size: 0.855em;
 font-weight: 300;
 line-height: 1;
 letter-spacing: 0px;
 white-space: nowrap;

 font-family: var(--font);
 text-transform: uppercase;
}
.list-bd-group-tontine h3 {
 display: flex;
 font-size: 0.855em;
 font-weight: 300;
 white-space: nowrap;
 line-height: 1;
 letter-spacing: 0px;
 font-family: var(--font);
}


.wrp-count-head-tontine {
 display: flex;
 margin-top: 3vh;
}
.wrp-count-head-tontine h3 {
 display: flex;
 font-size: 0.9em;
 font-weight: 300;
 letter-spacing: 0px;
 font-family: var(--font);
 color: #0077b6;
}
.wrp-count-head-tontine p {
 display: flex;
 font-size: 0.9em;
 font-weight: 300;
 line-height: 3;
 letter-spacing: 0px;
 font-family: var(--font);
 color: #0077b6;
}

/* .flex-head-group-tontine {
 display: flex;
 padding: 3vh 5%;
} */

/* .list-bd-group-tontine {
 display: flex;
 padding: 2% 0.5em;
}
.list-bd-group-tontine h2 {
 display: flex;
 font-size: 0.8em;
 font-weight: 300;
 line-height: 1;
 letter-spacing: 0px;
 font-family: var(--font);
 text-transform: uppercase;
}
.list-bd-group-tontine h3 {
 display: flex;
 font-size: 0.8em;
 font-weight: 300;
 line-height: 1;
 letter-spacing: 0.4px;
 font-family: var(--font);
} */

.grid-user-tontine {
 display: grid;
}

.wrp-count-head-tontine-total {
 display: flex;
}
.wrp-count-head-tontine-total p {
 display: flex;
 font-size: 1em;
 font-weight: 200;
 line-height: 1;
 white-space: nowrap;
 letter-spacing: 0px;
 font-family: var(--font);
 color: #0077b6;
}

.flex-head-group-tontine {
 display: flex;
 height: 6ch;
 padding: 0 5%;
}

.title-contri-body-user {
 display: flex;
 padding: 0 2.3em;
}
.title-contri-body-user h4 {
 display: flex;
 font-weight: 300;
 font-size: 0.9em;
 line-height: 4;
 white-space: nowrap;
 letter-spacing: 0px;
 font-family: var(--font);
 color: #6c757d;
}

/* Small  */
.title-contri-body-user-sm {
 display: flex;
 width: 100%;

 justify-content: space-between;
 place-items: center;
}
.title-contri-body-user-sm h4 {
 display: flex;
 font-weight: 300;
 font-size: 0.9em;
 line-height: 1;
 white-space: nowrap;
 letter-spacing: 0px;
 font-family: var(--font);
 color: #6c757d;
}

.border-rising {
 padding: 0.088em;
 border-radius: 100%;
 border: 2.2122px solid #00bbf9;
}
.border-asked {
 padding: 0.088em;
 border-radius: 100%;
 border: 2.2122px solid #06d6a0;
}
.border-white {
 padding: 0.088em;
 border-radius: 100%;
 border: 2.2122px solid #fff;
}

.flex-marge-left-3 {
 margin-left: 0.1em;
}
.flex-row {
 display: flex;
 flex-direction: row;
 place-items: center;
}

.img-list-user-tontine {
 display: flex;
 margin-left: 1em;
}
.img-list-user-tontine img {
 height: 2em;
 width: 2em;
 object-fit: cover;
}

.flex-grid-column {
 display: grid;
 grid-template-rows: auto 1fr auto;
 height: 100%;
}

.flex-height-25-vh {
 display: flex;
 width: 100%;
 height: 25vh;
 background-color: #e6e6e6;
}
.flex-pad-left-right-3 {
 display: flex;
 padding: 0 3%;
}

.flex-head-profil-info-tontine {
 display: grid;
 place-items: center;
}
.flex-head-profil-info-tontine p {
 display: flex;
 font-size: 1em;
 font-weight: 200;
 line-height: 1;
 letter-spacing: 0px;
 font-family: var(--font);
 margin-top: 2vh;
}

.title-head-transfer {
 display: flex;
}
.title-head-transfer h3 {
 display: flex;
 font-size: 1em;
 font-weight: 400;
 line-height: 2;
 letter-spacing: 0px;
 font-family: var(--font);
}
.pad-top-bottom {
 padding: 2vh 0;
}


/* .wrp-body-exchange-buy-detail {
 display: flex;
 padding: 3%;
} */


.wrp-exchange-buy-detail {
 display: flex;
 flex-direction: column;
 height: 100%;
 min-height: 100vh;
}

.pad-2 {
 padding: 2em;
}

.flex-vh-100 {
 display: grid;
 grid-template-columns: minmax(150px, 45%) 1fr;

 height: 100%;
 min-height: 100vh;
}

.flex-vh-100-sm {
 display: grid;
 height: 100%;
 min-height: 100vh;
}

.wrp-side-info {
 display: grid;
 place-items: center;
 height: 100%;
 background-color: #f5f3f4;
}

.flex-img-side-info {
 display: grid;
 place-items: center;
}
.flex-img-side-info img {
 height: 19vw;
 width: 22vw;
 object-fit: cover;
 margin-bottom: 2vh;
}

.grid-center {
 display: grid;
 place-items: center;
 height: 100%;
}

.title-pin-hm-main-info {
 display: grid;
 place-items: center;
 width: 100%;
 max-width: 30vw;
}
.title-pin-hm-main-info h2 {
 display: flex;
 font-size: 1.4em;
 font-weight: 300;
 line-height: 1;
 font-family: var(--font);
 letter-spacing: 0px;
 margin-bottom: 1ch;
 color: #495057;
}
.title-pin-hm-main-info p {
 display: flex;
 font-size: 0.855em;
 font-weight: 300;
 line-height: 1.4;
 font-family: var(--font);
 letter-spacing: 0px;
 text-align: center;
 color: #aaaaaa;
}

/* Small */
.title-pin-hm-main-info-sm {
 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: center;
 width: 100%;
}
.title-pin-hm-main-info-sm h2 {
 display: flex;
 font-size: 1.3em;
 font-weight: 100;
 line-height: 1;
 font-family: var(--font);
 letter-spacing: 0px;
 margin-bottom: 2ch;
 color: #495057;
}
.title-pin-hm-main-info-sm p {
 display: flex;
 font-size: 0.8em;
 font-weight: 100;
 line-height: 1.2;
 font-family: var(--font);
 letter-spacing: 0px;
 text-align: center;
 color: #aaaaaa;
}

/* .wrp-form-input-pin-nows {
 display: flex;
 justify-content: center;
 align-items: center;
 width: 100%;
 max-width: 25em;
}
.wrp-form-input-pin-nows form {
 display: flex;
 flex-direction: column;
 justify-content: center;

 place-items: center;
 padding: 1em;

 width: 100%;
 max-width: 100vw;
}
.wrp-form-input-pin-nows input {
 display: flex;
 font-size: 1.5em;
 font-weight: 500;
 font-family: var(--font);
 line-height: 1;

 letter-spacing: 8px;
 text-align: center;
 padding-left: 1.9em;
 border: none;
}
.wrp-form-input-pin-nows form button {
 display: flex;
 margin-top: 2vh;
 max-width: 100vw;
 font-size: 1.115em;
 border-radius: 0.6em;
 padding: 0.5em;
 font-family: var(--font);
}
.wrp-form-input-pin-nows form p {
 display: flex;
 font-size: 1.1em;
 font-weight: 300;
 line-height: 1.2;
 text-align: center;
 letter-spacing: 0px;
 font-family: var(--font);
 margin: 3vh 0;
}
.title-pop-pret {
 display: flex;
 font-size: 1.155em;
 font-weight: 300;
 font-family: var(--font);
 line-height: 1;
 letter-spacing: 0.3px;
 color: #2dc653;
}
.paragraph-pop-pret {
 display: flex;
 font-size: 0.9em;
 font-weight: 300;
 font-family: var(--font);
 line-height: 1;
 letter-spacing: 0.3px;
 color: #595959;

 margin: 4vh 0;
}
.flex-active-ask-pret {
 display: grid;
 place-items: start;
 width: 100%;
 gap: 1em;
}
.flex-input-pret-modulo {
 display: grid;
 place-items: start;
}
.flex-input-pret-modulo label {
 display: flex;
 font-size: 1.1em;
 font-weight: 300;
 line-height: 1.2;
 text-align: center;
 letter-spacing: 0px;
 font-family: var(--font);
 margin: 1vh 0;
 margin-top: 3vh;
 color: #2b303a;
}
.flex-input-pret-modulo input {
 display: flex;
 font-size: 1.3em;
 font-weight: 100;
 font-family: var(--font);
 line-height: 1;
 letter-spacing: 0px;
 border: none;
 color: #2b303a;
}

Small Pin
.wrp-form-input-pin-nows-sm {
 display: flex;
 justify-content: center;
 align-items: center;
 width: 100%;
}

.wrp-form-input-pin-nows-sm input {
 display: flex;
 font-size: 1.5em;
 font-weight: 500;
 font-family: var(--font);
 line-height: 1;

 letter-spacing: 8px;
 text-align: center;
 padding-left: 1.9em;
 border: none;
}
.wrp-form-input-pin-nows-sm form button {
 display: flex;
 margin-top: 2vh;
 max-width: 100vw;
 font-size: 1.115em;
 border-radius: 0.6em;
 padding: 0.5em;
 font-family: var(--font);
}
.wrp-form-input-pin-nows-sm form p {
 display: flex;
 font-size: 1.1em;
 font-weight: 300;
 line-height: 1.2;
 text-align: center;
 letter-spacing: 0px;
 font-family: var(--font);
 margin: 3vh 0;
}
.title-pop-pret {
 display: flex;
 font-size: 1.155em;
 font-weight: 300;
 font-family: var(--font);
 line-height: 1;
 letter-spacing: 0.3px;
 color: #2dc653;
}
.paragraph-pop-pret {
 display: flex;
 font-size: 0.9em;
 font-weight: 300;
 font-family: var(--font);
 line-height: 1;
 letter-spacing: 0.3px;
 color: #595959;

 margin: 4vh 0;
}
.flex-active-ask-pret {
 display: grid;
 place-items: start;
 width: 100%;
 gap: 1em;
}
.flex-input-pret-modulo {
 display: grid;
 place-items: start;
}
.flex-input-pret-modulo label {
 display: flex;
 font-size: 1.1em;
 font-weight: 300;
 line-height: 1.2;
 text-align: center;
 letter-spacing: 0px;
 font-family: var(--font);
 margin: 1vh 0;
 margin-top: 3vh;
 color: #2b303a;
}
.flex-input-pret-modulo input {
 display: flex;
 font-size: 1.3em;
 font-weight: 100;
 font-family: var(--font);
 line-height: 1;
 letter-spacing: 0px;
 border: none;
 color: #2b303a;
} */

.grid-head-dash-pret {
 grid-column: 1 / 4;
}
.grid-head-dash-pret-sm {
 grid-column: 1 / 2;
}

.flex-header-6ch {
 display: flex;
 width: 100%;
 place-items: center;
 height: 8ch;
 padding: 0 3%;
}

.bg-platinum {
 display: flex;
 background-color: #005db3;
}



.pointer {

 cursor: pointer;

}
.flex-20vh-head-drawe {
 display: flex;
 height: 30vh;
 width: 100%;
 background-color: #005db3;
 color: #fff;
}
.flex-20vh-head-drawe-sm {
 display: flex;
 height: 20vh;
 width: 100%;
 background-color: #005db3;
 color: #fff;
}

.flex-grid-left {
 display: grid;
 place-items: start;
}
.pad-2em {
 padding: 1em;
}

.pad-1em {
 padding: 3%;
}

.wrp-title-head-drawe-asked {
 display: flex;
}

.wrp-title-head-drawe-asked h2 {
 display: flex;
 font-size: 1.2em;
 font-weight: 300;
 line-height: 1;
 letter-spacing: 0px;
 font-family: var(--font);
}

.flex-asked-drawe {
 display: flex;
}
.flex-asked-drawe p {
 display: flex;
 font-size: 1.1em;
 font-weight: 300;
 line-height: 1;
 letter-spacing: 0px;
 white-space: nowrap;
 font-family: var(--font);
}

.flex-accordion-details {
 display: grid;
 place-items: start;
}
.flex-accordion-details h1 {
 display: flex;
 font-size: 1em;
 font-weight: 100;
 line-height: 1;
 letter-spacing: 0px;
 font-family: var(--font);
 margin: 4vh 0;
 color: #00b0ff;
}
.flex-accordion-details p {
 display: flex;
 font-size: 0.8em;
 font-weight: 100;
 line-height: 1;
 white-space: nowrap;

 letter-spacing: 0px;
 font-family: var(--font);
 color: #6c757d;
}
.flex-gap-3 {
 grid-gap: 3em;
 gap: 3em;
}
.left-margin-12vw {
 margin-left: 350px;
}

.left-margin-small-12vw {
 margin-left: 70px;
}

.margin-bottom-2vh {
 margin-bottom: 2vh;
}

.pop-up-small {
 display: flex;
 font-size: 0.5em;
 font-weight: 300;
 line-height: 1;
 letter-spacing: 0px;
 font-family: var(--font);
}

/* Small */
.flex-accordion-details-sm {
 display: grid;
 place-items: start;
}
.flex-accordion-details-sm h1 {
 display: flex;
 font-size: 1em;
 font-weight: 100;
 line-height: 1;
 letter-spacing: 0px;
 font-family: var(--font);
 margin: 4vh 0;
 color: #00b0ff;
}
.flex-accordion-details-sm p {
 display: flex;
 font-size: 0.7em;
 font-weight: 400;
 line-height: 1;
 white-space: nowrap;

 letter-spacing: 0px;
 font-family: var(--font);
 color: #6c757d;
}

.top-8vh {
 margin-top: 2vh;
}
button {
 border: none;
}
.mr-bt-5vh {
 margin-bottom: 5vh;
}

.pd-b-5vh {
 display: flex;
 padding-bottom: 3vh;
}

.flex-width-10em {
 width: 5em;
}

.flex-budget-pret-grid {
 display: flex;
}
.flex-budget-pret-grid-sm {
 display: grid;
 place-items: start;
}

.flex-balance-budget-pret {
 display: flex;
 color: #fdfdfd;
}
.flex-balance-budget-pret p {
 display: flex;
 font-size: 1em;
 font-weight: 300;
 line-height: 0.3;
 letter-spacing: 0px;
 white-space: nowrap;
 font-family: var(--font);
}

/* Small */
.flex-balance-budget-pret-sm {
 display: flex;
 color: #fdfdfd;
}
.flex-balance-budget-pret-sm p {
 display: flex;
 font-size: 0.9em;
 font-weight: 300;
 line-height: 0;
 letter-spacing: 0px;
 white-space: nowrap;
 font-family: var(--font);
}

.flex-progress-pret {
 display: flex;
 flex-direction: column;
 padding: 1em;
}
.rose-quartz {
 display: flex;
 background-color: #faf8f5;
}

.padding-5 {
 padding: 5%;
}

.flex-global-pret-progres {
 display: grid;
 margin-top: 3vh;
}
.flex-global-pret-progres h3 {
 display: flex;
 font-size: 1.11em;
 font-weight: 300;
 line-height: 1;
 letter-spacing: 0px;
 font-family: var(--font);
 color: #005db3;
}

.wrp-title-head-progress-global {
 display: flex;
}

.wrp-title-head-progress-global h2 {
 display: flex;
 font-size: 1.255em;

 font-weight: 300;
 line-height: 1;
 letter-spacing: 0px;
 font-family: var(--font);
 color: #005db3;
}

.box-pop-up {
 display: flex;
 font-size: 0.8em;
 font-weight: 300;
 line-height: 0.8;
 letter-spacing: 0px;
 font-family: var(--font);
}

/* marign */

.flex-mrtop-5 {
 margin-top: 5vh;
}

.wrp-repay-pret {
 display: flex;
 flex-direction: column;
 background-color: #faf8f5;
}

.wrp-title-repay-pret {
 display: flex;
 flex-direction: column;
 padding: 3vh 3%;
}
.wrp-title-repay-pret h3 {
 display: flex;
 font-size: 0.9em;
 font-weight: 300;
 line-height: 1;
 letter-spacing: 0px;
 font-family: var(--font);
 text-transform: uppercase;
 color: #555555;
}

.wrp-head-repay-pret {
 display: flex;
 /* height: 8vh; */
}

.pad-3 {
 padding: 3%;
}

.pading-2 {
 padding: 1em;
}

.between {
 display: flex;
 justify-content: space-between;
 width: 100%;
}

.crimson {
 /* color: crimson; */
 color: #005db3;
}

.grey-595959 {
 color: #595959;
}

.fat-400 {
 font-weight: 500;
}
.fat-100 {
 font-weight: 100;
}

.wrp-main-prix-pret {
 display: flex;
}

.wrp-main-prix-pret p {
 display: flex;
 font-size: 1.8em;
 font-weight: 100;
 line-height: 1;
 letter-spacing: 0px;
 font-family: var(--font);
}

.wrp-main-prix-pret-sm {
 display: flex;
 font-size: 1.8em;
 font-weight: 100;
 line-height: 1;
 letter-spacing: 0px;
 font-family: var(--font);
}

.flex-avatar-profil-info-pret {
 display: grid;
 place-items: center;
}

.flex-avatar-profil-info-pret p {
 display: flex;
 font-size: 1.1em;
 margin-top: 2vh;
 font-weight: 300;
 line-height: 1;
 letter-spacing: 0.5px;
 font-family: var(--font);
 color: #7f7f7f;
}

.wrp-small-info-pret {
 padding: 0 5%;
}

.wrp-small-info-pret {
 padding: 0 5%;
 height: 15vh;
}

.flex-consumption-info-dash-pret {
 display: grid;
 margin-top: 5vh;
}

.title-consumption-info {
 display: flex;
 flex-direction: column;
 margin-bottom: 3vh;
}
.title-consumption-info h3 {
 display: flex;
 font-size: 1.2em;
 font-weight: 300;
 line-height: 1;
 letter-spacing: 0px;
 font-family: var(--font);
}

.flex-info-msg-pret {
 display: flex;
}
.flex-info-msg-pret p {
 display: flex;
 font-size: 0.8em;
 font-weight: 300;
 line-height: 1;
 letter-spacing: 0.5px;
 font-family: var(--font);
 color: #6c757d;
}

.wrp-timestamp-pret {
 display: flex;
 flex-direction: column;
 margin-top: 6vh;
}

/* .wrp-consumption-time-pret {
 display: flex;
 flex-direction: column;
 width: 100%;
}
.wrp-consumption-time-pret h3 {
 display: flex;
 font-size: 1.1em;
 font-weight: 100;
 font-family: var(--font);
 line-height: 4;
 letter-spacing: 0px;
}
.wrp-consumption-time-pret p {
 display: flex;
 font-size: 0.7em;
 font-weight: 300;
 line-height: 1.2;
 letter-spacing: 0px;
 font-family: var(--font);
 color: #6c757d;
} */

.detail-info-pret {
 display: grid;
 margin-top: 10vh;
}
.detail-info-pret p {
 display: flex;
 font-size: 0.8em;
 font-weight: 300;
 line-height: 1;
 letter-spacing: 0px;
 font-family: var(--font);
 color: #6c757d;
}

.flex-vh-100 {
 display: grid;
 grid-template-columns: minmax(150px, 45%) 1fr;

 height: 100%;
 min-height: 100vh;
}

.flex-vh-100-sm {
 display: grid;
 height: 100%;
 min-height: 100vh;
}

.wrp-side-info {
 display: grid;
 place-items: center;
 height: 100%;
 background-color: #f5f3f4;
}

.flex-img-side-info {
 display: grid;
 place-items: center;
}
.flex-img-side-info img {
 height: 19vw;
 width: 22vw;
 object-fit: cover;
 margin-bottom: 2vh;
}

.grid-center {
 display: grid;
 place-items: center;
 height: 100%;
}

/* .title-pin-hm-main-info {
 display: grid;
 place-items: center;
 width: 100%;
 max-width: 35vw;
}
.title-pin-hm-main-info h2 {
 display: flex;
 font-size: 1.2em;
 font-weight: 400;
 line-height: 1;
 font-family: var(--font);
 letter-spacing: 0px;
 margin-bottom: 1ch;
}
.title-pin-hm-main-info p {
 display: flex;
 font-size: 1em;
 font-weight: 400;
 line-height: 1.2;
 font-family: var(--font);
 letter-spacing: 0px;
 text-align: center;
 color: #aaaaaa;
}

Small
.title-pin-hm-main-info-sm {
 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: center;
 width: 100%;
 max-width: 85vw;
}
.title-pin-hm-main-info-sm h2 {
 display: flex;
 font-size: 1.2em;
 font-weight: 400;
 line-height: 1;
 font-family: var(--font);
 letter-spacing: 0px;
 margin-bottom: 1ch;
}
.title-pin-hm-main-info-sm p {
 display: flex;
 font-size: 1em;
 font-weight: 400;
 line-height: 1.2;
 font-family: var(--font);
 letter-spacing: 0px;
 text-align: center;
 color: #aaaaaa;
} */

/* .wrp-form-input-pin-nows {
 display: flex;
 justify-content: center;
 align-items: center;
 width: 100%;
 max-width: 25em;
}
.wrp-form-input-pin-nows form {
 display: flex;
 flex-direction: column;
 justify-content: center;

 place-items: center;
 padding: 1em;

 width: 100%;
 max-width: 100vw;
}
.wrp-form-input-pin-nows input {
 display: flex;
 font-size: 1.5em;
 font-weight: 500;
 font-family: var(--font);
 line-height: 1;

 letter-spacing: 8px;
 text-align: center;
 padding-left: 1.9em;
 border: none;
}
.wrp-form-input-pin-nows form button {
 display: flex;
 margin-top: 2vh;
 max-width: 100vw;
 font-size: 1.115em;
 border-radius: 0.6em;
 padding: 0.5em;
 font-family: var(--font);
}
.wrp-form-input-pin-nows form p {
 display: flex;
 font-size: 1.1em;
 font-weight: 300;
 line-height: 1.2;
 text-align: center;
 letter-spacing: 0px;
 font-family: var(--font);
 margin: 3vh 0;
}
.title-pop-pret {
 display: flex;
 font-size: 1.155em;
 font-weight: 300;
 font-family: var(--font);
 line-height: 1;
 letter-spacing: 0.3px;
 color: #2dc653;
}
.paragraph-pop-pret {
 display: flex;
 font-size: 0.9em;
 font-weight: 300;
 font-family: var(--font);
 line-height: 1;
 letter-spacing: 0.3px;
 color: #595959;

 margin: 4vh 0;
}
.flex-active-ask-pret {
 display: grid;
 place-items: start;
 width: 100%;
 gap: 1em;
}
.flex-input-pret-modulo {
 display: grid;
 place-items: start;
}
.flex-input-pret-modulo label {
 display: flex;
 font-size: 1.1em;
 font-weight: 300;
 line-height: 1.2;
 text-align: center;
 letter-spacing: 0px;
 font-family: var(--font);
 margin: 1vh 0;
 margin-top: 3vh;
 color: #2b303a;
}
.flex-input-pret-modulo input {
 display: flex;
 font-size: 1.3em;
 font-weight: 100;
 font-family: var(--font);
 line-height: 1;
 letter-spacing: 0px;
 border: none;
 color: #2b303a;
}

Small Pin
.wrp-form-input-pin-nows-sm {
 display: flex;
 justify-content: center;
 align-items: center;
 width: 100%;
}

.wrp-form-input-pin-nows-sm input {
 display: flex;
 font-size: 1.5em;
 font-weight: 500;
 font-family: var(--font);
 line-height: 1;

 letter-spacing: 8px;
 text-align: center;
 padding-left: 1.9em;
 border: none;
}
.wrp-form-input-pin-nows-sm form button {
 display: flex;
 margin-top: 2vh;
 max-width: 100vw;
 font-size: 1.115em;
 border-radius: 0.6em;
 padding: 0.5em;
 font-family: var(--font);
}
.wrp-form-input-pin-nows-sm form p {
 display: flex;
 font-size: 1.1em;
 font-weight: 300;
 line-height: 1.2;
 text-align: center;
 letter-spacing: 0px;
 font-family: var(--font);
 margin: 3vh 0;
}
.title-pop-pret {
 display: flex;
 font-size: 1.155em;
 font-weight: 300;
 font-family: var(--font);
 line-height: 1;
 letter-spacing: 0.3px;
 color: #2dc653;
}
.paragraph-pop-pret {
 display: flex;
 font-size: 0.9em;
 font-weight: 300;
 font-family: var(--font);
 line-height: 1;
 letter-spacing: 0.3px;
 color: #595959;

 margin: 4vh 0;
}
.flex-active-ask-pret {
 display: grid;
 place-items: start;
 width: 100%;
 gap: 1em;
}
.flex-input-pret-modulo {
 display: grid;
 place-items: start;
}
.flex-input-pret-modulo label {
 display: flex;
 font-size: 1.1em;
 font-weight: 300;
 line-height: 1.2;
 text-align: center;
 letter-spacing: 0px;
 font-family: var(--font);
 margin: 1vh 0;
 margin-top: 3vh;
 color: #2b303a;
}
.flex-input-pret-modulo input {
 display: flex;
 font-size: 1.3em;
 font-weight: 100;
 font-family: var(--font);
 line-height: 1;
 letter-spacing: 0px;
 border: none;
 color: #2b303a;
} */

.grid-heigth-dash-litige {
 display: grid;
 height: 100%;
 min-height: 100vh;
 grid-template-rows: auto 1fr auto;
}

.flex-header-6ch {
 display: flex;
 width: 100%;
 place-items: center;
 height: 6ch;
 padding: 0 3%;
}
.bg-color-hicolblue {
 background-color: #0b2545;
}

.grid-center-box-drawer {
 display: grid;
 place-items: center;
}

.grid-flex-center {
 display: flex;
 place-items: center;
}

.flex-flag-litge-box {
 display: flex;
}

.flex-flag-litge-box p {
 display: flex;
 margin-top: 2vh;
 font-size: 1em;
 font-weight: 300;
 line-height: 0;
 letter-spacing: 0px;
 font-family: var(--font);
}

.grid-form-litige {
 display: grid;
 height: 100vh;
 grid-template-rows: auto 1fr auto;
}

.flex-head-form-acces-litige {
 display: grid;
 height: 30vh;
 place-items: center;

 background-color: #4f6d7a;
}

.title-head-acces-litige {
 display: flex;
}
.title-head-acces-litige h3 {
 display: flex;
 font-size: 1em;
 font-weight: 400;
 text-transform: uppercase;
 line-height: 1;
 letter-spacing: 0px;
 font-family: var(--font);
 color: #000;
}

.flex-litige-personal {
 display: block;
 margin-top: 20vh;
 place-items: center;
}

.flex-litige-personal {
 display: block;
 margin-top: 20vh;
 place-items: center;
 padding: 0.5em;
}

.wrp-input-phone-personal {
 display: flex;
 flex-direction: column;
 width: 100%;
}
.wrp-input-phone-personal form {
 display: flex;
 flex-direction: column;
 grid-gap: 1em;
 gap: 1em;
 justify-content: center;
 align-items: center;
}
.wrp-input-phone-personal form input {
 display: flex;
 flex-direction: column;
 font-size: 1.3em;
 font-weight: 400;
 line-height: 1;
 padding: 1.1vh 0;
 letter-spacing: 0px;
 font-family: var(--font);
 border: none;
}

.flex-litige-personal {
 display: block;
 margin-top: 20vh;
 place-items: center;
}


.grid-head-dash-litige {
 grid-column: 1 / 3;
}
.grid-head-dash-litige-sm {
 grid-column: 1 / 2;
}
.flex-header-6ch {
 display: flex;
 width: 100%;
 place-items: center;
 height: 6ch;
 padding: 0 3%;
}

.bg-color-hicolblue {
 background-color: #0b2545;
}

.wrp-logo {
 display: flex;
 cursor: pointer;
}
.wrp-logo img {
 height: 3em;
 width: 3em;
 object-fit: cover;
}

.wrp-prix-valid-litige {
 display: flex;
 place-items: center;
 margin-top: 3vh;
}
.wrp-prix-valid-litige p {
 display: flex;
 font-size: 1em;
 font-weight: 300;
 line-height: 1;
 letter-spacing: 0px;
 font-family: var(--font);
}

.cashbeck {
 display: flex;
 font-size: 1em;
 font-weight: 300;
 line-height: 1;
 letter-spacing: 0.3px;
 font-family: var(--font);
}
.cashbeck-text {
 display: flex;
 font-size: 0.9em;
 font-weight: 300;
 line-height: 1.3;
 letter-spacing: 0px;
 font-family: var(--font);
}

.litige-title-transf-two {
 display: flex;
}

.litige-title-transf-two h1 {
 display: flex;
 font-size: 1em;
 font-weight: 300;
 line-height: 1;
 letter-spacing: 0px;
 font-family: var(--font);
}

.wrp-body-transfert-valid {
 display: grid;
 margin-top: 5vh;
}

.wrp-profil-litige-two {
 display: flex;
}

.flex-profil-left-litige-transf {
 display: grid;
 place-items: center;
}
.flex-profil-left-litige-transf h4 {
 display: flex;
 font-size: 0.9em;
 font-weight: 300;
 margin-top: 1vh;
 line-height: 1;
 letter-spacing: 0px;
 font-family: var(--font);
 text-transform: lowercase;
}
.flex-profil-left-litige-transf h5 {
 display: flex;
 font-size: 0.9em;
 font-weight: 400;
 margin-top: 1vh;
 line-height: 1;
 letter-spacing: 0px;
 font-family: var(--font);
}

.wrp-profil-litige-two {
 display: flex;
}

.flex-profil-left-litige-transf {
 display: grid;
 place-items: center;
}
.flex-profil-left-litige-transf h4 {
 display: flex;
 font-size: 0.9em;
 font-weight: 300;
 margin-top: 1vh;
 line-height: 1;
 letter-spacing: 0px;
 font-family: var(--font);
 text-transform: lowercase;
}
.flex-profil-left-litige-transf h5 {
 display: flex;
 font-size: 0.9em;
 font-weight: 300;
 margin-top: 1vh;
 line-height: 1;
 letter-spacing: 0px;
 font-family: var(--font);
}

.wrp-transfert-litige-valid {
 display: grid;
 padding: 5vh 1em;
}

.flex-litige-left {
 display: flex;
 flex-direction: column;
 padding: 1em;
}

.flex-heath-two-litige {
 display: flex;
}

.wrp-profil-litige-two {
 display: flex;
}

.flex-profil-left-litige {
 display: grid;
 place-items: center;
}
.flex-profil-left-litige h4 {
 display: flex;
 font-size: 1.055em;
 font-weight: 300;
 margin-top: 1.5vh;
 line-height: 1;
 letter-spacing: 0px;
 font-family: var(--font);
 text-transform: lowercase;
}

.border-succes {
 display: flex;
 border-radius: 100%;
 padding: 0.1em;
 border: 0.144em solid #493;
}
.border-error {
 display: flex;
 border-radius: 100%;
 padding: 0.1em;
 border: 0.144em solid crimson;
}

.flex-prix-balance-litige {
 display: flex;
}
.flex-prix-balance-litige p {
 display: flex;
 font-size: 1em;
 font-weight: 300;
 line-height: 0.5;
 letter-spacing: 0px;
 font-family: var(--font);
 color: #3e3e3e;
}

.drawer-height-1eft {
 display: flex;
 margin: 5vh 0;
}

/* .wrp-profil-litige-two {
 display: flex;
} */

/* .flex-profil-left-litige {
 display: grid;
 place-items: center;
}
.flex-profil-left-litige h4 {
 display: flex;
 font-size: 1.055em;
 font-weight: 400;
 margin-top: 1.5vh;
 line-height: 1;
 letter-spacing: 0px;
 font-family: var(--font);
 text-transform: lowercase;
} */

/* .border-succes {
 display: flex;
 border-radius: 100%;
 padding: 0.1em;
 border: 3px solid #493;
}
.border-error {
 display: flex;
 border-radius: 100%;
 padding: 0.1em;
 border: 3px solid crimson;
} */

/* .flex-prix-balance-litige {
 display: flex;
}

.flex-prix-balance-litige p {
 display: flex;
 font-size: 1.2em;
 font-weight: 400;
 line-height: 1;
 letter-spacing: 0px;
 font-family: var(--font);
 color: #3e3e3e;
} */

.wrp-body-exchange-buy-detail {
 display: flex;
 padding: 3%;
}

/* .list-exchange-buy-detail {
 display: flex;
 flex-direction: column;
 width: 100%;
}
.list-top-exchange-buy-detail {
 display: flex;
 flex-direction: column;
 border-bottom: 1px solid #e7ecef;

 padding-top: 7vh;
}
.list-sub-exchange-buy-detail {
 display: flex;
 justify-content: space-between;
 place-items: center;
 width: 100%;
}
.list-sub-exchange-buy-detail p {
 display: flex;
 font-size: 0.7em;
 font-weight: 400;
 line-height: 0.5;
 white-space: nowrap;
 letter-spacing: 0px;
 font-family: var(--font);
}

.list-top-exchange-info-detail {
 display: flex;
 flex-direction: column;
 border-bottom: 1px solid #e7ecef;

 padding-top: 7vh;
}
.list-sub-exchange-info-detail {
 display: flex;
 justify-content: space-between;
 place-items: center;
 width: 100%;
}
.list-sub-exchange-info-detail p {
 display: flex;
 font-size: 0.7em;
 font-weight: 400;

 padding-top: 2vh;

 line-height: 2;
 white-space: nowrap;
 letter-spacing: 0px;
 font-family: var(--font);
}

.list-exchange-buy-detail-view {
 display: flex;
 flex-direction: column;
 width: 100%;
}
.list-top-exchange-buy-detail-view {
 display: flex;
 flex-direction: column;
 border-radius: 1em;
 padding: 0.7em;
 margin: 1vh 0;
}
.list-sub-exchange-buy-detail-view {
 display: flex;
 justify-content: space-between;
 place-items: center;
 width: 100%;
}
.list-sub-exchange-buy-detail-view p {
 display: flex;
 font-size: 0.7em;
 font-weight: 400;
 line-height: 0.5;
 white-space: nowrap;
 letter-spacing: 0px;
 font-family: var(--font);
} */

.title-head-transfer-self {
 display: flex;
 margin: 6vh 0;
}
.title-head-transfer-self h3 {
 display: flex;
 font-size: 1em;
 font-weight: 400;
 line-height: 0;
 letter-spacing: 0px;
 font-family: var(--font);
}

.title-head-transfer-self-sm {
 display: flex;
 margin: 6vh 0;
}
.title-head-transfer-self-sm h3 {
 display: flex;
 font-size: 1.2em;
 font-weight: 300;
 white-space: nowrap;
 line-height: 1;
 letter-spacing: 0px;
 font-family: var(--font);
}


.flex-grid-center-creditaire {
 display: flex;
 justify-content: space-between;
 place-items: center;
}

.wrp-exchange-buy-detail {
 display: flex;
 flex-direction: column;
 height: 100%;
 min-height: 100vh;
}

.wrp-body-exchange-buy-detail {
 display: flex;
 padding: 3%;
}

/* .list-exchange-buy-detail {
 display: flex;
 flex-direction: column;
 width: 100%;
}
.list-top-exchange-buy-detail {
 display: flex;
 flex-direction: column;
 border-bottom: 1px solid #e7ecef;

 padding-top: 7vh;
}
.list-sub-exchange-buy-detail {
 display: flex;
 justify-content: space-between;
 place-items: center;
 width: 100%;
}
.list-sub-exchange-buy-detail p {
 display: flex;
 font-size: 0.7em;
 font-weight: 400;
 line-height: 0.5;
 white-space: nowrap;
 letter-spacing: 0px;
 font-family: var(--font);
}

.list-top-exchange-info-detail {
 display: flex;
 flex-direction: column;
 border-bottom: 1px solid #e7ecef;

 padding-top: 7vh;
}
.list-sub-exchange-info-detail {
 display: flex;
 justify-content: space-between;
 place-items: center;
 width: 100%;
}
.list-sub-exchange-info-detail p {
 display: flex;
 font-size: 0.7em;
 font-weight: 400;

 padding-top: 2vh;

 line-height: 2;
 white-space: nowrap;
 letter-spacing: 0px;
 font-family: var(--font);
}

.list-exchange-buy-detail-view {
 display: flex;
 flex-direction: column;
 width: 100%;
}
.list-top-exchange-buy-detail-view {
 display: flex;
 flex-direction: column;
 border-radius: 1em;
 padding: 0.7em;
 margin: 1vh 0;
}
.list-sub-exchange-buy-detail-view {
 display: flex;
 justify-content: space-between;
 place-items: center;
 width: 100%;
}
.list-sub-exchange-buy-detail-view p {
 display: flex;
 font-size: 0.7em;
 font-weight: 400;
 line-height: 0.5;
 white-space: nowrap;
 letter-spacing: 0px;
 font-family: var(--font);
} */

.title-head-transfer-self {
 display: flex;
 margin: 6vh 0;
}
.title-head-transfer-self h3 {
 display: flex;
 font-size: 1em;
 font-weight: 400;
 line-height: 0;
 letter-spacing: 0px;
 font-family: var(--font);
}

.title-head-transfer-self-sm {
 display: flex;
 margin: 6vh 0;
}
.title-head-transfer-self-sm h3 {
 display: flex;
 font-size: 1.2em;
 font-weight: 300;
 white-space: nowrap;
 line-height: 1;
 letter-spacing: 0px;
 font-family: var(--font);
}


.flex-grid-center-creditaire {
 display: flex;
 justify-content: space-between;
 place-items: center;
}

.wrp-exchange-buy-detail {
 display: flex;
 flex-direction: column;
 height: 100%;
 min-height: 100vh;
}

.flex-litige-left {
 display: flex;
 flex-direction: column;
}

.flex-heath-two-litige {
 display: flex;
}

/* .wrp-profil-litige-two {
 display: flex;
} */

/* .flex-profil-left-litige {
 display: grid;
 place-items: center;
}
.flex-profil-left-litige h4 {
 display: flex;
 font-size: 1.055em;
 font-weight: 400;
 margin-top: 1.5vh;
 line-height: 1;
 letter-spacing: 0px;
 font-family: var(--font);
 text-transform: lowercase;
} */

/* .border-succes {
 display: flex;
 border-radius: 100%;
 padding: 0.1em;
 border: 3px solid #493;
}
.border-error {
 display: flex;
 border-radius: 100%;
 padding: 0.1em;
 border: 3px solid crimson;
} */


.grid-head-dash-litige {
 grid-column: 1 / 3;
}
.grid-head-dash-litige-sm {
 grid-column: 1 / 2;
}
.flex-header-6ch {
 display: flex;
 width: 100%;
 place-items: center;
 height: 6ch;
 padding: 0 3%;
}

.bg-color-hicolblue {
 background-color: #0b2545;
}

.wrp-prix-valid-litige {
 display: flex;
 place-items: center;
 margin-top: 3vh;
}
.wrp-prix-valid-litige p {
 display: flex;
 font-size: 1em;
 font-weight: 300;
 line-height: 1;
 letter-spacing: 0px;
 font-family: var(--font);
}

/* .cashbeck {
 display: flex;
 font-size: 1em;
 font-weight: 300;
 line-height: 1;
 letter-spacing: 0.3px;
 font-family: var(--font);
}
.cashbeck-text {
 display: flex;
 font-size: 0.9em;
 font-weight: 300;
 line-height: 1.3;
 letter-spacing: 0px;
 font-family: var(--font);
} */

.litige-title-transf-two {
 display: flex;
}

.litige-title-transf-two h1 {
 display: flex;
 font-size: 1.155em;
 font-weight: 300;
 line-height: 1;
 letter-spacing: 0px;
 font-family: var(--font);
}

.wrp-body-transfert-valid {
 display: flex;
 flex-direction: column;
 margin-top: 15vh;
}

.flex-transfert-buy-balance {
 display: grid;
 grid-template-rows: auto 1fr auto;
 height: 100vh;
}

.flex-prix-balance-litige-cancel {
 display: flex;
}
.flex-prix-balance-litige-cancel p {
 display: flex;
 font-size: 1.5em;
 font-weight: 100;
 line-height: 0.5;
 letter-spacing: 0px;
 font-family: var(--font);
 color: #679436;
}
.flex-prix-balance-litige-cancel span {
 display: flex;
 font-size: 0.8em;
 font-weight: 100;
 line-height: 0.5;
 letter-spacing: 0px;
 font-family: var(--font);
 margin-top: 1vh;
 color: #d90368;
}

.wrp-list-buy-transfert-cancel {
 display: grid;
 margin-top: 5vh;
}

.wrp-list-buy-transfert-cancel ul {
 padding: 0;
 margin: 0;
 list-style: none;
}

.wrp-list-buy-transfert-cancel ul li {
 display: flex;
 justify-content: space-between;
 place-items: center;
 width: 100%;
}

.wrp-list-buy-transfert-cancel ul li p {
 display: flex;
 font-size: 0.9em;
 font-weight: 300;
 line-height: 1;
 font-family: var(--font);
 letter-spacing: 0px;
}

.wrp-transfert-litige-valid {
 display: grid;
 padding: 5vh 1em;
}

.mrtop-2 {
 padding-top: 2vh;
}

.flex-litige-left {
 display: flex;
 flex-direction: column;
 padding: 1em;
}

.flex-heath-two-litige {
 display: flex;
}

.wrp-profil-litige-two {
 display: flex;
}

.flex-profil-left-litige {
 display: grid;
 place-items: center;
}
.flex-profil-left-litige h4 {
 display: flex;
 font-size: 1.055em;
 font-weight: 300;
 margin-top: 1.5vh;
 line-height: 1;
 letter-spacing: 0px;
 font-family: var(--font);
 text-transform: lowercase;
}

.border-succes {
 display: flex;
 border-radius: 100%;
 padding: 0.1em;
 border: 0.144em solid #493;
}
.border-error {
 display: flex;
 border-radius: 100%;
 padding: 0.1em;
 border: 0.144em solid crimson;
}

.flex-prix-balance-litige {
 display: flex;
}
.flex-prix-balance-litige p {
 display: flex;
 font-size: 1em;
 font-weight: 300;
 line-height: 0.5;
 letter-spacing: 0px;
 font-family: var(--font);
 color: #3e3e3e;
}

.wrp-body-exchange-buy-detail {
 display: flex;
 padding: 3%;
}

/* .list-exchange-buy-detail {
 display: flex;
 flex-direction: column;
 width: 100%;
}
.list-top-exchange-buy-detail {
 display: flex;
 flex-direction: column;
 border-bottom: 1px solid #e7ecef;

 padding-top: 7vh;
}
.list-sub-exchange-buy-detail {
 display: flex;
 justify-content: space-between;
 place-items: center;
 width: 100%;
}
.list-sub-exchange-buy-detail p {
 display: flex;
 font-size: 1em;
 font-weight: 400;
 line-height: 0.5;
 white-space: nowrap;
 letter-spacing: 0px;
 font-family: var(--font);
}

.list-top-exchange-info-detail {
 display: flex;
 flex-direction: column;
 border-bottom: 1px solid #e7ecef;

 padding-top: 7vh;
}
.list-sub-exchange-info-detail {
 display: flex;
 justify-content: space-between;
 place-items: center;
 width: 100%;
}
.list-sub-exchange-info-detail p {
 display: flex;
 font-size: 1em;
 font-weight: 400;

 padding-top: 2vh;

 line-height: 2;
 white-space: nowrap;
 letter-spacing: 0px;
 font-family: var(--font);
}

.list-exchange-buy-detail-view {
 display: flex;
 flex-direction: column;
 width: 100%;
}
.list-top-exchange-buy-detail-view {
 display: flex;
 flex-direction: column;
 border-radius: 1em;
 padding: 0.7em;
 margin: 1vh 0;
}
.list-sub-exchange-buy-detail-view {
 display: flex;
 justify-content: space-between;
 place-items: center;
 width: 100%;
}
.list-sub-exchange-buy-detail-view p {
 display: flex;
 font-size: 1em;
 font-weight: 400;
 line-height: 0.5;
 white-space: nowrap;
 letter-spacing: 0px;
 font-family: var(--font);
} */

.title-head-transfer-self {
 display: flex;
 margin: 6vh 0;
}
.title-head-transfer-self h3 {
 display: flex;
 font-size: 1em;
 font-weight: 400;
 line-height: 0;
 letter-spacing: 0px;
 font-family: var(--font);
}

.title-head-transfer-self-sm {
 display: flex;
 margin: 6vh 0;
}
.title-head-transfer-self-sm h3 {
 display: flex;
 font-size: 1.2em;
 font-weight: 300;
 white-space: nowrap;
 line-height: 1;
 letter-spacing: 0px;
 font-family: var(--font);
}


.flex-grid-center-creditaire {
 display: flex;
 justify-content: space-between;
 place-items: center;
}

.wrp-exchange-buy-detail {
 display: flex;
 flex-direction: column;
 height: 100%;
 min-height: 100vh;
}


.grid-head-dash-litige {
 grid-column: 1 / 3;
}
.grid-head-dash-litige-sm {
 grid-column: 1 / 2;
}
.flex-header-6ch {
 display: flex;
 width: 100%;
 place-items: center;
 height: 6ch;
 padding: 0 3%;
}

.bg-color-hicolblue {
 background-color: #0b2545;
}

.wrp-logo {
 display: flex;
 cursor: pointer;
}
.wrp-logo img {
 height: 3em;
 width: 3em;
 object-fit: cover;
}

.wrp-prix-valid-litige {
 display: flex;
 place-items: center;
 margin-top: 3vh;
}
.wrp-prix-valid-litige p {
 display: flex;
 font-size: 1em;
 font-weight: 300;
 line-height: 1;
 letter-spacing: 0px;
 font-family: var(--font);
}

/* .cashbeck {
 display: flex;
 font-size: 1em;
 font-weight: 300;
 line-height: 1;
 letter-spacing: 0.3px;
 font-family: var(--font);
}
.cashbeck-text {
 display: flex;
 font-size: 0.9em;
 font-weight: 300;
 line-height: 1.3;
 letter-spacing: 0px;
 font-family: var(--font);
} */

.litige-title-transf-two {
 display: flex;
}

.litige-title-transf-two h1 {
 display: flex;
 font-size: 1.155em;
 font-weight: 300;
 line-height: 1;
 letter-spacing: 0px;
 font-family: var(--font);
}

.wrp-body-transfert-valid {
 display: flex;
 flex-direction: column;
 margin-top: 15vh;
}

.flex-transfert-buy-balance {
 display: grid;
 grid-template-rows: auto 1fr auto;
 height: 100vh;
}

.flex-prix-balance-litige-cancel {
 display: flex;
}
.flex-prix-balance-litige-cancel p {
 display: flex;
 font-size: 1.5em;
 font-weight: 100;
 line-height: 0.5;
 letter-spacing: 0px;
 font-family: var(--font);
 color: #679436;
}
.flex-prix-balance-litige-cancel span {
 display: flex;
 font-size: 0.8em;
 font-weight: 100;
 line-height: 0.5;
 letter-spacing: 0px;
 font-family: var(--font);
 margin-top: 1vh;
 color: #d90368;
}

.wrp-list-buy-transfert-cancel {
 display: grid;
 margin-top: 5vh;
}

.wrp-list-buy-transfert-cancel ul {
 padding: 0;
 margin: 0;
 list-style: none;
}

.wrp-list-buy-transfert-cancel ul li {
 display: flex;
 justify-content: space-between;
 place-items: center;
 width: 100%;
}

.wrp-list-buy-transfert-cancel ul li p {
 display: flex;
 font-size: 0.9em;
 font-weight: 300;
 line-height: 1;
 font-family: var(--font);
 letter-spacing: 0px;
}

.wrp-transfert-litige-valid {
 display: grid;
 padding: 5vh 1em;
}

.mrtop-2 {
 margin-top: 2vh;
}

.flex-litige-left {
 display: flex;
 flex-direction: column;
 padding: 1em;
}

.flex-heath-two-litige {
 display: flex;
}

.wrp-profil-litige-two {
 display: flex;
}

.flex-profil-left-litige {
 display: grid;
 place-items: center;
}
.flex-profil-left-litige h4 {
 display: flex;
 font-size: 1.055em;
 font-weight: 300;
 margin-top: 1.5vh;
 line-height: 1;
 letter-spacing: 0px;
 font-family: var(--font);
 text-transform: lowercase;
}

.border-succes {
 display: flex;
 border-radius: 100%;
 padding: 0.1em;
 border: 0.144em solid #493;
}
.border-error {
 display: flex;
 border-radius: 100%;
 padding: 0.1em;
 border: 0.144em solid crimson;
}

.flex-prix-balance-litige {
 display: flex;
}
.flex-prix-balance-litige p {
 display: flex;
 font-size: 1em;
 font-weight: 300;
 line-height: 0.5;
 letter-spacing: 0px;
 font-family: var(--font);
 color: #3e3e3e;
}

.wrp-body-exchange-buy-detail {
 display: flex;
 padding: 3%;
}

/* .list-exchange-buy-detail {
 display: flex;
 flex-direction: column;
 width: 100%;
}
.list-top-exchange-buy-detail {
 display: flex;
 flex-direction: column;
 border-bottom: 1px solid #e7ecef;

 padding-top: 7vh;
}
.list-sub-exchange-buy-detail {
 display: flex;
 justify-content: space-between;
 place-items: center;
 width: 100%;
}
.list-sub-exchange-buy-detail p {
 display: flex;
 font-size: 0.7em;
 font-weight: 400;
 line-height: 0.5;
 white-space: nowrap;
 letter-spacing: 0px;
 font-family: var(--font);
}

.list-top-exchange-info-detail {
 display: flex;
 flex-direction: column;
 border-bottom: 1px solid #e7ecef;

 padding-top: 7vh;
}
.list-sub-exchange-info-detail {
 display: flex;
 justify-content: space-between;
 place-items: center;
 width: 100%;
}
.list-sub-exchange-info-detail p {
 display: flex;
 font-size: 0.7em;
 font-weight: 400;

 padding-top: 2vh;

 line-height: 2;
 white-space: nowrap;
 letter-spacing: 0px;
 font-family: var(--font);
}

.list-exchange-buy-detail-view {
 display: flex;
 flex-direction: column;
 width: 100%;
}
.list-top-exchange-buy-detail-view {
 display: flex;
 flex-direction: column;
 border-radius: 1em;
 padding: 0.7em;
 margin: 1vh 0;
}
.list-sub-exchange-buy-detail-view {
 display: flex;
 justify-content: space-between;
 place-items: center;
 width: 100%;
}
.list-sub-exchange-buy-detail-view p {
 display: flex;
 font-size: 0.7em;
 font-weight: 400;
 line-height: 0.5;
 white-space: nowrap;
 letter-spacing: 0px;
 font-family: var(--font);
} */

.title-head-transfer-self {
 display: flex;
 margin: 6vh 0;
}
.title-head-transfer-self h3 {
 display: flex;
 font-size: 1em;
 font-weight: 400;
 line-height: 0;
 letter-spacing: 0px;
 font-family: var(--font);
}

.title-head-transfer-self-sm {
 display: flex;
 margin: 6vh 0;
}
.title-head-transfer-self-sm h3 {
 display: flex;
 font-size: 1.2em;
 font-weight: 300;
 white-space: nowrap;
 line-height: 1;
 letter-spacing: 0px;
 font-family: var(--font);
}


.flex-grid-center-creditaire {
 display: flex;
 justify-content: space-between;
 place-items: center;
}

.wrp-exchange-buy-detail {
 display: flex;
 flex-direction: column;
 height: 100%;
 min-height: 100vh;
}

.flex-vh-100 {
 display: grid;
 grid-template-columns: minmax(150px, 45%) 1fr;

 height: 100%;
 min-height: 100vh;
}

.flex-vh-100-sm {
 display: grid;
 height: 100%;
 min-height: 100vh;
}

.wrp-side-info {
 display: grid;
 place-items: center;
 height: 100%;
 background-color: #f5f3f4;
}

.flex-img-side-info {
 display: grid;
 place-items: center;
}
.flex-img-side-info img {
 height: 19vw;
 width: 22vw;
 object-fit: cover;
 margin-bottom: 2vh;
}

.grid-center {
 display: grid;
 place-items: center;
 height: 100%;
}

/* .title-pin-hm-main-info {
 display: grid;
 place-items: center;
 width: 100%;
 max-width: 35vw;
}
.title-pin-hm-main-info h2 {
 display: flex;
 font-size: 1.2em;
 font-weight: 400;
 line-height: 1;
 font-family: var(--font);
 letter-spacing: 0px;
 margin-bottom: 1ch;
}
.title-pin-hm-main-info p {
 display: flex;
 font-size: 1em;
 font-weight: 100;
 line-height: 1.2;
 font-family: var(--font);
 letter-spacing: 0px;
 text-align: center;
 color: #aaaaaa;
}

Small
.title-pin-hm-main-info-sm {
 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: center;
 width: 100%;
 max-width: 85vw;
}
.title-pin-hm-main-info-sm h2 {
 display: flex;
 font-size: 1.2em;
 font-weight: 400;
 line-height: 1;
 font-family: var(--font);
 letter-spacing: 0px;
 margin-bottom: 1ch;
}
.title-pin-hm-main-info-sm p {
 display: flex;
 font-size: 1em;
 font-weight: 400;
 line-height: 1.2;
 font-family: var(--font);
 letter-spacing: 0px;
 text-align: center;
 color: #aaaaaa;
} */

/* .wrp-form-input-pin-nows {
 display: flex;
 justify-content: center;
 align-items: center;
 width: 100%;
 max-width: 25em;
}
.wrp-form-input-pin-nows form {
 display: flex;
 flex-direction: column;
 justify-content: center;

 place-items: center;
 padding: 1em;

 width: 100%;
 max-width: 100vw;
}
.wrp-form-input-pin-nows input {
 display: flex;
 font-size: 1.5em;
 font-weight: 500;
 font-family: var(--font);
 line-height: 1;

 letter-spacing: 8px;
 text-align: center;
 padding-left: 1.9em;
 border: none;
}
.wrp-form-input-pin-nows form button {
 display: flex;
 margin-top: 2vh;
 max-width: 100vw;
 font-size: 1.115em;
 border-radius: 0.6em;
 padding: 0.5em;
 font-family: var(--font);
}
.wrp-form-input-pin-nows form p {
 display: flex;
 font-size: 1.1em;
 font-weight: 300;
 line-height: 1.2;
 text-align: center;
 letter-spacing: 0px;
 font-family: var(--font);
 margin: 3vh 0;
}
.title-pop-pret {
 display: flex;
 font-size: 1.155em;
 font-weight: 300;
 font-family: var(--font);
 line-height: 1;
 letter-spacing: 0.3px;
 color: #2dc653;
}
.paragraph-pop-pret {
 display: flex;
 font-size: 0.9em;
 font-weight: 300;
 font-family: var(--font);
 line-height: 1;
 letter-spacing: 0.3px;
 color: #595959;

 margin: 4vh 0;
}
.flex-active-ask-pret {
 display: grid;
 place-items: start;
 width: 100%;
 gap: 1em;
}
.flex-input-pret-modulo {
 display: grid;
 place-items: start;
}
.flex-input-pret-modulo label {
 display: flex;
 font-size: 1.1em;
 font-weight: 300;
 line-height: 1.2;
 text-align: center;
 letter-spacing: 0px;
 font-family: var(--font);
 margin: 1vh 0;
 margin-top: 3vh;
 color: #2b303a;
}
.flex-input-pret-modulo input {
 display: flex;
 font-size: 1.3em;
 font-weight: 100;
 font-family: var(--font);
 line-height: 1;
 letter-spacing: 0px;
 border: none;
 color: #2b303a;
}

Small Pin
.wrp-form-input-pin-nows-sm {
 display: flex;
 justify-content: center;
 align-items: center;
 width: 100%;
}

.wrp-form-input-pin-nows-sm input {
 display: flex;
 font-size: 1.5em;
 font-weight: 500;
 font-family: var(--font);
 line-height: 1;

 letter-spacing: 8px;
 text-align: center;
 padding-left: 1.9em;
 border: none;
}
.wrp-form-input-pin-nows-sm form button {
 display: flex;
 margin-top: 2vh;
 max-width: 100vw;
 font-size: 1.115em;
 border-radius: 0.6em;
 padding: 0.5em;
 font-family: var(--font);
}
.wrp-form-input-pin-nows-sm form p {
 display: flex;
 font-size: 1.1em;
 font-weight: 300;
 line-height: 1.2;
 text-align: center;
 letter-spacing: 0px;
 font-family: var(--font);
 margin: 3vh 0;
}
.title-pop-pret {
 display: flex;
 font-size: 1.155em;
 font-weight: 300;
 font-family: var(--font);
 line-height: 1;
 letter-spacing: 0.3px;
 color: #2dc653;
}
.paragraph-pop-pret {
 display: flex;
 font-size: 0.9em;
 font-weight: 300;
 font-family: var(--font);
 line-height: 1;
 letter-spacing: 0.3px;
 color: #595959;

 margin: 4vh 0;
}
.flex-active-ask-pret {
 display: grid;
 place-items: start;
 width: 100%;
 gap: 1em;
}
.flex-input-pret-modulo {
 display: grid;
 place-items: start;
}
.flex-input-pret-modulo label {
 display: flex;
 font-size: 1.1em;
 font-weight: 300;
 line-height: 1.2;
 text-align: center;
 letter-spacing: 0px;
 font-family: var(--font);
 margin: 1vh 0;
 margin-top: 3vh;
 color: #2b303a;
}
.flex-input-pret-modulo input {
 display: flex;
 font-size: 1.3em;
 font-weight: 100;
 font-family: var(--font);
 line-height: 1;
 letter-spacing: 0px;
 border: none;
 color: #2b303a;
} */

.pad-lef-righ-1em {
 padding: 0 1em;
}

.bg-color-miletor {
 background-color: #a0a499;
}

.wrp-logo-creditaire {
 display: flex;
 flex-direction: row;
}

.title-head-refs-creditaire {
 display: grid;
 padding: 2vh 2%;
}
.title-head-refs-creditaire h1 {
 display: flex;
 font-size: 1em;
 font-weight: 300;
 line-height: 1;
 letter-spacing: 0px;
 font-family: var(--font);
 text-transform: uppercase;
 margin-bottom: 5vh;
 margin-top: 1.9ch;

 color: #99a87d;
}

.flex-head-refs {
 display: grid;
}

.flex-col-list-refs {
 display: flex;
 flex-direction: column;
 padding: 0.5em;
}
.flex-col-list-refs ul {
 padding: 0.5em;
 border-radius: 0.5em;
}
.flex-col-list-refs p {
 display: flex;
 font-size: 0.7em;
 font-weight: 400;
 line-height: 1;
 letter-spacing: 0px;
 font-family: var(--font);
}

.wrp-title-head-refs-creditaire {
 display: grid;
 padding: 2vh 2%;
}

.wrp-title-head-refs-creditaire h1 {
 display: flex;
 font-size: 1em;
 font-weight: 300;
 line-height: 1;
 letter-spacing: 0px;
 font-family: var(--font);
 text-transform: uppercase;

 color: crimson;
}

.flex-head-refs {
 display: grid;
}

.flex-creidit-center {
 display: flex;
 place-items: center;
}
.flex-creidit-center p {
 display: flex;
 font-size: 0.8em;
 font-weight: 300;
 line-height: 1;
 letter-spacing: 0px;
 margin-top: 1vh;
 padding: 0.4em;
 font-family: var(--font);
}

.box-credit-center {
 display: flex;
}
.box-credit-center p {
 display: flex;
 font-size: 0.7em;
 font-weight: 400;
 line-height: 1;
 letter-spacing: 0px;
 margin-top: 2vh;
 padding: 0.4em;
 font-family: var(--font);
}

.flex-block-center {
 display: flex;
 flex-direction: column;
}
.flex-block-center h1 {
 display: flex;
 font-size: 1em;
 font-weight: 400;
 line-height: 4;
 letter-spacing: 0px;
 font-family: var(--font);
}
.flex-block-center p {
 display: flex;
 font-size: 0.8em;
 font-weight: 300;
 line-height: 0.6;
 letter-spacing: 0px;
 font-family: var(--font);
}

.border-bottom-1 {
 border-bottom: 1px solid #dadbdb;
}
.flex-budget-creditaire {
 display: flex;
 margin-top: 2vh;
 padding: 0 3%;
}
.bg-blue {
 height: 100%;
 background-color: #deded6;
}

/* .flex-budget-creditaire p {
 display: flex;
 font-size: 1.1em;
 font-weight: 400;
 line-height: 1;
 letter-spacing: 0px;
 font-family: var(--font);
 color: #444444;
} */

.unit-budget-creditaire {
 position: absolute;
 top: 0;
 right: 0;
 padding: 1em;
}

.flex-center {
 display: flex;
 flex-direction: row;
}

.wrp-budget-creditaire-dash {
 display: flex;
 flex-direction: column;
}

.border-bottom-1 {
 border-bottom: 1px solid #dadbdb;
}
.flex-budget-creditaire {
 display: flex;
 margin-top: 2vh;
 padding: 0 3%;
}

.flex-dash-solde-budget-creditaire {
 display: flex;
 font-size: 1.2em;
 font-weight: 300;
 line-height: 1;
 letter-spacing: 0px;
 font-family: var(--font);
 padding: 0.7em;
}

.flex-budget-creditaire p {
 display: flex;
 font-size: 1em;
 font-weight: 300;
 line-height: 1;
 letter-spacing: 0px;
 font-family: var(--font);
 color: #444444;
}

.unit-budget-creditaire {
 position: absolute;
 top: 0;
 right: 0;
 padding: 1em;
}

.title-operator-budget {
 display: flex;
 padding: 3vh 3%;
}

.title-operator-budget h1 {
 display: flex;
 font-size: 1.2em;
 font-weight: 100;
 line-height: 1;
 letter-spacing: 0px;
 font-family: var(--font);
}

.flex-uppercase-1 {
 display: flex;

 font-size: 0.8em;
 font-weight: 100;
 line-height: 2;
 letter-spacing: 0.5px;
 font-family: var(--font);

 text-transform: uppercase;
}

.ref-operator-creditaire {
 display: grid;
 /* height: 100%; */
 padding: 1em;
}

.martop-10 {
 margin-top: 5vh;
}

.flex-qt-operator-budget-credit {
 display: flex;
}
.flex-qt-operator-budget-credit p {
 display: flex;
 font-size: 1em;
 font-weight: 300;
 line-height: 1;
 letter-spacing: 0px;
 font-family: var(--font);
}

.flex-grid-center-creditaire {
 display: flex;
 justify-content: space-between;
 place-items: center;
}

.wrp-input-ref-signin-user {
 display: flex;
 flex-direction: column;
}
.wrp-input-ref-signin-user form {
 display: grid;
}
.wrp-input-ref-signin-user form input {
 display: flex;
 font-size: 1.1em;
 font-weight: 300;
 line-height: 1;
 margin-top: 1vh;
 letter-spacing: 0px;
 font-family: var(--font);
 color: #343a40;
}
.wrp-input-ref-signin-user form button {
 margin-top: 3vh;
}

/* .color- {
} */

.flex-heigth {
 display: flex;
 grid-template-columns: minmax(150px, 25%) 1fr;
 height: auto;
}

.wrp-list-menu-dash {
 overflow: scroll;
 height: 100vh;
 padding: 1em;
 background-color: #fbfcff;
}

.wrp-stretching {
 display: grid;
 grid-template-columns: repeat(2, 1fr);
}
.wrp-stretching ul {
 margin: 0;
 padding: 0;
 list-style: none;
}
.wrp-stretching ul li {
 flex: 1 1;
}

.flex-list-grid-center {
 display: flex;
 grid-gap: 1rem;
 gap: 1rem;
 place-items: center;
 padding: 0.8em;
}
.flex-list-grid-center .icon-dash {
 padding: 0.5em 0.6em;
 border-radius: 0.8em;
 cursor: pointer;
}
.flex-list-grid-center span {
 display: flex;
 font-size: 10px;
 font-weight: 400;
 white-space: nowrap;
 letter-spacing: 0px;
 font-family: "Quicksand", sans-serif;
 cursor: pointer;
}

/* Small screen */
.flex-list-grid-center-sm {
 display: flex;
 grid-gap: 1rem;
 gap: 1rem;
 place-items: center;
 padding: 0.8em;
}
.flex-list-grid-center-sm .icon-dash {
 padding: 0.5em 0.6em;
 border-radius: 0.8em;
 cursor: pointer;
}
.flex-list-grid-center-sm span {
 display: flex;
 font-size: 0.9em;
 font-weight: 400;
 white-space: nowrap;
 letter-spacing: 0px;
 font-family: "Quicksand", sans-serif;
 cursor: pointer;
}

.wrp-list-main-dash {
 overflow: scroll;
 height: 100vh;
 padding: 1em;
 background-color: #e9ecef;
}

.wrp-nav-head-dash {
 display: flex;
 width: 100%;
 height: 5ch;
 padding: 0 1em;
 background-color: #f5f4f3;
}

.wrp-left-nav-dash {
 display: flex;
 grid-gap: 1em;
 gap: 1em;
}


.wrp-icon-menu-dash {
 margin: 3vh 0;
}

.wrp-budget-head-dash {
 display: flex;
 margin: 7vh 0;
}
.budget-dash-screen-large {
 display: flex;
}
.budget-dash-screen-large p {
 display: flex;
 font-size: 1.7em;
 font-weight: 300;
 line-height: 1;
 letter-spacing: 0px;

 font-family: "Quicksand", sans-serif;
 font-optical-sizing: auto;
 font-style: normal;
}

/* small large */
.budget-dash-screen-large-sm {
 display: flex;
}
.budget-dash-screen-large-sm p {
 display: flex;
 font-size: 4.5vw;
 font-weight: 300;
 line-height: 1;
 letter-spacing: 0px;
 white-space: nowrap;

 font-family: "Quicksand", sans-serif;
 font-optical-sizing: auto;
 font-style: normal;
}

/* .wrp-all-actif-user-info {
 display: grid;
 place-items: start;
}
.wrp-all-actif-user-info h4 {
 display: flex;
 font-size: 0.9em;
 font-weight: 400;
 line-height: 1;
 letter-spacing: 0px;
 font-family: var(--font);
 margin-bottom: 2.5vh;
 color: #9999a1;
}
.wrp-all-actif-user-info p {
 display: flex;
 font-size: 0.9em;
 font-weight: 400;
 line-height: 1;
 white-space: nowrap;
 letter-spacing: 0px;
 font-family: var(--font);

 margin-bottom: 1vh;
}
.actif-red {
 color: #d62246;
}

.wrp-all-actif-user-info-sm {
 display: grid;
 place-items: start;
}
.wrp-all-actif-user-info-sm h4 {
 display: flex;
 font-size: 0.9em;
 font-weight: 400;
 line-height: 1;
 letter-spacing: 0px;
 font-family: var(--font);
 margin-bottom: 2.5vh;
 color: #9999a1;
}
.wrp-all-actif-user-info-sm p {
 display: flex;
 font-size: 0.7em;
 font-weight: 400;
 line-height: 1;
 white-space: nowrap;
 letter-spacing: 0px;
 font-family: var(--font);

 margin-bottom: 1vh;
}
.actif-red {
 color: #d62246;
} */

.wrp-avatar-transfer-identity-dash {
 display: flex;
 place-items: center;
 margin: 5vh 0;
}
.wrp-avatar-transfer-identity-dash p {
 display: flex;
 font-size: 1em;
 font-weight: 300;
 line-height: 0;
 letter-spacing: 0.3px;
 font-family: var(--font);
 margin-left: 0.5em;
 padding-top: 3ch;
}

/* Small */
.wrp-avatar-transfer-identity-dash-sm {
 display: grid;
 place-items: center;
}
.wrp-avatar-transfer-identity-dash-sm p {
 display: flex;
 font-size: 0.8em;
 font-weight: 100;
 line-height: 0;
 text-align: end;
 letter-spacing: 0.3px;
 font-family: var(--font);
 color: #fff;
}

.wrp-hm-dash-list-user-pret {
 display: flex;
 justify-content: space-between;
 place-items: center;
 width: 100%;
 cursor: pointer;
 max-width: 100vw;
 border-bottom: 1px solid #eeeeee;
}
.wrp-hm-dash-list-user-pret p {
 display: flex;
 font-size: 0.8em;
 font-weight: 300;
 text-align: start;
 white-space: nowrap;
 line-height: 0.3;
 letter-spacing: 0px;
 font-family: var(--font);
 margin-top: 3vh;
 color: #5b5b5b;
}
.flex-money-user-list {
 display: flex;
 flex-direction: column;
}
.flex-money-user-list span {
 display: flex;
 font-size: 0.7em;
 width: 10em;

 font-weight: 400;
 text-align: center;
 white-space: nowrap;
 letter-spacing: 0px;
 font-family: var(--font);
 color: #5b5b5b;
}

.flex-pret-title-user {
 display: grid;
 padding: 0 3%;
 margin-top: 5vh;
}
.flex-pret-title-user h4 {
 display: flex;
 font-size: 1.2em;
 font-weight: 400;
 line-height: 1;
 letter-spacing: 0px;
 font-family: var(--font);
 color: #004e98;
}

.mr-top-bottom-3 {
 margin: 3vh 0;
}

.scroll-user-list-total-dash {
 overflow: scroll;
 height: 60vh;
}
.scroll-user-list-tontine {
 overflow: scroll;
 height: 80vh;
}

.flex {
 display: flex;
 place-items: center;
 grid-gap: 1em;
 gap: 1em;
}

.flex-thrift {
 display: grid;
}

.flex-thrift p {
 display: flex;
 font-size: 0.9em;
 font-weight: 300;
 line-height: 1;
 letter-spacing: 0px;
 font-family: var(--font);
}

.scroll-user-list-total-dash {
 overflow: scroll;
 height: 80vh;
}

.wrp-unit-dash {
 display: grid;
 margin-top: 10vh;
}

.wrp-body-throw-over-dash {
 display: flex;
 justify-content: space-between;
 align-items: center;
 width: 100%;
 border-radius: none;
}
.wrp-body-throw-content {
 display: grid;
 place-items: start;
}
.wrp-body-throw-content h3 {
 display: flex;
 font-size: 1em;
 font-family: var(--font);
 font-weight: 400;
 line-height: 1;
 letter-spacing: 0px;
 margin-bottom: 3vh;
 white-space: nowrap;
}
.wrp-body-throw-content p {
 display: flex;
 font-size: 0.94em;
 font-family: var(--font);
 font-weight: 100;
 line-height: 1;
 letter-spacing: 0px;
 margin: 1.2vh 0;
 white-space: nowrap;
 color: #7f7f7f;
}

.flex-stock-detail {
 display: grid;
 place-items: start;
 margin-top: 1vh;
}
.flex-stock-detail p {
 display: flex;
 font-size: 0.9em;
 font-weight: 400;
 font-family: var(--font);
 line-height: 0.8;
 letter-spacing: 0px;
}

.flex-pop-up-cmd-airtel {
 display: flex;
 flex-direction: row;
 justify-content: space-between;
 line-height: 1;
 width: 100%;
}
.flex-pop-up-cmd-airtel div {
 display: flex;
 flex-direction: row;
}

.input-signin-user {
 display: flex;
 flex-direction: column;
 width: 100%;

 justify-content: center;
 align-items: center;
}
.input-signin-user form {
 display: flex;
 width: 100%;

 height: 100vh;
 overflow: scroll;
 max-width: 25em;
 flex-direction: column;
}
.input-signin-user form input {
 display: flex;
 flex-direction: column;
 font-size: 1.1em;
 font-weight: 500;
 font-family: "Quicksand", sans-serif;
 line-height: 2;
 padding: 1.5vh 0;
 letter-spacing: 0.5px;
 border: none;
 border-bottom: 1px solid #b0bacf;

 color: #000;

 background: transparent;
}
.input-signin-user form button {
 margin-top: 3vh;
}

/* Small screen */
.input-signin-user-sm {
 display: flex;
 flex-direction: column;
 width: 100%;
 justify-content: center;
 align-items: center;
 margin-top: 3vh;
}
.input-signin-user-sm form {
 display: flex;
 width: 100%;
 padding: 1em;
 height: 100vh;
 overflow: scroll;
 flex-direction: column;
}
.input-signin-user-sm form input {
 display: flex;
 flex-direction: column;
 font-size: 1.1em;
 font-weight: 500;
 font-family: "Quicksand", sans-serif;
 line-height: 2;
 padding: 1.5vh 0;
 letter-spacing: 0.5px;
 border: none;
 border-bottom: 1px solid #b0bacf;

 color: #000;

 background: transparent;
}
.input-signin-user-sm form button {
 margin-top: 3vh;
}

.pop-rate {
 display: flex;
 font-size: 0.8em;
 font-weight: 300;
 line-height: 1;
 letter-spacing: 0px;
 font-family: var(--font);
}

.flex-title-rate-form {
 display: flex;
 margin-top: 5vh;
}

.flex-title-rate-form h3 {
 display: flex;
 font-size: 1em;
 font-weight: 300;
 line-height: 1;
 letter-spacing: 0px;
 font-family: var(--font);
}

.flex-title-form-enroll {
 display: flex;
 padding: 1em;
}
.flex-title-form-enroll h5 {
 display: flex;
 font-size: 1em;
 font-weight: 400;
 line-height: 1;
 letter-spacing: 0.5px;
 font-family: var(--font);
 text-transform: uppercase;
}

.bg-color-green-type {
 height: 100vh;
 background-color: #468faf;
}

.bg-color-green-type-sm {
 height: 10vh;
 background-color: #468faf;
}

.flex-form-enrol {
 display: grid;
 height: 100%;
 min-height: 100vh;
 grid-template-columns: minmax(150px, 55%) 1fr;
}

.flex-form-enrol-sm {
 display: grid;
 height: 100%;
 min-height: 100vh;
 grid-template-rows: auto 1fr auto;
}

.flex-title-hm-send-money {
 background-color: #344;
}
.flex-title-hm-send-money h1 {
 display: flex;
 font-size: 1em;
 font-weight: 400;
 line-height: 1;
 letter-spacing: 0.5px;
 font-family: var(--font);
 text-transform: uppercase;
 color: #6c757d;
}

.flex-title-hm-send-money-sm {
 background-color: #344;
 height: 10vh;
}
.flex-title-hm-send-money-sm h1 {
 display: flex;
 font-size: 1em;
 font-weight: 400;
 line-height: 1;
 letter-spacing: 0.5px;
 font-family: var(--font);
 text-transform: uppercase;
 color: #6c757d;
}

.wrp-num-phone-send {
 display: flex;
 flex-direction: column;
 width: 100%;
 max-width: 70%;
}
.wrp-num-phone-send form {
 display: flex;
 flex-direction: column;
 grid-gap: 2vh;
 gap: 2vh;
}
.wrp-num-phone-send form input {
 display: flex;
 flex-direction: column;
 font-size: 1.144em;
 font-weight: 500;
 line-height: 1;
 letter-spacing: 0.2px;

 width: 100%;
 font-family: "Quicksand", sans-serif;
 border: none;
 color: black;
 background: transparent;
}

/* Small */
.wrp-num-phone-send-sm {
 display: flex;
 flex-direction: column;
 width: 100%;
 max-width: 90vw;
}
.wrp-num-phone-send-sm form {
 display: flex;
 flex-direction: column;
 grid-gap: 2vh;
 gap: 2vh;
}
.wrp-num-phone-send-sm form input {
 display: flex;
 flex-direction: column;
 font-size: 1.144em;
 font-weight: 500;
 line-height: 1;
 letter-spacing: 0.2px;

 width: 100%;
 font-family: "Quicksand", sans-serif;
 border: none;
 color: black;
 background: transparent;
}

.wrp-user-transfert-send {
 display: grid;
 grid-template-rows: auto 1fr auto;
}

.block-center {
 display: grid;
 place-items: center;
}
.block-gap-1 {
 grid-gap: 1vh;
 gap: 1vh;
}
.flex-avatar-user-profil-transfert p {
 display: flex;
 font-size: 0.9em;
 font-weight: 400;
 line-height: 1;
 letter-spacing: 0px;
 font-family: var(--font);
 text-transform: uppercase;

 color: #343a40;
}
.border-raduis-2 {
 border-radius: 50%;
 padding: 2px;
 border: 1px solid crimson;
}
.wrp-count-send-transfert p {
 display: flex;
 font-size: 2em;
 font-weight: 300;
 line-height: 1;
 letter-spacing: 0px;
 font-family: "Quicksand", sans-serif;
}

.input-withdraw {
 display: flex;
 flex-direction: column;

 justify-content: center;
 align-items: center;

 height: 100%;
 width: 100%;
 max-width: 21em;
}
.input-withdraw form {
 display: flex;
 flex-direction: column;
 width: 100%;
 margin-top: 4ch;
}
.input-withdraw input {
 display: flex;
 outline: none;
 border: none;
 /* font-family: "Quicksand", sans-serif; */
 font-family: var(--font);
 padding: 0.2vh 0;
 font-size: 2em;
 justify-content: center;
 align-items: center;
 width: 100%;

 font-weight: 400;
 text-align: center;
 letter-spacing: 1px;
 border-radius: 0.3em;
}
.input-withdraw h2 {
 display: flex;
 font-size: 1.2em;
 font-weight: 300;
 font-family: "Quicksand", sans-serif;
 line-height: 1;
 letter-spacing: 0px;
}
.input-withdraw button {
 margin-top: 2vh;
}

.wrp-item-reduce {
 display: flex;
}

.wrp-item-reduce h1 {
 display: flex;
 font-size: 2em;
 font-weight: 400;
 font-family: "Quicksand", sans-serif;
 line-height: 1;
 letter-spacing: 0px;
}

.flex-user-quote-transfert-send-money {
 display: flex;
}
.flex-user-quote-transfert-send-money p {
 display: flex;
 font-size: 0.9em;
 font-weight: 300;
 line-height: 1.155;
 letter-spacing: 0px;
 text-align: center;
 font-family: var(--font);
 color: #c1121f;
}

.flex-budget-transfert {
 display: flex;
 background-color: #edede9;
}

.wrp-user-transfert-send {
 display: grid;
 grid-template-rows: auto 1fr auto;
}

.block-center {
 display: grid;
 place-items: center;
}
.block-gap-1 {
 grid-gap: 1vh;
 gap: 1vh;
}
.flex-avatar-user-profil-transfert p {
 display: flex;
 font-size: 0.9em;
 font-weight: 400;
 line-height: 1;
 letter-spacing: 0px;
 font-family: var(--font);
 text-transform: uppercase;

 color: #343a40;
}
.border-raduis-2 {
 border-radius: 50%;
 padding: 2px;
 border: 1px solid crimson;
}
.wrp-count-send-transfert p {
 display: flex;
 font-size: 2em;
 font-weight: 300;
 line-height: 1;
 letter-spacing: 0px;
 font-family: "Quicksand", sans-serif;
}

.input-withdraw {
 display: flex;
 flex-direction: column;

 justify-content: center;
 align-items: center;

 height: 100%;
 width: 100%;
 max-width: 21em;
}
.input-withdraw form {
 display: flex;
 flex-direction: column;
 width: 100%;
 margin-top: 4ch;
}
.input-withdraw input {
 display: flex;
 outline: none;
 border: none;
 /* font-family: "Quicksand", sans-serif; */
 font-family: var(--font);
 padding: 0.2vh 0;
 font-size: 2em;
 justify-content: center;
 align-items: center;
 width: 100%;

 font-weight: 400;
 text-align: center;
 letter-spacing: 1px;
 border-radius: 0.3em;
}
.input-withdraw h2 {
 display: flex;
 font-size: 1.2em;
 font-weight: 300;
 font-family: "Quicksand", sans-serif;
 line-height: 1;
 letter-spacing: 0px;
}
.input-withdraw button {
 margin-top: 2vh;
}

.wrp-item-reduce {
 display: flex;
}

.wrp-item-reduce h1 {
 display: flex;
 font-size: 2em;
 font-weight: 400;
 font-family: "Quicksand", sans-serif;
 line-height: 1;
 letter-spacing: 0px;
}

.flex-admin-quote-addbudget {
 display: flex;
}
.flex-admin-quote-addbudget p {
 display: flex;
 font-size: 0.9em;
 font-weight: 300;
 line-height: 1.155;
 letter-spacing: 0px;
 font-family: var(--font);
 text-align: center;
 color: #7f7f7f;
}

.input-withdraw-drawer {
 display: flex;
 flex-direction: column;

 justify-content: center;
 align-items: center;

 height: 100%;
 width: 100%;
 padding: 1em;
}
.input-withdraw-drawer form {
 display: flex;
 flex-direction: column;
 width: 100%;
 margin-top: 4ch;
}
.input-withdraw-drawer input {
 display: flex;
 outline: none;
 border: none;
 /* font-family: "Quicksand", sans-serif; */
 font-family: var(--font);
 padding: 0.2vh 0;
 font-size: 2em;
 justify-content: center;
 align-items: center;
 width: 100%;

 font-weight: 400;
 text-align: center;
 letter-spacing: 1px;
 border-radius: 0.3em;
}
.input-withdraw-drawer h2 {
 display: flex;
 font-size: 1.2em;
 font-weight: 300;
 font-family: "Quicksand", sans-serif;
 line-height: 1;
 letter-spacing: 0px;
}
.input-withdraw-drawer button {
 margin-top: 2vh;
}
.wrp-item-reduce {
 display: flex;
}
.wrp-item-reduce h1 {
 display: flex;
 font-size: 2em;
 font-weight: 400;
 font-family: "Quicksand", sans-serif;
 line-height: 1;
 letter-spacing: 0px;
}

.quote-transfert-send-budget {
 display: flex;
}
.quote-transfert-send-budget p {
 display: flex;
 font-size: 1em;
 font-weight: 300;
 line-height: 1.155;
 letter-spacing: 0px;
 text-align: center;
 font-family: var(--font);
}

.flex-budget-transfert {
 display: flex;
 background-color: #edede9;
}

.flex-title-hm-send-money {
 background-color: #344;
}
.flex-title-hm-send-money h1 {
 display: flex;
 font-size: 1em;
 font-weight: 400;
 line-height: 1;
 letter-spacing: 0.5px;
 font-family: var(--font);
 text-transform: uppercase;
 color: #6c757d;
}

.flex-title-hm-send-money-sm {
 background-color: #344;
 height: 10vh;
}
.flex-title-hm-send-money-sm h1 {
 display: flex;
 font-size: 1em;
 font-weight: 400;
 line-height: 1;
 letter-spacing: 0.5px;
 font-family: var(--font);
 text-transform: uppercase;
 color: #6c757d;
}

.wrp-num-phone-send {
 display: flex;
 flex-direction: column;
 width: 100%;
 max-width: 70%;
}
.wrp-num-phone-send form {
 display: flex;
 flex-direction: column;
 grid-gap: 2vh;
 gap: 2vh;
}
.wrp-num-phone-send form input {
 display: flex;
 flex-direction: column;
 font-size: 1.144em;
 font-weight: 500;
 line-height: 1;
 letter-spacing: 0.2px;

 width: 100%;
 font-family: "Quicksand", sans-serif;
 border: none;
 color: black;
 background: transparent;
}

/* Small */
.wrp-num-phone-send-sm {
 display: flex;
 flex-direction: column;
 width: 100%;
 max-width: 90vw;
}
.wrp-num-phone-send-sm form {
 display: flex;
 flex-direction: column;
 grid-gap: 2vh;
 gap: 2vh;
}
.wrp-num-phone-send-sm form input {
 display: flex;
 flex-direction: column;
 font-size: 1.144em;
 font-weight: 500;
 line-height: 1;
 letter-spacing: 0.2px;

 width: 100%;
 font-family: "Quicksand", sans-serif;
 border: none;
 color: black;
 background: transparent;
}

.flex-title-hm-send-money {
 background-color: #344;
}
.flex-title-hm-send-money h1 {
 display: flex;
 font-size: 1em;
 font-weight: 400;
 line-height: 1;
 letter-spacing: 0.5px;
 font-family: var(--font);
 text-transform: uppercase;
 color: #6c757d;
}

.flex-title-hm-send-money-sm {
 background-color: #344;
 height: 10vh;
}
.flex-title-hm-send-money-sm h1 {
 display: flex;
 font-size: 1em;
 font-weight: 400;
 line-height: 1;
 letter-spacing: 0.5px;
 font-family: var(--font);
 text-transform: uppercase;
 color: #6c757d;
}

.wrp-num-phone-send {
 display: flex;
 flex-direction: column;
 width: 100%;
 max-width: 70%;
}
.wrp-num-phone-send form {
 display: flex;
 flex-direction: column;
 grid-gap: 2vh;
 gap: 2vh;
}
.wrp-num-phone-send form input {
 display: flex;
 flex-direction: column;
 font-size: 1.144em;
 font-weight: 500;
 line-height: 1;
 letter-spacing: 0.2px;

 width: 100%;
 font-family: "Quicksand", sans-serif;
 border: none;
 color: black;
 background: transparent;
}

/* Small */
.wrp-num-phone-send-sm {
 display: flex;
 flex-direction: column;
 width: 100%;
 max-width: 90vw;
}
.wrp-num-phone-send-sm form {
 display: flex;
 flex-direction: column;
 grid-gap: 2vh;
 gap: 2vh;
}
.wrp-num-phone-send-sm form input {
 display: flex;
 flex-direction: column;
 font-size: 1.144em;
 font-weight: 500;
 line-height: 1;
 letter-spacing: 0.2px;

 width: 100%;
 font-family: "Quicksand", sans-serif;
 border: none;
 color: black;
 background: transparent;
}

.wrp-user-transfert-send {
 display: grid;
 grid-template-rows: auto 1fr auto;
}

.block-center {
 display: grid;
 place-items: center;
}
.block-gap-1 {
 grid-gap: 1vh;
 gap: 1vh;
}
.flex-avatar-user-profil-transfert p {
 display: flex;
 font-size: 0.9em;
 font-weight: 400;
 line-height: 1;
 letter-spacing: 0px;
 font-family: var(--font);
 text-transform: uppercase;

 color: #343a40;
}
.border-raduis-2 {
 border-radius: 50%;
 padding: 2px;
 border: 1px solid crimson;
}
.wrp-count-send-transfert p {
 display: flex;
 font-size: 2em;
 font-weight: 300;
 line-height: 1;
 letter-spacing: 0px;
 font-family: "Quicksand", sans-serif;
}

.input-withdraw {
 display: flex;
 flex-direction: column;

 justify-content: center;
 align-items: center;

 height: 100%;
 width: 100%;
 max-width: 21em;
}
.input-withdraw form {
 display: flex;
 flex-direction: column;
 width: 100%;
 margin-top: 4ch;
}
.input-withdraw input {
 display: flex;
 outline: none;
 border: none;
 /* font-family: "Quicksand", sans-serif; */
 font-family: var(--font);
 padding: 0.2vh 0;
 font-size: 2em;
 justify-content: center;
 align-items: center;
 width: 100%;

 font-weight: 400;
 text-align: center;
 letter-spacing: 1px;
 border-radius: 0.3em;
}
.input-withdraw h2 {
 display: flex;
 font-size: 1.2em;
 font-weight: 300;
 font-family: "Quicksand", sans-serif;
 line-height: 1;
 letter-spacing: 0px;
}
.input-withdraw button {
 margin-top: 2vh;
}

.wrp-item-reduce {
 display: flex;
}

.wrp-item-reduce h1 {
 display: flex;
 font-size: 2em;
 font-weight: 400;
 font-family: "Quicksand", sans-serif;
 line-height: 1;
 letter-spacing: 0px;
}

.flex-admin-quote-addbudget {
 display: flex;
}
.flex-admin-quote-addbudget p {
 display: flex;
 font-size: 0.9em;
 font-weight: 300;
 line-height: 1.155;
 letter-spacing: 0px;
 font-family: var(--font);
 text-align: center;
 color: #7f7f7f;
}

.input-withdraw-drawer {
 display: flex;
 flex-direction: column;

 justify-content: center;
 align-items: center;

 height: 100%;
 width: 100%;
 padding: 1em;
}
.input-withdraw-drawer form {
 display: flex;
 flex-direction: column;
 width: 100%;
 margin-top: 4ch;
}
.input-withdraw-drawer input {
 display: flex;
 outline: none;
 border: none;
 /* font-family: "Quicksand", sans-serif; */
 font-family: var(--font);
 padding: 0.2vh 0;
 font-size: 2em;
 justify-content: center;
 align-items: center;
 width: 100%;

 font-weight: 400;
 text-align: center;
 letter-spacing: 1px;
 border-radius: 0.3em;
}
.input-withdraw-drawer h2 {
 display: flex;
 font-size: 1.2em;
 font-weight: 300;
 font-family: "Quicksand", sans-serif;
 line-height: 1;
 letter-spacing: 0px;
}
.input-withdraw-drawer button {
 margin-top: 2vh;
}
.wrp-item-reduce {
 display: flex;
}
.wrp-item-reduce h1 {
 display: flex;
 font-size: 2em;
 font-weight: 400;
 font-family: "Quicksand", sans-serif;
 line-height: 1;
 letter-spacing: 0px;
}

.quote-transfert-send-budget {
 display: flex;
}
.quote-transfert-send-budget p {
 display: flex;
 font-size: 1em;
 font-weight: 300;
 line-height: 1.155;
 letter-spacing: 0px;
 text-align: center;
 font-family: var(--font);
}

.flex-budget-transfert {
 display: flex;
 background-color: #edede9;
}

.flex-head-share {
 display: flex;
 height: 6ch;
 width: 100%;
 background-color: #277da1;
}
.p-left-right-1 {
 padding: 0 1em;
}

.flex-title-head-share {
 display: flex;
 color: #fff;
}
.flex-title-head-share h1 {
 display: flex;
 font-size: 1em;
 font-weight: 200;
 line-height: 1;
 letter-spacing: 0px;
 font-family: var(--font);
}

.flex-list-share {
 display: grid;
}

.flex-list-share p {
 display: flex;
 font-size: 0.8em;
 line-height: 1;
 letter-spacing: 0px;
 font-family: var(--font);
}

.flex-etablish {
 display: flex;
 font-size: 1em;
 line-height: 1;
 letter-spacing: 0px;
 font-family: var(--font);
 text-transform: uppercase;
}

.flex-total-share {
 display: flex;
 margin-top: 3vh;
}

.flex-total-share p {
 display: flex;
 font-size: 1.2em;
 font-weight: 300;
 line-height: 1;
 letter-spacing: 0px;
 font-family: var(--font);
 color: dodgerblue;
}

.flex-view-send-share {
 display: grid;
 height: 100vh;
 min-height: 100%;
 grid-template-rows: auto 1fr auto;
}
.block-flex-center-wifi {
 display: grid;
 place-items: center;
}

.flex-history-share {
 display: grid;
 margin: 0.5em;
}
.flex-history-share p {
 display: flex;
 font-size: 0.8em;
 font-weight: 300;
 line-height: 1.3;
 letter-spacing: 0px;
 font-family: var(--font);
}
.flex-box-history {
 display: grid;
 grid-gap: 1vh;
 gap: 1vh;
 padding: 1em;
 border-radius: 1em;
 background-color: #f6f6f6;
}
.center {
 display: grid;
 place-items: start;
}

.flex-etablish-list {
 display: flex;
 padding: 1em;
 width: 20em;
 color: #2032;
}
.flex-etablish-list p {
 display: flex;
 font-size: 1em;
 font-weight: 300;
 text-align: center;
 line-height: 1.3;
 white-space: nowrap;
 letter-spacing: 0px;
 font-family: var(--font);
}

.grid-share-col {
 display: grid;
 grid-template-columns: minmax(150px, 35%) auto;
}

.pad-1 {
 padding: 1em;
}

