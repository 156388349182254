.flex-price-increase {
 display: grid;
 place-items: start;
 margin: 3vh 0;
}

.wrp-head-pack-title h2 {
 display: flex;
 font-size: 1.6em;
 font-weight: 300;
 line-height: 0.5;
 letter-spacing: -1px;
 font-family: "Noto Sans Cham", sans-serif;
 padding: 0 0.5em;
 color: #004b23;
}

.flex-price-increase p {
 display: flex;
 font-size: 1.3em;
 font-weight: 300;
 line-height: 0.5;
 letter-spacing: -1px;
 font-family: "Noto Sans Cham", sans-serif;
 color: #004b23;
}

.flex-price-increase span {
 display: flex;
 font-size: 0.7em;
 font-weight: 300;
 line-height: 0;
 padding-left: 3em;
 letter-spacing: 0px;
 font-family: "Noto Sans Cham", sans-serif;
 color: crimson;
}

.flex-padding-1em {
 padding: 1em;
}
