.hm-page {
 display: flex;
 flex-direction: row;
 width: 100%;
}
.hm-page-sm {
 display: flex;
 flex-direction: column;
 width: 100%;
}
