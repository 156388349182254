.flex-uppercase-1 {
 display: flex;

 font-size: 0.8em;
 font-weight: 100;
 line-height: 2;
 letter-spacing: 0.5px;
 font-family: var(--font);

 text-transform: uppercase;
}
