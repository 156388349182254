.flex-sign-hm {
 display: flex;
 flex-direction: column;
 width: 100%;
 justify-content: center;
 align-items: center;
 gap: 2em;
 color: var(--color-text-gold);
 background-color: var(--color-bg);
}
.flex-sign-hm-sm {
 display: flex;
 flex-direction: column;

 width: 100%;
 height: 75vh;
 justify-content: center;
 align-items: center;
 color: var(--color-text-gold);
 background-color: var(--color-bg);
}
