.box-unit-list-month {
 display: flex;
 flex-direction: column;
 padding: 2ch 0;
 border-bottom: 2px solid #ced4da;
}
.box-unit-list-month h3 {
 display: flex;
 font-size: 1.2em;
 font-weight: 400;
 font-family: var(--font);
 line-height: 1;
 letter-spacing: 0px;
}
.wrp-unit-list-month {
 display: grid;
 width: 100%;
 place-items: start;
 margin-top: 3vh;
}
.wrp-unit-list-month p {
 display: flex;
 font-size: 1.155em;
 font-family: var(--font);
 font-weight: 400;
 line-height: 0.5;
 letter-spacing: 0px;
}

.box-unit-list-month h3 {
 display: flex;
 font-size: 1.155em;
 font-family: var(--font);
 font-weight: 400;
 line-height: 0.5;
 letter-spacing: 0px;
 color: #8a5a44;
}

.wrp-unit-list-month h2 {
 display: flex;
 font-size: 2.5em;
 font-family: var(--font);
 font-weight: 200;
 line-height: 1;
 letter-spacing: 0px;
 color: #8a5a44;
}
