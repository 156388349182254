.wrp-title-head-progress-global {
 display: flex;
}

.wrp-title-head-progress-global h2 {
 display: flex;
 font-size: 1.255em;

 font-weight: 300;
 line-height: 1;
 letter-spacing: 0px;
 font-family: var(--font);
 color: #005db3;
}
