.block-center {
 display: grid;
 place-items: center;
}
.block-gap-1 {
 gap: 1vh;
}
.flex-avatar-user-profil-transfert p {
 display: flex;
 font-size: 0.9em;
 font-weight: 400;
 line-height: 1;
 letter-spacing: 0px;
 font-family: var(--font);
 text-transform: uppercase;

 color: #343a40;
}
.border-raduis-2 {
 border-radius: 50%;
 padding: 2px;
 border: 1px solid crimson;
}
.wrp-count-send-transfert p {
 display: flex;
 font-size: 2em;
 font-weight: 300;
 line-height: 1;
 letter-spacing: 0px;
 font-family: "Quicksand", sans-serif;
}
