.wrp-head-hm-info {
 padding: 0.7rem;
 grid-column: 1 / 3;
}
.wrp-head-hm-dash-info {
 padding: 0.7rem;
 grid-column: 1 / 4;
}
.wrp-head-hm-dash-info-two {
 grid-column: 1 / 3;
}
.wrp-head-hm-dash-info-one {
 grid-column: 1 / 2;
}

.wrp-head-hm-info-small {
 padding: 0.7rem;
 grid-column: 1 / 2;
}
.wrp-head-hm-dash-info-small {
 padding: 0.7rem;
 grid-column: 1 / 2;
}

.wrp-head-hm-info-client {
 padding: 0.7rem;
 grid-column: 1 / 4;
}
.flex-border-bottom {
 border-bottom: 1px solid #d3d3d3;
}
.flex-border-left {
 border-left: 1px solid #d3d3d3;
}
.flex-border-rigth {
 border-right: 1px solid #d3d3d3;
}
