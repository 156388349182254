.btn-large-hm-actif-all {
 display: grid;
 position: absolute;
 right: 3%;
 bottom: 10%;
}
.btn-large-hm-actif-all span {
 display: flex;
 cursor: pointer;
 padding: 0.6em;
 border-radius: 50%;
 background-color: #d4d700;
}

/* Small btn */
.btn-large-hm-actif-all-sm {
 display: grid;
 position: absolute;
 left: 5%;
 bottom: 10%;
}
.btn-large-hm-actif-all-sm span {
 display: flex;
 cursor: pointer;
 padding: 0.6em;
 border-radius: 50%;
 background-color: #d4d700;
}
