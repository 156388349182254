.flex-total-share {
 display: flex;
 margin-top: 3vh;
}

.flex-total-share p {
 display: flex;
 font-size: 1.2em;
 font-weight: 300;
 line-height: 1;
 letter-spacing: 0px;
 font-family: var(--font);
 color: dodgerblue;
}
