.flex-btn-stock {
 display: flex;
 width: 100%;
 max-width: 20em;
}
.flex-btn-stock button {
 display: flex;

 font-size: 1.155em;
 letter-spacing: 0.3px;
 font-family: var(--font);
 white-space: nowrap;
 outline-style: none;
 font-weight: 400;

 justify-content: center;
 align-items: center;

 padding: 0.5em 0;

 cursor: pointer;
 width: 100%;
 color: #55606a;

 border-radius: 0.6em;
 border: 0.1px solid var(--color-text);
 background-color: var(--color-text);
}

/* Small screen */
.flex-btn-stock-sm {
 display: flex;
 width: 100%;
 max-width: 95vw;
}
.flex-btn-stock-sm button {
 display: flex;

 font-size: 1.155em;
 letter-spacing: 0px;
 font-family: var(--font);
 white-space: nowrap;
 outline-style: none;
 font-weight: 400;

 justify-content: center;
 align-items: center;

 padding: 0.5em 0;

 cursor: pointer;
 width: 100%;
 color: #55606a;

 border-radius: 0.6em;
 border: 0.1px solid var(--color-text);
 background-color: var(--color-text);
}
