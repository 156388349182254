.flex-title-hm-send-money {
 background-color: #344;
}
.flex-title-hm-send-money h1 {
 display: flex;
 font-size: 1em;
 font-weight: 400;
 line-height: 1;
 letter-spacing: 0.5px;
 font-family: var(--font);
 text-transform: uppercase;
 color: #6c757d;
}

.flex-title-hm-send-money-sm {
 background-color: #344;
 height: 10vh;
}
.flex-title-hm-send-money-sm h1 {
 display: flex;
 font-size: 1em;
 font-weight: 400;
 line-height: 1;
 letter-spacing: 0.5px;
 font-family: var(--font);
 text-transform: uppercase;
 color: #6c757d;
}
