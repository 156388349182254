.wrp-num-phone-send {
 display: flex;
 flex-direction: column;
 width: 100%;
 max-width: 70%;
}
.wrp-num-phone-send form {
 display: flex;
 flex-direction: column;
 gap: 2vh;
}
.wrp-num-phone-send form input {
 display: flex;
 flex-direction: column;
 font-size: 1.144em;
 font-weight: 500;
 line-height: 1;
 letter-spacing: 0.2px;

 width: 100%;
 font-family: "Quicksand", sans-serif;
 border: none;
 color: black;
 background: transparent;
}

/* Small */
.wrp-num-phone-send-sm {
 display: flex;
 flex-direction: column;
 width: 100%;
 max-width: 90vw;
}
.wrp-num-phone-send-sm form {
 display: flex;
 flex-direction: column;
 gap: 2vh;
}
.wrp-num-phone-send-sm form input {
 display: flex;
 flex-direction: column;
 font-size: 1.144em;
 font-weight: 500;
 line-height: 1;
 letter-spacing: 0.2px;

 width: 100%;
 font-family: "Quicksand", sans-serif;
 border: none;
 color: black;
 background: transparent;
}
