.litige-title-transf-two {
 display: flex;
}

.litige-title-transf-two h1 {
 display: flex;
 font-size: 1.155em;
 font-weight: 300;
 line-height: 1;
 letter-spacing: 0px;
 font-family: var(--font);
}
