.flex-profil-left-litige-transf {
 display: grid;
 place-items: center;
}
.flex-profil-left-litige-transf h4 {
 display: flex;
 font-size: 0.9em;
 font-weight: 300;
 margin-top: 1vh;
 line-height: 1;
 letter-spacing: 0px;
 font-family: var(--font);
 text-transform: lowercase;
}
.flex-profil-left-litige-transf h5 {
 display: flex;
 font-size: 0.9em;
 font-weight: 400;
 margin-top: 1vh;
 line-height: 1;
 letter-spacing: 0px;
 font-family: var(--font);
}
