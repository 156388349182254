.detail-info-pret {
 display: grid;
 margin-top: 10vh;
}
.detail-info-pret p {
 display: flex;
 font-size: 0.8em;
 font-weight: 300;
 line-height: 1;
 letter-spacing: 0px;
 font-family: var(--font);
 color: #6c757d;
}
