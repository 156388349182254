.wrp-title-repay-pret {
 display: flex;
 flex-direction: column;
 padding: 3vh 3%;
}
.wrp-title-repay-pret h3 {
 display: flex;
 font-size: 0.9em;
 font-weight: 300;
 line-height: 1;
 letter-spacing: 0px;
 font-family: var(--font);
 text-transform: uppercase;
 color: #555555;
}
