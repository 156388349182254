.wrp-count-head-tontine-total {
 display: flex;
}
.wrp-count-head-tontine-total p {
 display: flex;
 font-size: 1em;
 font-weight: 200;
 line-height: 1;
 white-space: nowrap;
 letter-spacing: 0px;
 font-family: var(--font);
 color: #0077b6;
}
