.wrp-save-edit-info {
 display: flex;
}
.wrp-save-edit-info h2 {
 display: flex;
 font-size: 0.9em;
 font-weight: 300;
 line-height: 1;
 letter-spacing: 0.3px;
 font-family: var(--font);
 text-transform: uppercase;
 cursor: pointer;
 color: #fff;
}
