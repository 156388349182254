.flex-info-msg-pret {
 display: flex;
}
.flex-info-msg-pret p {
 display: flex;
 font-size: 0.8em;
 font-weight: 300;
 line-height: 1;
 letter-spacing: 0.5px;
 font-family: var(--font);
 color: #6c757d;
}
