.class-group-css-tontine {
 display: flex;
}
.class-group-css-tontine h2 {
 display: flex;
 font-size: 0.9em;
 font-weight: 100;
 line-height: 1;
 letter-spacing: 0px;
 font-family: var(--font);
 text-transform: uppercase;
}
