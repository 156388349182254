.input-signin-user {
 display: flex;
 flex-direction: column;
 width: 100%;

 justify-content: center;
 align-items: center;
}
.input-signin-user form {
 display: flex;
 width: 100%;

 height: 100vh;
 overflow: scroll;
 max-width: 25em;
 flex-direction: column;
}
.input-signin-user form input {
 display: flex;
 flex-direction: column;
 font-size: 1.1em;
 font-weight: 500;
 font-family: "Quicksand", sans-serif;
 line-height: 2;
 padding: 1.5vh 0;
 letter-spacing: 0.5px;
 border: none;
 border-bottom: 1px solid #b0bacf;

 color: #000;

 background: transparent;
}
.input-signin-user form button {
 margin-top: 3vh;
}

/* Small screen */
.input-signin-user-sm {
 display: flex;
 flex-direction: column;
 width: 100%;
 justify-content: center;
 align-items: center;
 margin-top: 3vh;
}
.input-signin-user-sm form {
 display: flex;
 width: 100%;
 padding: 1em;
 height: 100vh;
 overflow: scroll;
 flex-direction: column;
}
.input-signin-user-sm form input {
 display: flex;
 flex-direction: column;
 font-size: 1.1em;
 font-weight: 500;
 font-family: "Quicksand", sans-serif;
 line-height: 2;
 padding: 1.5vh 0;
 letter-spacing: 0.5px;
 border: none;
 border-bottom: 1px solid #b0bacf;

 color: #000;

 background: transparent;
}
.input-signin-user-sm form button {
 margin-top: 3vh;
}

.pop-rate {
 display: flex;
 font-size: 0.8em;
 font-weight: 300;
 line-height: 1;
 letter-spacing: 0px;
 font-family: var(--font);
}
