.wrp-prix-valid-litige {
 display: flex;
 place-items: center;
 margin-top: 3vh;
}
.wrp-prix-valid-litige p {
 display: flex;
 font-size: 1em;
 font-weight: 300;
 line-height: 1;
 letter-spacing: 0px;
 font-family: var(--font);
}
