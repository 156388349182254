.flex-list-grid-center {
 display: flex;
 gap: 1rem;
 place-items: center;
 padding: 0.8em;
}
.flex-list-grid-center .icon-dash {
 padding: 0.5em 0.6em;
 border-radius: 0.8em;
 cursor: pointer;
}
.flex-list-grid-center span {
 display: flex;
 font-size: 10px;
 font-weight: 400;
 white-space: nowrap;
 letter-spacing: 0px;
 font-family: "Quicksand", sans-serif;
 cursor: pointer;
}

/* Small screen */
.flex-list-grid-center-sm {
 display: flex;
 gap: 1rem;
 place-items: center;
 padding: 0.8em;
}
.flex-list-grid-center-sm .icon-dash {
 padding: 0.5em 0.6em;
 border-radius: 0.8em;
 cursor: pointer;
}
.flex-list-grid-center-sm span {
 display: flex;
 font-size: 0.9em;
 font-weight: 400;
 white-space: nowrap;
 letter-spacing: 0px;
 font-family: "Quicksand", sans-serif;
 cursor: pointer;
}
